export const ACTIVE_DRAW = 'Active Draws';
export const PREVIOUS_DRAW = 'Previous Draws';

export const REMOVE_FROM_MENU = 'remove-from-menu-99';

export const NAV_SEGMENT = Object.freeze({
	GLOBAL_KYC: 'transactional-kyc',
	PLAYER_KYC: 'kyc',
	WINNING_PARTICIPATION: 'winning-participations',
	WITHDRAWAL_REQUESTS: 'withdrawal-requests',
	LOGOUT: 'logout',
	PLAYER_MG__PLAYERS: '/player-management/players'
})
