import { Pipe, PipeTransform } from '@angular/core';
import { ObjPropsStringDotNotationUtility } from '../../../utilities';

@Pipe({
	name: 'heColData'
})

export class ColumnDataPipe implements PipeTransform {
	transform(rowData: any, field: string) {
		return ObjPropsStringDotNotationUtility.getObjValue(rowData, field);
	}
}
