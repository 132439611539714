import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of, switchMap, throwError as _throw} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ServiceController} from '../../shared/utilities/service-utilities/service-controller.urls';
import {FormatParams, ServiceHeadersConfig} from '../../shared/utilities/service-utilities/service-headers.config';
import {ServiceAction} from '../../shared/utilities/service-utilities/service-action.urls';
import {ResponseBodyType} from '../../shared/enums/response-body-type.enum';
import {ServiceUrlsUtility} from '../../shared/utilities/service-utilities/service-urls.utility';
import {ServiceHeadersUtility} from '../../shared/utilities/service-utilities/service-headers.utility';
import {ServiceRequestBodyUtility} from '../../shared/utilities/service-utilities/service-request-body.utility';
import {ServiceError} from '../../shared/models/general/service-error.model';
import {fromFetch} from 'rxjs/internal/observable/dom/fetch';
import {TableDataResponse} from '../../shared';
import {API_DATA_INVALID} from '../../shared/constants';
import {isArrayInit} from '../../shared/utilities/general-utilities/arrays.utils';

/**
 * Base Service class used to handle HTTP requests to the server
 */
export abstract class BaseService {
	/**
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 * @param controller Contains the 'controller' part of the request URL
	 * @param baseServiceURL backend associated service URL segment
	 * @param headersConfig Object containing the headers configuration for the HTTP requests, such as Content-Type and Authorization
	 */
	constructor(
		protected http: HttpClient,
		protected controller: ServiceController,
		protected baseServiceURL: string,
		protected headersConfig: ServiceHeadersConfig = new ServiceHeadersConfig()
	) {
	}

	/**
	 * Function to make an HTTP GET request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param responseBodyType one of @link ResponseBodyType.JSON} | {@link ResponseBodyType.Text} | {@link ResponseBodyType.Blob}
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 * @param xFormatParams allows customisation of CSV naming and which columns are returned.
	 * @param baseServiceURL allows overriding of the default service (class) value
	 * @returns An Observable of the data returned from the server
	 */
	protected get<T>(
		action?: ServiceAction, value?: any[], searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON, controller?: ServiceController,
		xFormatParams?: FormatParams[], baseServiceURL?: string
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const baseUrl = baseServiceURL ?? this.baseServiceURL;

		const serviceUrl = ServiceUrlsUtility.getUrl(baseUrl, ctrl, action, value);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(
			xFormatParams ? this.headersConfig.attachXFormatParams(xFormatParams) : this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		return this.http.get<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);

		// return this.http.get(serviceUrl, options)
		// 	.map(res => this.extractData(res, responseBodyType))
		// 	.catch(this.handleError);
	}

	protected get_fetch<T>(
		action?: ServiceAction, value?: any[], searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON, controller?: ServiceController, xFormatParams?: FormatParams[]
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value);

		const headers = ServiceHeadersUtility.httpHeaders_fetch(
			xFormatParams ? this.headersConfig.attachXFormatParams(xFormatParams) : this.headersConfig);

		const options: any = {
			headers,
			method: 'GET',
			mode: 'cors'
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		/*return fetch(serviceUrl, options).then(res => {
			/!*if (!res) {
				return this.handleError(new HttpErrorResponse({error: new Error(`HE-Error-Msg: Response from ${serviceUrl} was undefined.`)}));
			}*!/
			return res;
		}).catch((err) => {
			this.handleError(err);
		})*/
		return fromFetch(serviceUrl, options).pipe(
			switchMap(res => {
				if (res) {
					return of(res);
				}

				throw new Error('FetchAPI returned null.');
			}),
			catchError(this.handleError)
		)
	}

	/**
	 * Use to download files where retrieval of their filename is also needed.
	 */
	protected async download_for_filename<T>(
		action?: ServiceAction, value?: any[], searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON, controller?: ServiceController, xFormatParams?: FormatParams[],
		method: 'GET' | 'POST' = 'GET', body?: object
	): Promise<FetchedDataAndFilename<T>> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value);

		const headers = ServiceHeadersUtility.httpHeaders_fetch(
			xFormatParams ? this.headersConfig.attachXFormatParams(xFormatParams) : this.headersConfig);

		const options: any = {
			headers,
			method,
			mode: 'cors',
			observe: 'response'
		};

		if (method !== 'GET') {
			options.body = JSON.stringify(body);
		}

		options.responseType = this.setResponseType(responseBodyType);

		const url = new URL(serviceUrl);

		if (searchParams !== undefined) {
			// Transfer values within searchParams to the const url.searchParams
			for (const key of searchParams.keys()) {
				url.searchParams.set(key, searchParams.get(key));
			}
		}

		let myResponse;

		const request = new Request(url);

		return fetch(request, options).then(response => {
			myResponse = response;
			if (responseBodyType === ResponseBodyType.Text) {
				return response.text()
			} else if (responseBodyType === ResponseBodyType.JSON) {
				return response.json()
			} else {
				return response.blob()
			}
		}).then(data => {
			let filename = myResponse.headers.get('content-disposition') ?? null;
			const SIGNATURE = 'filename="';

			if (filename) {
				filename = filename.substring(filename.indexOf(SIGNATURE) + SIGNATURE.length, filename.length - 1); // minus 1 so the end " is removed
				filename = filename ?? null;
			}

			return {data, filename}
		}).catch(err => {
			return err;
		});
	}

	/**
	 * @todo FIX - request data not attaching!!!!
	 * Function to make an HTTP POST request
	 * @param action Contains the 'action' part of the request URL
	 * @param postData Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 * @param header provides a way to override the method set value whilst still being backward compatible. By default all POST request
	 * attaches authorisation token.
	 * @param responseBodyType one of JSON | Text | Blob; by convention the default is usually JSON.
	 * @returns An Observable of the data returned from the server
	 */
	protected post<T>(
		action?: ServiceAction, postData?: any, searchParams?: HttpParams,
		value?: any[], controller?: ServiceController, header?: ServiceHeadersConfig, responseBodyType: ResponseBodyType = ResponseBodyType.JSON
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value);

		const headerConfig = header ? header : this.headersConfig;

		const body = ServiceRequestBodyUtility.getRequestBody(postData, headerConfig.contentType);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(headerConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		return this.http.post<T>(serviceUrl, body, options);
	}

	/**
	 * Function to make an HTTP PUT request
	 * @param action Contains the 'action' part of the request URL
	 * @param editData Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 * @returns An Observable of the data returned from the server
	 */
	protected edit<T>(
		action?: ServiceAction, editData?: any, searchParams?: HttpParams, value?: any[], controller?: ServiceController
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value ?? []);

		// Note: If method fails as a result of body/header - for FormData - check pattern from POST for fix
		const body = JSON.stringify(editData);
		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.put<T>(serviceUrl, body, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * @todo Make sure the structure of PUT returned from BE is in the expected format for all PUT
	 *       - And in future {@link edit} link can be refactor as a private helper and all callers are to go through {@link editWithValidate}
	 */
	protected editWithValidate<D>(
		requiredFields: string[],
		action?: ServiceAction, editData?: any, searchParams?: HttpParams, value?: any[],
		controller?: ServiceController): Observable<D> {
		return new Observable<D>(subscriber => {
			// get(ServiceAction.AGENTS_OPERATOR_CURRENCIES, [String(tenantID)], undefined, undefined, ServiceController.USER_OWN_CONTROLLER)
			this.edit<D>(action, editData, searchParams, value, controller).subscribe({
				next: res => {
					let hasRequiredFields: boolean;

					// const obj of Object.keys(res)
					for (const field of requiredFields) {
						hasRequiredFields = true;

						hasRequiredFields = hasRequiredFields && res.hasOwnProperty(field);

						if (!hasRequiredFields) {
							console.error(`#validatePutRes: some, or all, required fields ${requiredFields} does not exist on obj.`);
							break;
						}
					}

					// Use flag rather than "res.resultSet > 0" so that genuine empties are not classed as error
					if (hasRequiredFields) {
						subscriber.next(res);
					} else {
						subscriber.error(API_DATA_INVALID);
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		});
	}

	/**
	 * Function to make an HTTP PATCH request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param data Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 * @returns An Observable of the data returned from the server
	 */
	protected patch<T>(
		action?: ServiceAction,
		data?: any | any[],
		searchParams?: HttpParams,
		value?: any[],
		controller?: ServiceController
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value ?? []);

		const body = JSON.stringify(data);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.patch<T>(serviceUrl, body, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function to make an HTTP DELETE request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @returns An Observable of boolean signifying whether delete was successful or not
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @param controller provides a way to override the constructor set value whilst still being backward compatible.
	 */
	protected delete<T>(
		action?: ServiceAction,
		value?: any[],
		searchParams?: HttpParams,
		controller?: ServiceController
	): Observable<any> {
		const ctrl = controller ?? this.controller;
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, ctrl, action, value ?? []);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.delete<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function to make an HTTP DELETE request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @returns An Observable of any signifying whether delete was successful or not and any data it might return
	 */
	protected deleteWithReturn<T>(action?: ServiceAction, value?: any[]): Observable<any> {
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action, value);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		return this.http.delete<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function called to handle unexpected errors
	 */
	protected handleError(serviceErrors: HttpErrorResponse) {
		let errors: ServiceError[] = serviceErrors.error as ServiceError[];
		if (errors && Array.isArray(errors)) { // To guard against times when the cast to ServiceError[] is unsuccessful and is obj
			errors.forEach((item) => {
				item.status = serviceErrors.status;
			});
		} else {
			errors = [];
			errors.push(new ServiceError(serviceErrors.status, serviceErrors.statusText, 0, undefined));
		}

		return _throw(errors);
	}

	/**
	 * Function called to read the server's response
	 * @returns The serialised data
	 */
	// protected extractData(res: Response, responseBodyType: ResponseBodyType = ResponseBodyType.JSON): any {
	// 	if (res.status < 200 || res.status >= 300) {
	// 		throw new Error('Bad response status ' + res.status);
	// 	}

	// 	let body;
	// 	switch (responseBodyType) {
	// 		case ResponseBodyType.Text:
	// 			body = res.text();
	// 			break;
	// 		case ResponseBodyType.Blob:
	// 			body = res.blob();
	// 			break;
	// 		case ResponseBodyType.JSON:
	// 		default:
	// 			body = res.json();
	// 			break;
	// 	}

	// 	let mappedData = this.mapData(body);
	// 	return mappedData;
	// }

	/**
	 * Function called to read the server's response when no return data is expected
	 * @returns A boolean signifying whether the request was successful or not
	 */
	// protected voidExtractData(res: Response): boolean {
	// 	if (res.status < 200 || res.status >= 300) {
	// 		return false;
	// 	}

	// 	return true;
	// }

	/**
	 * Function called to serialise the returned data from the server
	 * @returns The serialised data
	 */

	// protected abstract mapData(data: any): any;
	/**
	 * @todo the use of {@link requiredFields} does not support DRY as these values will also require modifying should
	 *   the object signatures change. A safe solution would be to reflect the fields of the Generic dto!!
	 */
	protected validateDataTableRes<D>(
		requiredFields: string[],
		action?: ServiceAction, value?: any[], searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON, controller?: ServiceController): Observable<TableDataResponse<D>> {
		return new Observable<TableDataResponse<D>>(subscriber => {
			// get(ServiceAction.AGENTS_OPERATOR_CURRENCIES, [String(tenantID)], undefined, undefined, ServiceController.USER_OWN_CONTROLLER)
			this.get<TableDataResponse<D>>(action, value, searchParams, responseBodyType, controller).subscribe({
				next: res => {
					let isValidData = true;
					let hasRequiredFields: boolean;
					const tempData: D[] = []

					for (const obj of (res.resultSet as D[])) {
						hasRequiredFields = true;

						for (const field of requiredFields) {
							hasRequiredFields = hasRequiredFields && obj.hasOwnProperty(field);

							if (!hasRequiredFields) {
								// @ts-ignore
								console.error(`#getAndValidateDataTableRes: some or all of the required fields ${requiredFields}
								 does not exist on object.`)
								break;
							}
						}

						// Validate, to prevent adding null or undefined lookup
						if (hasRequiredFields) {
							tempData.push(obj as D);
						} else {
							isValidData = false;
							break;
						}
					}

					// Use flag rather than "res.resultSet > 0" so that genuine empties are not classed as error
					if (isValidData) {
						res.resultSet = tempData;
						subscriber.next(res);
					} else {
						subscriber.error(API_DATA_INVALID);
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		});
	}

	protected validateAndParseLookupResSTD<U>(
		lookupArr: U[],
		validatingFields: { label: string, value: string },
		action?: ServiceAction,
		value?: any[],
		searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		controller?: ServiceController
	): Observable<U[]> {
		if (isArrayInit(lookupArr)) {
			return of(lookupArr);
		}

		return new Observable<U[]>(subscriber => {
			// TODO - Provide a better solution to the below comment (i.e. a way to flag to UI that lookup is not available for e2e test)
			// First deliver the undefined value, indicating that lookup is not ready, so caller can show Toast
			// subscriber.next(lookupArr);

			// TODO: Use named params for #get method - make sure it's backwards compatible
			this.get(action, value, searchParams, responseBodyType, controller).subscribe({
				next: res => {
					lookupArr = [];

					let isNonconformingRes = false;

					const list = res?.resultSet ?? res;

					list.forEach(obj => {
						// Validate, to present adding null or undefined lookup
						if (obj.hasOwnProperty(validatingFields.label) && obj.hasOwnProperty(validatingFields.value)) {
							lookupArr.push({
								...obj,
								label: obj[validatingFields.label],
								value: obj[validatingFields.value]
							} as U);
						} else {
							isNonconformingRes = true;
						}
					});

					if (isNonconformingRes) {
						// Rather than throwing an exception, simply log as the app should not fail because of absence of lookup
						console.error(`#validateAndParseLookupResSTD: one or more object delivered - by ${action} - has been omitted for missing a required field.`);
					}

					if (lookupArr) {
						subscriber.next(JSON.parse(JSON.stringify(lookupArr)));
					} else {
						subscriber.error(new Error(API_DATA_INVALID));
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}

	protected validateAndParseObject<U>(
		validatingFields: string[],
		action?: ServiceAction,
		value?: any[],
		searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON,
		controller?: ServiceController
	): Observable<U> {
		return new Observable<U>(subscriber => {
			// TODO - Provide a better solution to the below comment (i.e. a way to flag to UI that lookup is not available for e2e test)
			// First deliver the undefined value, indicating that lookup is not ready, so caller can show Toast
			// subscriber.next(lookupArr);

			// TODO: Use named params for #get method - make sure it's backwards compatible
			this.get(action, value, searchParams, responseBodyType, controller).subscribe({
				next: obj => {
					let isConformingRes = true;

					for (const field of validatingFields) {
						if (!obj.hasOwnProperty(field)) {
							isConformingRes = false;
							break;
						}
					}

					if (isConformingRes) {
						subscriber.next(obj);
					} else {
						console.warn(`#validateAndParseObject: one or more fields delivered - by ${action} - is missing.`);
						subscriber.error(new Error(API_DATA_INVALID));
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}

	/**
	 * Function to set responseType
	 */
	private setResponseType(responseBodyType: ResponseBodyType): string {
		switch (responseBodyType) {
			case ResponseBodyType.Text:
				return 'text';
			case ResponseBodyType.Blob:
				return 'blob';
			case ResponseBodyType.JSON:
			default:
				return 'json';
		}
	}
}

export class FetchedDataAndFilename<T> {
	data: T;
	filename: string;
}
