export enum ToastMessageType {
	success = 'success',
	warning = 'warn',
	error = 'error',
	info = 'info'
}

export enum ToastMessagePosition {
	topRight = 'top-right',
	topLeft = 'top-left',
	bottomRight = 'bottom-right',
	bottomLeft = 'bottom-left',
	topCenter = 'top-center',
	bottomCenter = 'bottom-center',
	center = 'center'
}

export interface ToastMessage {
	type: ToastMessageType;
	title: string;
	description: string;
}

export interface RequestErrorMsg {
	propertyName: string;
	errorNo: string;
	description: string;
}
