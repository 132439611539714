import {Injectable} from '@angular/core';
import {ServiceError, ToastMessageType} from '../../shared/models/general';
import {ToastDisplayService} from './toast-display.service';
import {LoginService} from './login.service';

@Injectable()
export class BoErrorHandlerService {
	private DEFAULT_ERR_MSG = 'An error has occurred. Please contact support.';
	private CONNECTION_ERR_MSG = 'Please check your internet connection.';

	constructor(private toastDisplayService: ToastDisplayService, private loginService: LoginService) {
	}

	handleError(err: ServiceError[] | Error, errorMessage?: string, callName?: string): void {
		const summaryWording = callName === undefined ? 'Error' : callName;
		// console.log('navigator.onLine 111 =', navigator.onLine, 'error =', error ) ;

		let description: string;

		const error = err['error'] ?? err;

		if (error === undefined) {
			description = errorMessage === undefined ? this.DEFAULT_ERR_MSG : errorMessage

			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: summaryWording,
				description
			});
		} else if (error[0] instanceof Object) {
			description = (errorMessage === undefined) ? error[0].description : errorMessage;

			if (error[0].status === 401) {
				description = 'You are not authorised to perform this action.';

				if (error[0].description === 'Session token expired') {
					this.loginService.logoutAndRedirectToLogin(true);
				}
			} else if (error[0].status === 500) {
				description = this.DEFAULT_ERR_MSG;
			} else if (error[0].status === 0 || !navigator.onLine) {
				if (error[0].description === 'Unknown Error') {
					description = this.DEFAULT_ERR_MSG;
				} else if (error[0].description) {
					description = error[0].description; // If there is an err msg and it is not = 'Unknown Error'
				} else {
					// If the unknown error is as a result of poor/no internet connection
					// navigator.onLine is prone to false positive, hence check status too
					description = this.CONNECTION_ERR_MSG;
				}
			} else if (!error[0].status && error[0].description) {
				description = error[0].description;
			}

			if (description.trim() === 'OK') {
				// Temp fix for when an unexpected error message structure is received from the BE and desc is set to OK (for some reason)
				description = this.DEFAULT_ERR_MSG;
			}

			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: summaryWording,
				description: description
			});
		}
	}
}
