import {AfterViewChecked, Component, Input} from '@angular/core';
import {WidgetDto} from '../model/widget-dto';
import {GridStack} from 'gridstack';

@Component({
	selector: 'he-dashboard-panel',
	templateUrl: './dash-board-panel.component.html',
	styleUrls: ['./dash-board-panel.component.scss']
})
export class DashBoardPanelComponent implements AfterViewChecked {
	@Input() widgets: WidgetDto[];

	ngAfterViewChecked() {
		if (document.querySelector('.grid-stack')) {
			GridStack.init();
		}
	}
}
