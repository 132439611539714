import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AssignPspData, PaymentRoute} from '../../../../shared/models/finance/psps.model';
import {BaseLookup} from '../../../../shared/interfaces/lookup-interfaces';
import {LookupService} from '../../../../shared/services/lookup.service';
import {BoErrorHandlerService} from '../../../../helio-core-services';
import {forkJoin} from 'rxjs';
import {Player} from '../../../models/player/players.model';
import {PaymentProviderService} from '../../../services/payment-provider.service';

@Component({
	selector: 'he-assign-psp-dialog',
	templateUrl: './assign-psp-dialog.component.html',
	styleUrls: ['./assign-psp-dialog.component.scss']
})
export class AssignPspDialogComponent {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() psp: PaymentRoute;
	@Input() playerPartialData: Player;

	@Output() closeEvent = new EventEmitter<void>();
	@Output() submitEvent = new EventEmitter<AssignPspData>();

	form: FormGroup;

	isLoadingLookups = false;

	pspLookup: BaseLookup[];

	constructor(
		private formBuilder: FormBuilder,
		private lookupService: LookupService,
		private pspService: PaymentProviderService,
		// private changeDetection: ChangeDetectorRef,
		private boErrorHandlerService: BoErrorHandlerService
	) {
	}

	ngOnInit() {
		// this.calculatedAmountAfter = this.wallet.walletAmount;

		this.formInit();
		this.setFormData();
	}

	private formInit() {
		this.form = this.formBuilder.group({
			selectedPsp: new FormControl('', {
				validators: [Validators.required]
			})
		});
	}

	private setFormData() {
		this.isLoadingLookups = true;

		const observables: any = {
			getPspLookup: this.pspService.getPaymentProvidersLookup(this.playerPartialData.tenantID),
		};

		forkJoin(observables).subscribe({
			next: (res: any) => {
				this.pspLookup = res.getPspLookup;

				this.form.get('selectedPsp').setValue(this.psp.thirdPartyID);

				this.isLoadingLookups = false;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onSubmit() {
		const thirdPartyID = this.form.get('selectedPsp').value;
		const thirdPartyName = this.pspLookup.find(entry => entry.value === thirdPartyID).label;

		const request: AssignPspData = {
			paymentMethodID: this.psp.paymentMethodID,
			thirdPartyID,
			thirdPartyName
		};

		this.submitEvent.emit(request);
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}
}
