<div style="padding: 0 16px 16px 16px;">
	<he-header headerTitle="No Access Rights"></he-header>

	<p>
		You do not have rights to access this page. Please contact your administrator.
	</p>

	<p>
		<a routerLink="/home">Go to Home Page</a>
	</p>
	<button class="flat" type="button" label="Logout" *ngIf="isLoggedIn" pButton icon="ui-icon-exit-to-app" (click)="logOut()"></button>
</div>
