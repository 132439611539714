import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { AppConfigService } from './app-config.service';
import { DataTableServerResponse } from '../../shared/components/data-table-v3';
import { ServiceController } from '../../shared/utilities/service-utilities';

@Injectable()
export class PagePermissionsService extends BaseService {
	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.PAGE_PERMISSIONS, appConfigService.serviceBaseURL);
	}

	getPages(): Observable<DataTableServerResponse> {
		return this.get();
	}
}
