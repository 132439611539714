import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ResponsiveContent} from '../../../interfaces/responsive-content';
import {WalletBalance, WalletTransaction} from '../../../models/finance/wallet-transaction.model';
import {ADJUST_ICON_TAG, LABEL_ADJUST_WALLET} from 'src/app/shared/constants/constants';
import {Subscription} from 'rxjs';
import {IndividualPlayerConstants} from '../constant/individual-player.constants';
import {AssignPspData, PaymentRoute} from '../../../models/finance/psps.model';
import {Player} from '../../../models/player/players.model';
import {BaseLookup} from '../../../interfaces/lookup-interfaces';
import {AppConfigService, BoErrorHandlerService, BreadcrumbService, ToastDisplayService} from '../../../../helio-core-services';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AppGuard} from '../../../guards';
import {PlayersService} from '../../../services/players.service';
import {LookupService} from '../../../services/lookup.service';
import {ExportCSVUtility, FileDownloadUtility, ServiceAction, ServiceController} from '../../../utilities';
import {WalletTransactionService} from '../../../services/wallet-transaction.service';
import {PaymentProviderService} from '../../../services/payment-provider.service';
import {WithdrawalsService} from '../../../services/withdrawals.service';
import * as ColumnNames from '../../../constants/ui-db-name-mappings';
import {ColumnType, DataTableLazyLoadEvent, SearchFieldType, TableDataResponse} from '../../data-table-v3';
import {HttpParams} from '@angular/common/http';
import {deepCopy, isArrayInit} from '../../../utilities/general-utilities/arrays.utils';
import {AdjustWalletRequest} from '../../../models/finance/adjust-wallet-request';
import {ToastMessageType} from '../../../models';
import {SelectItem} from 'primeng/api';
import {TransferDetails} from '../../../models/finance/transfer-information.model';

@Component({
	selector: 'he-wallet-transaction',
	templateUrl: './wallet-transaction.component.html',
	styleUrls: ['./wallet-transaction.component.scss']
})
export class WalletTransactionComponent extends ResponsiveContent<WalletTransaction> {

	@Input() tabbedTitle = IndividualPlayerConstants.TAB_NAMES.walletTransactions;
	@Input() isTabbedTable = false; // Represents false == 'globalFinancePage' and true == 'tabbedIndividualPlayerPage'

	@Output() updateDashboard: EventEmitter<void> = new EventEmitter();

	targetDataLookup: SelectItem[] = [];
	selectedTargetData: SelectItem;

	/**
	 * @deprecated
	 * @todo combine @HostBinding('attr.is-global') tag with {@link isTabbedTable}
	 */
	@HostBinding('attr.is-global') isStandAlone;

	btnLabel = Object.freeze({
		ADJUST: LABEL_ADJUST_WALLET,
		CARDS: 'Cards',
		TRANSFER_DETAILS: 'Transfer Details',
		BANK_DETAILS: 'Bank Details',
		EXCHANGE_HOUSE_DETAILS: 'Exchange House Details',
		PSP: 'Assign PSP'
	});

	ADJUST_ICON_TAG = ADJUST_ICON_TAG;

	showAdjustWalletDialog = false;
	showCardsDialog = false;
	showTransferDetailsDialog = false;
	showBankDetailsDialog = false;
	showExchangeHouseDialog = false;
	showAssignPspDialog = false;

	selectedTransactions: WalletTransaction[] = [];

	getWalletTransactionTypesSub$: Subscription;
	getWalletTypesSub$: Subscription;
	// getCurrenciesSub$: Subscription;
	getTableDataSub$: Subscription;
	getBalanceSubs$: Subscription;
	adjustBalanceSub$: Subscription;
	getPaymentRoute$: Subscription;

	routePlayerID: number;
	oldRoutePlayerID = 0;

	walletBalance: WalletBalance;
	hasAdjustWalletBalPermission = false;

	playerPspRoute: PaymentRoute;
	playerPartialData: Player;
	pspLookup: BaseLookup[];

	bankDetails: TransferDetails;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private appGuard: AppGuard,
				private breadcrumbService: BreadcrumbService,
				private toastDisplayService: ToastDisplayService,
				private playersService: PlayersService,
				private lookupService: LookupService,
				private walletTransService: WalletTransactionService,
				private withdrawalsService: WithdrawalsService,
				private pspService: PaymentProviderService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.isStandAlone = !this.isTabbedTable;

		this.initDataTable();

		this.breadcrumbService.setItems([
			{label: 'Finance'},
			{label: this.tabbedTitle}
		]);

		this.route.paramMap.subscribe((params: ParamMap) => {
			// this.playerPspRoute = undefined; // Important so that a new pspRoute is fetched in #getTableDataHelper for the new playerID
			this.routePlayerID = Number(params.get('id'));

			this.getTableData();
		});

		this.hasAdjustWalletBalPermission =
			this.appGuard.hasActionPermission(ServiceController.PLAYER_BALANCE, ServiceAction.ADJUST_WALLET_BALANCE);
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.getWalletTransactionTypesSub$, this.getWalletTypesSub$, this.getTableDataSub$,
			this.getBalanceSubs$, this.adjustBalanceSub$, this.getPaymentRoute$
		);
	}

	initDataTable() {
		this.dataKey = ColumnNames.WALLET_TRANS_ID.DB;

		this.cols = [
			{
				field: ColumnNames.WALLET_TRANS_ID.DB,
				header: ColumnNames.WALLET_TRANS_ID.UI
			},
			{
				field: ColumnNames.ACTION_ID.DB,
				header: ColumnNames.ACTION_ID.UI
			}
		];

		if (!this.isTabbedTable) {
			this.cols.push({
				field: ColumnNames.PLAYER_ID.DB,
				header: ColumnNames.PLAYER_ID.UI
			});
		}

		this.cols.push(
			{
				field: ColumnNames.WALLET_TRANS_DATE.DB,
				header: ColumnNames.WALLET_TRANS_DATE.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.WALLET_TRANS_TYPE.DB,
				header: ColumnNames.WALLET_TRANS_TYPE.UI
			},
			{
				field: ColumnNames.WALLET_TYPE.DB,
				header: ColumnNames.WALLET_TYPE.UI
			},
			{
				field: ColumnNames.WALLET_INVOICE_CREDIT_NOTE.DB,
				header: ColumnNames.WALLET_INVOICE_CREDIT_NOTE.UI,
				sortable: false
			},
			{
				field: ColumnNames.CURRENCY_CODE.DB,
				header: ColumnNames.CURRENCY_CODE.UI
			},
			{
				field: ColumnNames.WALLET_VAT.DB,
				header: ColumnNames.WALLET_VAT.UI,
				localeDecimalPlaces: 2
			},
			{
				field: ColumnNames.WALLET_AMOUNT.DB,
				header: ColumnNames.WALLET_AMOUNT.UI,
				localeDecimalPlaces: 2
			},
			{
				field: ColumnNames.WALLET_BALANCE.DB,
				header: ColumnNames.WALLET_BALANCE.UI,
				localeDecimalPlaces: 2
			},
			{
				field: ColumnNames.WALLET_TOTAL_BALANCE.DB,
				header: ColumnNames.WALLET_TOTAL_BALANCE.UI,
				localeDecimalPlaces: 2
			},
			/*
			// Remove requested by Brian on 02/05/24
			{
				field: ColumnNames.WALLET_BAL_BEFORE.DB,
				header: ColumnNames.WALLET_BAL_BEFORE.UI
			},
			{
				field: ColumnNames.WALLET_BAL_AFTER.DB,
				header: ColumnNames.WALLET_BAL_AFTER.UI
			},*/
			{
				field: ColumnNames.COMMENT.DB,
				header: ColumnNames.COMMENT.UI,
				sortable: false
			}
		);

		this.tableActions = [
			{
				menuItem: {
					label: 'Export to CSV',
					icon: 'ui-icon-insert-drive-file'
				},
				callback: (callbackObj) => {
					this.exportToCSV(callbackObj.data, callbackObj.isAllDataSelected);
				},
				isRowAction: false // implies isBulkAction
			}
		];

		this.searchFields = []

		this.searchFields.push(
			{
				property: ColumnNames.WALLET_TRANS_ID.DB,
				label: ColumnNames.WALLET_TRANS_ID.UI,
				type: SearchFieldType.Id
			},
			{
				property: ColumnNames.ACTION_ID.DB,
				label: ColumnNames.ACTION_ID.UI,
				type: SearchFieldType.Id
			}
		);

		if (!this.isTabbedTable) {
			this.searchFields.push({
				property: ColumnNames.PLAYER_ID.DB,
				label: ColumnNames.PLAYER_ID.UI,
				type: SearchFieldType.Id
			});
		}

		// NB: ColumnNames.ACTION_ID is declared above!!
		this.searchFields.push(
			{
				property: ColumnNames.WALLET_TRANS_DATE.DB,
				label: ColumnNames.WALLET_TRANS_DATE.UI,
				type: SearchFieldType.Date,
				isRange: true
			},
			{
				property: ColumnNames.WALLET_TRANS_TYPE_ID.DB,
				label: ColumnNames.WALLET_TRANS_TYPE_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [], // this.transTypeLookups,
				isArraySearch: false
			},
			{
				property: ColumnNames.WALLET_TYPE_ID.DB,
				label: ColumnNames.WALLET_TYPE_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [], // this.walletTypeLookups,
				isArraySearch: false
			},
			{
				property: ColumnNames.CURRENCY_ID.DB,
				label: ColumnNames.CURRENCY_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [], // this.transTypeLookups,
				isArraySearch: false
			},
			{
				property: ColumnNames.WALLET_VAT.DB,
				label: ColumnNames.WALLET_VAT.UI,
				type: SearchFieldType.Number
			},
			{
				property: ColumnNames.WALLET_INVOICE_NOTE.DB,
				label: ColumnNames.WALLET_INVOICE_NOTE.UI,
				type: SearchFieldType.StringDefault
			},
			{
				property: ColumnNames.WALLET_CREDIT_NOTE.DB,
				label: ColumnNames.WALLET_CREDIT_NOTE.UI,
				type: SearchFieldType.StringDefault
			},
			{
				property: ColumnNames.WALLET_AMOUNT.DB,
				label: ColumnNames.WALLET_AMOUNT.UI,
				type: SearchFieldType.Number
			},
			{
				property: ColumnNames.WALLET_BALANCE.DB,
				label: ColumnNames.WALLET_BALANCE.UI,
				type: SearchFieldType.Number
			},
			{
				property: ColumnNames.WALLET_TOTAL_BALANCE.DB,
				label: ColumnNames.WALLET_TOTAL_BALANCE.UI,
				type: SearchFieldType.Number
			}
		);

		this.fetchLookups();
	}

	getTableData(event?: DataTableLazyLoadEvent) {
		super.getTableData(event);

		this.params = this.computeTableDataHttpParams(event);
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.fetchCriticalLookup().then(() => {
			if (!this.targetDataLookup || this.targetDataLookup.length < 1) {
				this.boErrorHandlerService.handleError(
					undefined, 'Target table lookup init to undefined or < 1');
			}

			this.getTransactions(this.params);
		}).catch(catchErr => {
			this.loading = false;
			this.boErrorHandlerService.handleError(catchErr);
		});
	}

	private fetchCriticalLookup(): Promise<void> {
		if (this.targetDataLookup && (this.targetDataLookup.length > 0)) {
			// Resolve immediately if already fetched so that API is not called again during ops like AdvancedSearch.
			//  If len is less than 1 then an attempt should be made again to fetch the lookup
			return Promise.resolve();
		}

		return new Promise<void>((resolve, reject) => {
			this.lookupService.getWalletTypes().subscribe({
				next: res => {
					this.targetDataLookup = res;
					this.selectedTargetData = this.targetDataLookup[0];
					resolve();
				},
				error: error => {
					reject(error)
				}
			});
		})
	}

	getTransactions(params: HttpParams) {
		// #forkJoin is not applicable here as when err, thrown for non-supported PSP playerID, also results in table failing.
		//  Could perhaps use #concat, but this approach also works!
		const observable: any = this.isTabbedTable ?
			this.walletTransService.getTransactions(this.routePlayerID, params, this.selectedTargetData.value) :
			this.walletTransService.getTransactions(undefined, params, this.selectedTargetData.value);

		this.getTableDataSub$ = observable.subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;
				this.offset = res.offset;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}

				// Retrieve the tenantID for use with #getPaymentProvidersLookup
				if (this.oldRoutePlayerID !== this.routePlayerID && this.isTabbedTable) {

					this.playerPspRoute = null;
					this.oldRoutePlayerID = this.routePlayerID; // to prevent triggering this section for this player again

					this.playersService.getPlayer(this.routePlayerID).subscribe({
						next: (getPlayerRes) => {
							this.playerPartialData = (getPlayerRes as TableDataResponse<Player>).resultSet[0];

							this.getPaymentRoute();
						},
						error: innerErr => {
							this.boErrorHandlerService.handleError(innerErr);
						}
					});
				} else {
					this.loading = false;
				}
			},
			error: error => {
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError( // TODO - Re-expose once PlayerTrail error fixed
					error, error.description, 'walletTransService.getTransactions');
				this.loading = false;
			}
		});
	}

	private getPaymentRoute(onResSuccess?: (obj: PaymentRoute) => void) {
		this.loading = true;

		this.walletTransService.getPaymentRoute(this.routePlayerID).subscribe({
			next: (routeRes: PaymentRoute) => {
				this.playerPspRoute = routeRes as PaymentRoute;

				if (onResSuccess) {
					onResSuccess(this.playerPspRoute);
				}

				this.loading = false;
			},
			error: err => {
				if (err[0]?.description === 'Payment route not yet assigned to player') {
					this.playerPspRoute = null; // Null, rather than, undefined, is used to flag that no results are present
				} else {
					// this.boErrorHandlerService.handleError(err); TODO - Re-expose once PlayerTrail error fixed
				}

				this.loading = false;
			}
		});
	}

	onShowAdjustWalletDialog() {
		// Since this dialog is not directly invoked via the table, show the dialog immediately when clicked and
		//  handle loading UI on the dialog itself if walletBalance is not yet defined.
		this.showAdjustWalletDialog = true;

		let params: HttpParams;
		params = this.params.set('$take', '1')
			.set('$offset', '0');

		this.getBalanceSubs$ = this.playersService.getBalance(this.routePlayerID, params).subscribe({
			next: res => {
				this.walletBalance = res;
			},
			error: error => {
				this.tableMessage = this.appConfigService.genericErrorMessage +
					'. Failed to retrieve player balance, some features may be unavailable.';
				this.boErrorHandlerService.handleError(error);
			}
		})
	}

	fetchLookups() {
		this.releaseSubscriptions(this.getWalletTransactionTypesSub$, this.getWalletTypesSub$);

		const reassignLookupOpts = (res: any[], prop) => {
			if (isArrayInit(res)) {
				// this.transTypeLookups = res;

				// Update the previously added transType object by reference
				const temp = this.searchFields.find(value => {
					return value.property === prop;
				});
				temp.listOptions = res;
			}
		}

		// get operators
		this.getWalletTransactionTypesSub$ = this.lookupService.getWalletTransactionTypes().subscribe(
			{
				next: (res) => {
					reassignLookupOpts(res, ColumnNames.WALLET_TRANS_TYPE_ID.DB);
				},
				error: error => {
					this.boErrorHandlerService.handleError(error);
				}
			});

		// get transaction types
		this.getWalletTypesSub$ = this.lookupService.getWalletTypes().subscribe(
			{
				next: (res) => {
					reassignLookupOpts(res, ColumnNames.WALLET_TYPE_ID.DB);
				},
				error: error => {
					this.boErrorHandlerService.handleError(error);
				}
			});

		// get currencies
		this.lookupService.getPlayerNomenclature('Currencies').then(res => {
			reassignLookupOpts(res.result.currencies, ColumnNames.CURRENCY_ID.DB);
		}).catch(error => {
			this.boErrorHandlerService.handleError(error);
		});
	}

	onAdjustWallet(data: AdjustWalletRequest) {
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.adjustBalanceSub$ = this.walletTransService.adjustBalance(this.routePlayerID, data).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: `Player ${this.routePlayerID} balance has been adjusted.`
				});

				if (this.isTabbedTable) {
					this.updateDashboard.emit();
				}

				this.getTransactions(this.params);
			},
			error: err => {
				this.handleDataRequestError(`AdjustWalletBalance`, err);
				this.getTransactions(this.params);
			}
		});
	}

	onAssignPspRequest(data: AssignPspData) {
		this.loading = true;

		this.pspService.updatePlayerPaymentRoutes(this.routePlayerID, data).subscribe({
			next: () => {
				const onPaymentRouteSuccessConsumer = (obj: PaymentRoute) => {
					this.toastDisplayService.addMessage({
						type: ToastMessageType.success,
						title: 'Success',
						description: `${obj.thirdPartyName} assigned as player PSP.`
					});
				}

				this.getPaymentRoute(onPaymentRouteSuccessConsumer);
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		})
	}

	onShowTransferDetailsDialog() {
		this.loading = true;

		this.withdrawalsService.getBankDetailsByPlayer(this.routePlayerID).subscribe({
			next: bankDetails => {
				this.loading = false;
				this.bankDetails = bankDetails;
				this.showTransferDetailsDialog = true;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		})
	}

	onShowBankDetailsDialog() {
		// this.loading = true;
		this.showBankDetailsDialog = true;

		/*this.withdrawalsService.getBankDetailsByPlayer(this.routePlayerID).subscribe({
			next: bankDetails => {
				this.loading = false;
				this.bankDetails = bankDetails;
				this.showTransferDetailsDialog = true;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		})*/
	}

	onShowExchangeHouseDialog() {
		// this.loading = true;
		this.showExchangeHouseDialog = true;

		/*this.withdrawalsService.getBankDetailsByPlayer(this.routePlayerID).subscribe({
			next: bankDetails => {
				this.loading = false;
				this.bankDetails = bankDetails;
				this.showTransferDetailsDialog = true;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		})*/
	}

	onUpdateBankDetails(data: TransferDetails) {
		this.showTransferDetailsDialog = false;
		this.loading = true;

		this.withdrawalsService.updateBankDetails(data).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: `Bank details updated for player ${this.routePlayerID}.`
				});

				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		})
	}

	exportToCSV(selectedData?: WalletTransaction[], isAllDataSelected?: boolean) {
		super.exportToCSV(selectedData, isAllDataSelected);

		this.loading = true;

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, selectedData, isAllDataSelected, ColumnNames.WALLET_TRANS_ID.DB
		);

		// Add single-column, ui-parsed invoice and credit notes - which does not exist in BE - separately for CSV call
		const csvCols = deepCopy(this.cols);
		const indexOfUiField = csvCols.findIndex(entry => entry.field === ColumnNames.WALLET_INVOICE_CREDIT_NOTE.DB);

		csvCols.splice(indexOfUiField, 1, ...[
			{
				field: ColumnNames.WALLET_INVOICE_NOTE.DB,
				header: ColumnNames.WALLET_INVOICE_NOTE.UI
			},
			{
				field: ColumnNames.WALLET_CREDIT_NOTE.DB,
				header: ColumnNames.WALLET_CREDIT_NOTE.UI
			},
		]);

		this.walletTransService.getTransactionsCsv(this.routePlayerID, exportHttpParams, csvCols).subscribe({
			next: (res: string) => {
				if (res !== undefined) {
					// download file
					FileDownloadUtility.downloadCsv(
						res,
						this.routePlayerID ? `WalletTransactionsForPlayer_${this.routePlayerID}` : 'WalletTransactionForPlayers'
					);
				}

				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onSelectedTargetDataChange() {
		this.getTableData();
	}
}
