import {Component, Input} from '@angular/core';
import {BaseSkeleton} from '../../base-skeleton';

@Component({
	selector: 'he-skeleton-form',
	templateUrl: './skeleton-form.component.html',
	styleUrls: ['./skeleton-form.component.scss']
})
export class SkeletonFormComponent extends BaseSkeleton {
	@Input() inputCount: '1' | '2' | '3' | '5' = '1';
}
