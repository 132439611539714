import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ChangePasswordComponent } from './change-password.component';
import { ChangePasswordService } from './change-password.service';
import { SharedModule } from '../shared';
import { ChangePlayerPasswordDialogComponent } from './change-player-password-dialog/change-player-password-dialog.component';
import {CalendarModule} from 'primeng/calendar';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DropdownModule} from 'primeng/dropdown';
import {InputNumberModule} from 'primeng/inputnumber';

@NgModule({
	declarations: [
		ChangePasswordComponent,
		ChangePlayerPasswordDialogComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		DialogModule,
		ButtonModule,
		InputTextModule,
		SharedModule,
		/*CalendarModule,
		InputTextareaModule,
		DropdownModule,
		InputNumberModule*/
	],
	providers: [
		ChangePasswordService
	],
	exports: [
		ChangePasswordComponent,
		ChangePlayerPasswordDialogComponent
	]
})
export class ChangePasswordModule { }
