import {Injectable} from '@angular/core';
import {AppConfigService, BaseService} from '../../../helio-core-services';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ResponseBodyType, ServiceAction, ServiceController, TableDataResponse} from '../../../shared';
import {Observable} from 'rxjs';
import {
	BankAccount,
	BankAccountRequest,
	BankCard,
	ExchangeHouse,
	ExchangeHouseRequest
} from '../../../shared/models/finance/transfer-information.model';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class BankCardService extends BaseService {

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.PAYMENTS, appConfigService.serviceBaseURL);
	}

	getCards(playerID: number, searchParams?: HttpParams): Observable<TableDataResponse<BankCard>> {
		const getSrc = (methodName: string): string => {
			methodName = methodName.toLowerCase();

			if (methodName === 'visa') {
				return '/assets/lottery-logos/visa-logo-blue.png';
			} else if (methodName === 'mastercard') {
				return '/assets/lottery-logos/mastercard-logo.png';
			} else {
				return '/assets/lottery-logos/unknown_card_type.png';
			}
		}

		return this.validateDataTableRes(
			['playerPaymentAccountID', 'accountReference', 'paymentMethodName'], undefined,
			[playerID, ServiceAction.GET_PAYMENT_ACCOUNTS], searchParams
		).pipe(
			map(res => {
				const parsedData = res.resultSet.map((entry: any) => {
					const tempObj: BankCard = {...entry};

					tempObj.src = getSrc(tempObj.paymentMethodName);

					// Parse Expiry date string
					// const month = String(entry.expiryMonth);
					// tempObj.expiryDateStr = `${(month.length === 1) ? '0' : ''}${entry.expiryMonth}/${entry.expiryYear}`;

					// Parse Date objects
					tempObj.dateCreated = new Date(entry.dateCreated);
					tempObj.lastUsed = new Date(entry.lastUsed);

					return tempObj;
				});

				res.resultSet = parsedData;

				return res as TableDataResponse<BankCard>;
			})
		);

		// return of(dummyWalletCardsData).pipe(delay(200));
	}

	setAsDefault(playerID: number, method: BankCard): Observable<void> {
		return this.edit(
			undefined, undefined, undefined,
			[
				playerID,
				ServiceAction.GET_PAYMENT_ACCOUNTS,
				method.playerPaymentAccountID,
				ServiceAction.GET_PAYMENT_ACCOUNTS_SET_DEFAULT
			]
		);
	}

	remove(playerID: number, method: BankCard): Observable<void> {
		return this.delete(
			undefined,
			[playerID, ServiceAction.GET_PAYMENT_ACCOUNTS, method.playerPaymentAccountID]
		);
	}

	getExchangeHouses(playerID: number, searchParams?: HttpParams, getDeleted: boolean = false)
		: Observable<TableDataResponse<ExchangeHouse>> {
		let params = searchParams ?? new HttpParams();
		params = params.set('playerID', playerID)
			.set('getDeleted', getDeleted);

		return this.validateDataTableRes(
			['playerPaymentAccountID', 'accountReference', 'withdrawalMethod'], ServiceAction.EXCHANGE_HOUSES,
			undefined, params, ResponseBodyType.JSON, ServiceController.WITHDRAWALS
		).pipe(
			map(res => {
				const parsedData = res.resultSet.map((entry: any) => {
					const tempObj: ExchangeHouse = {...entry};

					// Parse Date objects
					tempObj.dateCreated = new Date(entry.dateCreated);
					tempObj.lastUsed = new Date(entry.lastUsed);
					tempObj.lastUpdated = new Date(entry.lastUpdated);

					return tempObj;
				});

				res.resultSet = parsedData;

				return res as TableDataResponse<ExchangeHouse>;
			})
		);
	}

	addExchangeHouse(data: ExchangeHouseRequest): Observable<ExchangeHouse> {
		return this.post(ServiceAction.EXCHANGE_HOUSES, data, undefined, undefined, ServiceController.WITHDRAWALS);
	}

	updateExchangeHouse(data: ExchangeHouseRequest) {
		return this.edit(ServiceAction.EXCHANGE_HOUSES, data, undefined, undefined, ServiceController.WITHDRAWALS);
	}

	deleteExchangeHouse(playerPaymentAccountID: number): Observable<void> {
		const params = new HttpParams().set('playerPaymentAccountID', playerPaymentAccountID);

		return this.delete(ServiceAction.EXCHANGE_HOUSES, undefined, params, ServiceController.WITHDRAWALS);
	}

	getBankAccounts(playerID: number, searchParams?: HttpParams, getDeleted: boolean = false)
		: Observable<TableDataResponse<BankAccount>> {
		let params = searchParams ?? new HttpParams();
		params = params.set('playerID', playerID)
			.set('getDeleted', getDeleted);

		return this.validateDataTableRes(
			['playerPaymentAccountID', 'accountReference', 'withdrawalMethod'], ServiceAction.BANK_ACCOUNTS,
			undefined, params, ResponseBodyType.JSON, ServiceController.WITHDRAWALS
		).pipe(
			map(res => {
				const parsedData = res.resultSet.map((entry: any) => {
					const tempObj: BankAccount = {...entry};

					// Parse Date objects
					tempObj.dateCreated = new Date(entry.dateCreated);
					tempObj.lastUsed = new Date(entry.lastUsed);
					tempObj.lastUpdated = new Date(entry.lastUpdated);

					return tempObj;
				});

				res.resultSet = parsedData;

				return res as TableDataResponse<BankAccount>;
			})
		);
	}

	addBankAccount(data: BankAccountRequest): Observable<ExchangeHouse> {
		return this.post(ServiceAction.BANK_ACCOUNTS, data, undefined, undefined, ServiceController.WITHDRAWALS);
	}

	updateBankAccount(data: BankAccountRequest) {
		return this.edit(ServiceAction.BANK_ACCOUNTS, data, undefined, undefined, ServiceController.WITHDRAWALS);
	}

	deleteBankAccount(playerPaymentAccountID: number): Observable<void> {
		const params = new HttpParams().set('playerPaymentAccountID', playerPaymentAccountID);

		return this.delete(ServiceAction.BANK_ACCOUNTS, undefined, params, ServiceController.WITHDRAWALS);
	}
}
