<he-base-dialog header="{{dialogTitle}}" [(visible)]="visible" [isLoadingLookups]="isLoadingRestrictionTypeLookups"
				[style]="{'max-width': '550px', width: '550px'}" (visibleChange)="onCloseDialog()"
				[submitLabel]="submitLabel" (submitEvent)="onSubmit()" [disableSubmit]="!form.valid">

	<form [formGroup]="form" class="dialog-form-placement" autocomplete="off">
		<label for="playerID">Player ID</label>
		<input pInputText id="playerID" formControlName="playerID" autocomplete="off" type="text"
			   [class.he-disabled]="true"/>

		<!-- Removing for now since this is auto selected based on restrictionType; as in directed by Casey -->
		<!--<label for="restrictionTypeID">{{'Restriction Type'}}</label>
		<p-dropdown id="restrictionTypeID" [options]="restrictionTypeLookup" appendTo="body"
					formControlName="restrictionTypeID" placeholder="Please select a restriction type">
		</p-dropdown>-->

		<ng-container
			*ngIf="restrictionType !== DialogType.BLACKLIST && restrictionType !== DialogType.DEACTIVATE_ACCOUNT">
			<label for="intervalType">{{ 'Interval Type' }}</label>
			<p-dropdown id="intervalType" [options]="intervalTypeLookup" appendTo="body"
						formControlName="intervalType" placeholder="Please select an interval type">
			</p-dropdown>

			<label for="numUnits">Number Units</label>
			<p-inputNumber id="numUnits" formControlName="numUnits" [useGrouping]="false" mode="decimal"/>
		</ng-container>

		<label for="comment">Comment</label>
		<textarea pInputTextarea id="comment" formControlName="comment" type="text" rows="5"></textarea>
	</form>
</he-base-dialog>

