<div class="card ui-g">
	<div class="search-header">
		<h3>{{componentType === SearchType.ADVANCED_SEARCH ? 'Advanced Search' : 'Filters'}}</h3>

		<div style="margin-bottom: 10px;">
			<span>{{'Use the dropdown list to select and add ' + componentType + ' fields to build your query.'}}</span>
			<span>{{'For each ' + componentType + ' field you can set the operator and value.'}}</span>
		</div>

		<div id="opts-adder">
			<p-dropdown [options]="searchFields" [optionLabel]="'label'" [(ngModel)]="searchFieldToAdd"
						[placeholder]="'Select ' + capitalise(componentType) + ' Field'"
						(onClick)="onSelectSearchFieldClicked()" data-cy=search-field-dropdown-trigger
						[filter]="true" [class.cy-filterable-list]="true"></p-dropdown>

			<button class="helio-orange-btn search-field-add" pButton (click)="addSearchField()" icon="ui-icon-add"
					title="Add Search Field"></button>
		</div>
	</div>

	<div class="search-fields-wrapper ui-g-12">
		<ng-template #searchFieldHost heAdvancedSearchFieldHost></ng-template>
	</div>

	<div class="search-footer ui-g-12">
		<div class="pull-right">
			<button style="width:auto" class="transparent-btn flat" pButton (click)="closeSearchClick()" label="Close"
					icon="ui-icon-close"></button>

			<button style="width:auto" [disabled]="searchFieldComponents.length === 0" pButton
					(click)="clearSearchClick()" label="Clear" icon="ui-icon-clear-all"></button>

			<button style="width:auto" [disabled]="searchFieldComponents.length === 0" class="helio-orange-btn" pButton
					(click)="submitSearchClick()" label="Search" icon="ui-icon-search"></button>
		</div>
	</div>
</div>
