import { Component, Input, OnChanges } from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import { ValidationService } from './validation.service';
@Component({
	selector: 'he-form-messages',
	styleUrls: [
		'./form-messages.component.scss'
	],
	templateUrl: './form-messages.component.html'
})
export class FormMessagesComponent implements OnChanges {
	errorMessage: string;

	@Input() errors: ValidationErrors;
	@Input() reaction: boolean;

	ngOnChanges() {
		this.setErrorMessage();
	}

	setErrorMessage() {
		if (this.errors !== null) {
			for (const fieldName in this.errors) {
				if (this.errors.hasOwnProperty(fieldName) && this.reaction) {
					this.errorMessage = ValidationService.getValidatorErrorMessage(fieldName, this.errors[fieldName]);
					break;
				}
			}
		} else {
			this.errorMessage = null;
		}
	}
}
