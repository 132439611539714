<!-- <span id="title">
	<span class="title-1">helio</span><span class="title-2">engine</span>
</span> -->

<div class="logo-wrapper" (click)="onLogoClicked()">
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	width="333.779px" height="108.075px" viewBox="0 0 333.779 108.075" enable-background="new 0 0 333.779 108.075" xml:space="preserve">
		<path class="main-font-colour" d="M87.167,97.136v-0.063c0-5.954,4.558-10.999,10.969-10.999c3.19,0,5.317,0.76,7.263,2.157
		c0.365,0.273,0.76,0.791,0.76,1.52c0,1.003-0.822,1.854-1.854,1.854c-0.487,0-0.852-0.213-1.155-0.424
		c-1.367-1.004-2.857-1.672-5.165-1.672c-3.92,0-6.898,3.434-6.898,7.504v0.061c0,4.377,2.887,7.6,7.231,7.6
		c2.005,0,3.83-0.641,5.135-1.611v-3.982h-4.192c-0.911,0-1.671-0.697-1.671-1.609s0.76-1.641,1.671-1.641h5.925
		c1.065,0,1.884,0.82,1.884,1.885v5.682c0,1.064-0.424,1.824-1.306,2.371c-1.824,1.215-4.376,2.309-7.568,2.309
		C91.542,108.075,87.167,103.335,87.167,97.136" />
		<path class="main-font-colour" d="M116.789,105.28l7.901-17.626c0.427-0.942,1.186-1.519,2.249-1.519h0.184c1.062,0,1.792,0.576,2.216,1.519
		l7.901,17.626c0.154,0.273,0.214,0.547,0.214,0.791c0,1.002-0.76,1.791-1.763,1.791c-0.881,0-1.489-0.518-1.824-1.307l-1.73-3.98
		h-10.334l-1.792,4.104c-0.303,0.758-0.941,1.184-1.733,1.184c-0.973,0-1.732-0.76-1.732-1.732
		C116.546,105.856,116.637,105.583,116.789,105.28 M130.707,99.263l-3.738-8.6l-3.736,8.6H130.707z" />
		<path class="main-font-colour" d="M147.569,88.201c0-1.032,0.819-1.884,1.884-1.884h0.395c0.819,0,1.367,0.426,1.732,1.003l6.442,10.089
		l6.471-10.119c0.397-0.639,0.943-0.973,1.703-0.973h0.396c1.064,0,1.884,0.852,1.884,1.884v17.808c0,1.033-0.819,1.854-1.884,1.854
		c-1.003,0-1.855-0.85-1.855-1.854V93.245l-5.195,7.811c-0.395,0.609-0.881,0.973-1.581,0.973c-0.668,0-1.154-0.363-1.549-0.973
		l-5.166-7.75v12.734c0,1.033-0.821,1.822-1.854,1.822c-1.033,0-1.822-0.789-1.822-1.822V88.201z" />
		<path class="main-font-colour" d="M180.229,88.141c0-1.033,0.822-1.853,1.884-1.853c1.033,0,1.854,0.819,1.854,1.853v17.868
		c0,1.033-0.82,1.854-1.854,1.854c-1.062,0-1.884-0.82-1.884-1.854V88.141z" />
		<path class="main-font-colour" d="M195.727,88.201c0-1.032,0.818-1.884,1.884-1.884h0.395c0.911,0,1.427,0.456,1.944,1.094l10.636,13.766
		V88.11c0-1.003,0.822-1.822,1.824-1.822c1.033,0,1.852,0.819,1.852,1.822v17.867c0,1.035-0.789,1.855-1.821,1.855h-0.151
		c-0.881,0-1.43-0.455-1.946-1.125l-10.938-14.162v13.494c0,1.002-0.822,1.822-1.825,1.822c-1.033,0-1.852-0.82-1.852-1.822V88.201z"
		/>
		<path class="main-font-colour" d="M224.863,97.136v-0.063c0-5.954,4.559-10.999,10.97-10.999c3.189,0,5.316,0.76,7.263,2.157
		c0.365,0.273,0.76,0.791,0.76,1.52c0,1.003-0.822,1.854-1.854,1.854c-0.487,0-0.853-0.213-1.155-0.424
		c-1.367-1.004-2.857-1.672-5.165-1.672c-3.92,0-6.898,3.434-6.898,7.504v0.061c0,4.377,2.887,7.6,7.231,7.6
		c2.005,0,3.83-0.641,5.135-1.611v-3.982h-4.191c-0.911,0-1.672-0.697-1.672-1.609s0.761-1.641,1.672-1.641h5.925
		c1.064,0,1.884,0.82,1.884,1.885v5.682c0,1.064-0.424,1.824-1.306,2.371c-1.824,1.215-4.376,2.309-7.568,2.309
		C229.237,108.075,224.863,103.335,224.863,97.136" />
		<path class="secondary-font-colour" d="M300.475,66.61c-18.365,0-33.305-14.94-33.305-33.305c0-18.364,14.939-33.305,33.305-33.305
		s33.305,14.941,33.305,33.305C333.779,51.67,318.84,66.61,300.475,66.61 M300.475,14.721c-10.247,0-18.585,8.337-18.585,18.585
		s8.338,18.585,18.585,18.585s18.585-8.337,18.585-18.585S310.722,14.721,300.475,14.721" />
		<path class="main-font-colour" d="M240.313,66.61c-4.065,0-7.36-3.295-7.36-7.359V7.361c0-4.065,3.295-7.36,7.36-7.36
		c4.064,0,7.359,3.295,7.359,7.36v51.89C247.673,63.315,244.378,66.61,240.313,66.61" />
		<path class="main-font-colour" d="M57.404,0c-4.065,0-7.36,3.295-7.36,7.36v18.584H14.721V7.361c0-4.065-3.296-7.36-7.36-7.36
		C3.296,0,0,3.295,0,7.361v51.89c0,4.064,3.296,7.359,7.361,7.359c4.064,0,7.36-3.295,7.36-7.359V40.666h35.323v18.585
		c0,4.064,3.295,7.359,7.36,7.359c4.065,0,7.36-3.295,7.36-7.359V7.361C64.764,3.295,61.469,0,57.404,0" />
		<path class="main-font-colour" d="M141.664,14.72H91.621c-4.065,0-7.361-3.295-7.361-7.36S87.556,0,91.621,0h50.043
		c4.065,0,7.36,3.295,7.36,7.36S145.729,14.72,141.664,14.72" />
		<path class="main-font-colour" d="M141.664,66.623H91.621c-4.065,0-7.361-3.295-7.361-7.36c0-4.064,3.296-7.36,7.361-7.36h50.043
		c4.065,0,7.36,3.295,7.36,7.36C149.024,63.328,145.729,66.623,141.664,66.623" />
		<path class="main-font-colour" d="M129.943,40.672H91.621c-4.065,0-7.36-3.295-7.36-7.36s3.295-7.36,7.36-7.36h38.322
		c4.065,0,7.36,3.295,7.36,7.36S134.008,40.672,129.943,40.672" />
		<path class="main-font-colour" d="M206.097,51.903h-22.855V7.36c0-4.065-3.296-7.36-7.361-7.36c-4.063,0-7.36,3.295-7.36,7.36v51.891
		c0,0.002,0.002,0.004,0.002,0.006s-0.002,0.004-0.002,0.006c0,4.064,3.297,7.359,7.36,7.359h30.217c4.065,0,7.36-3.295,7.36-7.359
		S210.162,51.903,206.097,51.903" />
	</svg>
</div>
