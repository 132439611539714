import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AppLayoutService} from '../layout/secure/app-layout.service';
import {BoErrorHandlerService, LoginService, UsersService} from '../helio-core-services';
import {UserV2} from '../shared/models';
import {Subscription} from 'rxjs';
import {goToProfile, logOutAndGotoLogin} from './app-topbar.function';

@Component({
	selector: 'he-topbar',
	templateUrl: './app-topbar.component.html',
	styleUrls: ['./app-topbar.component.scss']
})
export class AppTopBarComponent implements OnInit, OnDestroy {
	mobileMenuActive = false;
	loggedInUser: UserV2;
	isUserLoggedIn: boolean;
	userObservable: Subscription;

	@Output() toggleMenu = new EventEmitter<void>();

	constructor(
		private router: Router,
		private loginService: LoginService,
		private usersService: UsersService,
		private appLayoutService: AppLayoutService,
		private boErrorHandlerService: BoErrorHandlerService
	) {
	}

	ngOnInit() {
		this.isUserLoggedIn = sessionStorage.getItem('log_status') === 'true';

		if (this.isUserLoggedIn) {
			this.userObservable = this.usersService.getUser().subscribe({
				next: user => {
					this.loggedInUser = user;
				},
				error: err => {
					this.boErrorHandlerService.handleError(err);
				}
			});
		}
	}

	ngOnDestroy() {
		this.userObservable.unsubscribe();
	}

	onMenuButtonClick(event: Event) {
		this.appLayoutService.menuButtonClick = true;
		this.toggleMenu.emit();
		event.preventDefault();
	}

	goToProfile() {
		goToProfile(this.router);
	}

	logOut() {
		logOutAndGotoLogin(this.router, this.loginService);
	}
}
