
export function isArrayInit(arr: any[]) {
	return Array.isArray(arr) && (arr.length > 0);
}

export function isNotFalse(v: boolean) {
	return v !== false;
}

// Copy the object without making references to the original
export function deepCopy<T>(o: T): T {
	return JSON.parse(JSON.stringify(o));
}
