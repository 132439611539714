import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {AppLayoutService} from '../layout/secure/app-layout.service';
import {BoErrorHandlerService, LoginService, UsersService} from '../helio-core-services';
import {logOutAndGotoLogin, goToProfile} from '../topbar/app-topbar.function';
import {AppGuard, UserV2} from '../shared';
import {Subscription} from 'rxjs';
import {AppSubMenuComponent, ToggleMenuListener} from './sub-menu';
import {AppComponent, WindowResizeListener} from '../app.component';
// TODO - Replace JSON with SCCS - css Custom Variables since json requires sass-json-vars dependency
import deviceWidthSpec from '../../assets/ts-scss-vars/device-width-spec.json';
import {NavigationItem} from '../shared/models/general/navigation-item.model';
import {HeMenuItem} from '../shared/models/primeng-overrides/he-menu-item.interface';
import {ACTIVE_DRAW, NAV_SEGMENT, REMOVE_FROM_MENU} from '../shared/constants/navigation-titles';

export type MouseOverType = 'enter' | 'leave';

export type DeviceType = 'mobile' | 'tablet' | 'desktop' | undefined;

export interface Coordinate {
	x: number;
	y: number;
}

/*const MOBILE_MAX = 750;
const TABLET_MAX = 1024;
export const STD_MOBILE_MAX =  MOBILE_MAX;
export const STD_TABLET_MIN = (MOBILE_MAX + 1);
export const STD_TABLET_MAX =  TABLET_MAX;
export const STD_DESKTOP_MIN =  (TABLET_MAX + 1);*/

@Component({
	selector: 'he-navigation',
	styleUrls: [
		'./navigation.component.scss'
	],
	templateUrl: './navigation.component.html',
	animations: [
		trigger('fadeLogo', [
			state('false', style({opacity: 0})),
			state('true', style({opacity: 1})),
			transition('1 <=> 0', animate('250ms'))
		])
	]
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy, ToggleMenuListener, WindowResizeListener, ToggleMenuListener {

	public static _isMenuPinned = false; // Flag whether menu is pinned or collapsed. Static so that it's persistent on window resize.
	public static FIELD_IS_MENU_PINNED = 'device_isMenuPinnedState';

	TAG = NavigationComponent.name;
	activeNavItem: NavigationItem;
	menuItems: HeMenuItem[];
	reset = false;
	previousPoint: Coordinate | null;
	registerSwipe = false;
	deviceType: DeviceType = undefined;

	@Input() menuActive: boolean; // true indicates expanded state, false indicates collapsed
	@Output() setToggleMenuLis = new EventEmitter<ToggleMenuListener>();
	@Output() slideMenuStateChange = new EventEmitter<boolean>(); // Prompt toggling of menu between pinned and collapsed states.
	@Output() heMouseOverEvent = new EventEmitter<MouseOverType>();

	loggedInUser: UserV2;
	userObservable: Subscription;
	private userName = '';
	private sliderMenu: HTMLElement;
	private transitionInProgress = false;
	private outstandingTransitionRequest: MouseOverType | null = null;

	refinedMenuItems: HeMenuItem[];

	constructor(
		private appGuard: AppGuard,
		private router: Router,
		private loginService: LoginService,
		private appLayoutService: AppLayoutService,
		private usersService: UsersService,
		private boErrorHandlerService: BoErrorHandlerService
	) {
	}

	get isMenuPinned() {
		return NavigationComponent._isMenuPinned;
	}

	ngOnInit() {
		AppSubMenuComponent.setToggleMenuListener(this);

		AppComponent.registerWindowWidthLis(this);

		this.userName = sessionStorage.getItem('user_name');
		this.setMenuItems();

		if (sessionStorage.getItem('log_status') === 'true') {
			this.userObservable = this.usersService.getUser().subscribe({
				next: user => {
					this.loggedInUser = user;
				},
				error: err => {
					this.boErrorHandlerService.handleError(err);
				}
			});
		}

		this.refineMenuItems();

		this.setToggleMenuLis.emit(this);
	}

	ngAfterViewInit(): void {
		this.sliderMenu = document.querySelector('div.layout-sidebar');
		this.sliderMenu.addEventListener('mousemove', this.handleMouseMove, false);
		this.sliderMenu.addEventListener('touchmove', this.handleMouseMove, false);
		this.sliderMenu.addEventListener('mousedown', this.handleMouseDown, false);
		this.sliderMenu.addEventListener('touchstart', this.handleMouseDown, false);
		this.sliderMenu.addEventListener('mouseup', this.handleMouseUp, false);
		this.sliderMenu.addEventListener('touchend', this.handleMouseUp, false);

		const layoutSidebar = document.querySelector('.layout-sidebar') as HTMLElement;

		layoutSidebar.addEventListener('transitionstart', ($event) => {
			this.transitionInProgress = true;
		});

		layoutSidebar.addEventListener('transitionend', ($event) => {
			this.transitionInProgress = false;

			if (this.outstandingTransitionRequest) {
				// manually trigger mouseleave - in case such a request was made during transition (and aborted to avoid jitter)
				this.mouseOverHandler(this.outstandingTransitionRequest);
				this.outstandingTransitionRequest = null;
			}
		});

		const expandUI = document.querySelector('#menu-bottom') as HTMLElement;
		// Use Attribute to pass on the flag to #onSlideMenuStateChange - when expandUI is clicked - that localStorage value be used
		expandUI.setAttribute(NavigationComponent.FIELD_IS_MENU_PINNED, String(this.getHistoricIsMenuPinned()));
		expandUI.click();
	}

	ngOnDestroy() {
		if (this.userObservable) {
			this.userObservable.unsubscribe();
		}

		AppSubMenuComponent.setToggleMenuListener(null);

		if (this.sliderMenu) {
			this.sliderMenu.removeEventListener('mousemove', this.handleMouseMove, false);
			this.sliderMenu.removeEventListener('touchmove', this.handleMouseMove, false);
			this.sliderMenu.removeEventListener('mousedown', this.handleMouseDown, false);
			this.sliderMenu.removeEventListener('touchstart', this.handleMouseDown, false);
			this.sliderMenu.removeEventListener('mouseup', this.handleMouseUp, false);
			this.sliderMenu.removeEventListener('touchend', this.handleMouseUp, false);
		}

		AppComponent.registerWindowWidthLis(null);
	}

	getHistoricIsMenuPinned(): boolean | undefined {
		const flag = localStorage.getItem(NavigationComponent.FIELD_IS_MENU_PINNED);

		if (!flag) {
			return false;
		}

		return Boolean(Number(flag));
	}

	/**
	 * Add only menuItems with API page permissions and only include those with at least 1 child.
	 */
	refineMenuItems() {
		const tempMenuItems = this.menuItems;// JSON.parse(JSON.stringify(this.menuItems));
		// Using JSON.parse(...) does not work since command field cannot be retained

		for (const entry of tempMenuItems) {
			if (entry?.items) {
				const itemsWithRights = [];

				entry.items.forEach((itemEntry, index) => {
					const hasPageRights = this.appGuard.hasPageRights(itemEntry?.routeName); // 'my-profile'

					if (hasPageRights) {
						// entry.items.splice(index, 1);
						itemsWithRights.push(itemEntry);
					}
				});

				entry.items = itemsWithRights;
			}
		}

		this.refinedMenuItems = tempMenuItems.filter(entry => {
			// Only include the menuItem, if it has at least 1 child OR it's the logout item
			// (where we do want it to be without children)
			return (entry?.items && entry?.items.length > 0) ||
				entry.routeName === NAV_SEGMENT.LOGOUT;
		})
	}

	onToggleMenu() {
		this.onSlideMenuStateChange();
	}

	onWindowResize(width: number) {
		if (width <= deviceWidthSpec.stdMobileMax) {
			this.deviceType = 'mobile';
		} else if (width <= deviceWidthSpec.stdTabletMax) {
			this.deviceType = 'tablet';
		} else {
			// When window width is > 1024 (i.e. desktop) and table container is >= 1300, flag isMenuPin, so that the conditional
			// values in #menu-bottom within template is aligned with _main.scss "@media (min-width: $stdDesktop_min)"
			/*if (!this.deviceType) {
				NavigationComponent._isMenuPinned = true;
			}*/

			this.deviceType = 'desktop';
		}
	}

	setMenuItems() {
		this.menuItems = [
			{
				label: 'User Management', icon: 'person', routeName: '/user-management', items: [
					{label: 'Users', icon: 'person', routeName: '/user-management/users', routerLink: ['/user-management/users']},
					{label: 'Groups', icon: 'people', routeName: '/group-management/groups', routerLink: ['/group-management/groups']}
				]
			},
			{
				label: 'Tenant Management', icon: 'work', routeName: '/tenant-management', routerLink: ['/tenant-management']
			},
			{
				label: 'Player Management', icon: 'account_circle', routeName: '/player-management', items: [
					{
						label: 'Players',
						icon: 'account_circle',
						routeName: '/player-management/players',
						routerLink: ['/player-management/players']
					},
					{
						label: 'Players Audit',
						icon: 'pi-history',
						routeName: '/player-management/players-audit',
						routerLink: ['/player-management/players-audit']
					},
					// wallet player management
					{label: 'Players', icon: 'account_circle', routeName: '/wallet/wallet-players', routerLink: ['/wallet/wallet-players']},
					{
						label: 'KYC',
						icon: 'folder_shared',
						routeName: REMOVE_FROM_MENU, // `player-management/${NAV_SEGMENT.PLAYER_KYC}`,
						routerLink: [`/player-management/${NAV_SEGMENT.PLAYER_KYC}`]
					},
					{
						label: 'Transactional KYC',
						icon: 'folder',
						routeName: `/player-management/${NAV_SEGMENT.GLOBAL_KYC}`,
						routerLink: [`/player-management/${NAV_SEGMENT.GLOBAL_KYC}`]
					},
					{
						label: 'Withdrawal Requests',
						icon: 'payments',
						routeName: '/player-management/withdrawal-requests',
						routerLink: ['/player-management/withdrawal-requests']
					},
					{
						label: 'Winning Participations',
						icon: 'local_play',
						routeName: REMOVE_FROM_MENU, // 'player-management/winning-participations',
						routerLink: ['/player-management/winning-participations']
					}
				]
			},
			{
				label: 'Ticket Management', icon: 'local_offer', routeName: 'ticket-management', items: [
					{
						label: 'Issue Batch', icon: 'queue',
						routeName: '/ticket-management/issue-batch', routerLink: ['/ticket-management/issue-batch']
					},
					{
						label: 'Distributed Batches',
						icon: 'donut_small',
						routeName: REMOVE_FROM_MENU, // 'ticket-management/distributed-batches',
						routerLink: ['/ticket-management/distributed-batches']
					},
					{
						label: 'Subscriptions', icon: 'book',
						routeName: '/ticket-management/subscriptions-tab', routerLink: ['/ticket-management/subscriptions-tab']
					}
				]
			},
			{
				label: 'Draw Processing', icon: 'gradient', routeName: '/draw-processing', items: [
					// {
					// 	label: 'Current Draws', icon: 'gradient',
					// 	routeName: '/draw-processing/current-draws', routerLink: ['/draw-processing/current-draws']
					// },
					{
						label: ACTIVE_DRAW, icon: 'hdr_strong',
						routeName: '/draw-processing/upcoming-draws', routerLink: ['/draw-processing/upcoming-draws']
					},
					{
						label: 'Previous Draws', icon: 'hdr_weak',
						routeName: '/draw-processing/previous-draws', routerLink: ['/draw-processing/previous-draws']
					}
				]
			},
			{
				label: 'Reports', icon: 'show_chart', routeName: '/reports', items: [
					{
						label: 'Reports',
						icon: 'show_chart',
						routeName: '/reports',
						routerLink: ['/reports']
					}
				]
			},
			{
				label: 'Finance', icon: 'receipt', routeName: '/finance', items: [
					{
						label: 'Wallet Transactions', icon: 'account_balance_wallet',
						routeName: '/finance/wallet-transaction', routerLink: ['/finance/wallet-transaction']
					},
					{
						// TODO - Add "routeName: 'top-up-cards'" once BE is ready
						label: 'Top-Up Cards', icon: 'pi-ticket',
						routeName: '/finance/topup-cards', routerLink: ['/finance/topup-cards']
					},
					{
						label: 'Payment Fallback', icon: 'pi pi-sort-numeric-down',
						routeName: '/finance/payment-providers-fallback', routerLink: ['/finance/payment-providers-fallback']
					},
					{
						label: 'Payment Distribution', icon: 'pi-percentage',
						routeName: '/finance/payment-providers-distribution', routerLink: ['/finance/payment-providers-distribution']
					}
				]
			},
			/*{
				label: 'BillingFinance', icon: 'account_balance_wallet', routeName: '/financial', items: [
					{
						label: 'Billing Aggregates', icon: 'account_balance_wallet',
						routeName: '', routerLink: ['/billing-finance/daily-aggregates']
					},
					{
						label: 'Billing Invoices',
						icon: 'receipt',
						routeName: '',
						routerLink: ['/billing-finance/billing-invoices']
					},
					{
						label: 'Bank Accounts',
						icon: 'account_balance',
						routeName: '',
						routerLink: ['/billing-finance/bank-accounts']
					},
					{
						label: '', icon: 'compare_arrows',
						routeName: 'billing-finance/bank-nominal-mappings', routerLink: ['/financial/bank-nominal-mappings']
					}
				]
			},*/
			{
				label: 'Retail Management', icon: 'contacts', routeName: '/retail-agents', items: [
					{
						label: 'Find Ticket',
						icon: 'search',
						routeName: '/retail-agents/find-ticket',
						routerLink: ['/retail-agents/find-ticket']
					},
					{label: 'Agents', icon: 'recent_actors', routeName: '/retail-agents/agents', routerLink: ['/retail-agents/agents']},
					{
						label: 'Reconciliation', icon: 'playlist_add_check',
						routeName: '/retail-agents/reconciliation', routerLink: ['/retail-agents/reconciliation']
					}
				]
			},
			// Adding  - routeName: '' - is necessary for the label to show since this circumvents the heShowNavItem
			// Directive been applied to show/hide pages - in sub-menu.component - if its routeName is not present.
			/*{
				label: 'Logout', icon: 'pi-sign-out', routeName: NAV_SEGMENT.LOGOUT, command: () => this.logOut()
			},*/
		];

		// renaming player menu items if user has rights to both player management pages
		// remove when wallet functionality is removed!
		if (this.appGuard.hasPageRights('wallet/wallet-players') && this.appGuard.hasPageRights('player-management/players')) {
			this.menuItems[2].items[0].label = 'Engine Players';
			this.menuItems[2].items[1].label = 'Wallet Players';
		}
	}

	onSidebarClick(event: Event) {
		this.appLayoutService.menuClick = true;
	}

	activateNavItem(navItem: NavigationItem) {
		this.userName = sessionStorage.getItem('user_name');
		// set active to false for the current activateNavItem
		if (this.activeNavItem === undefined) {
			this.activeNavItem = navItem;
		} else {
			this.activeNavItem.isActive = false;
		}

		navItem.isActive = true;
		this.activeNavItem = navItem;
	}

	onSlideMenuStateChange(event?: any) {
		if (event?.target.getAttribute(NavigationComponent.FIELD_IS_MENU_PINNED)) {
			// Re-initialising from LocalStorage
			event?.target.removeAttribute(NavigationComponent.FIELD_IS_MENU_PINNED); // Remove so it is not re-flagged as init from storage
			NavigationComponent._isMenuPinned = this.getHistoricIsMenuPinned(); // Align value with LocalStorage
		} else {
			// Toggle case
			NavigationComponent._isMenuPinned = (event === true || event === false) ? event : !NavigationComponent._isMenuPinned;
		}

		this.slideMenuStateChange.emit(NavigationComponent._isMenuPinned);

		localStorage.setItem(NavigationComponent.FIELD_IS_MENU_PINNED, NavigationComponent._isMenuPinned ? '1' : '0');
	}

	goToProfile() {
		goToProfile(this.router);
		// this.toggleMenu.emit();
	}

	logOut() {
		logOutAndGotoLogin(this.router, this.loginService);
	}

	mouseOverHandler(type: MouseOverType) {
		if (!this.transitionInProgress) {
			this.heMouseOverEvent.emit(type)
		} else {
			this.outstandingTransitionRequest = type;
		}
	}

	private handleMouseUp = (e: any) => {
		this.registerSwipe = false; // Flag false so mouse movement are no longer interpreted as swipe
		this.previousPoint = null; // Clear previousPoint so that different swipes are not interpreted as one
	};

	private handleMouseDown = (e: any) => {
		this.registerSwipe = true; // Flag true so mouse movement over menu is interpreted as swipe
	};

	private handleMouseMove = (e: any) => {
		e.preventDefault();

		if (!this.menuActive || this.deviceType === 'desktop') {
			// Ensures that
			// 1) toggle is NOT triggered multiple times (i.e. from disjointed swipe to collapse)
			// 2) Sliding functionality is disabled in desktop
			return;
		}

		const eventObj = e.type === 'touchmove' ? e.changedTouches[0] : e;
		const currentPoint = this.getMouse(eventObj, this.sliderMenu);

		if (!this.previousPoint) {
			this.previousPoint = currentPoint;
		}

		const dist = this.horizontalDistance(this.previousPoint, currentPoint);
		// console.warn(this.TAG, '#handleMouseMove: dist =', dist, '; previousPoint =', this.previousPoint, '; currentPoint =', currentPoint);

		if (dist <= 0) {
			this.previousPoint = currentPoint;
		} else if (this.registerSwipe && dist > 15) {
			this.onToggleMenu();
		}
	};

	private getMouse(e: any, slider: HTMLElement) {
		const rect = slider.getBoundingClientRect();
		const x = (e.clientX - rect.left);
		const y = (e.clientY - rect.top);

		return {
			x,
			y
		};
	}

	private horizontalDistance = (previous: Coordinate, current: Coordinate): number => {
		// Ignore if the motion is not horizontal
		const min = previous.y - 5;
		const max = previous.y + 5;

		if (current.y < min || current.y > max) {
			return 0;
		}

		return previous.x - current.x; // positive value indicates collapsing motion, i.e, towards the left of the screen
	};
}
