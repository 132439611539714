export class Login {
	client_id: string;

	constructor(
		public username: string = '',
		public password: string = '',
		public grant_type: string = 'password'
	) {
	}
}

export interface LogoutData {
	client_id: string;
	grant_type: string;
	refresh_token: string;
	action_type: string;
}

export interface LogoutRes {
	IsSuccess: boolean;
	ErrorMsg: any;
	ErrorCode: any;
}
