import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BoErrorHandlerService, BreadcrumbService, ToastDisplayService} from '../helio-core-services';
import {ToastMessageType} from '../shared';
import {DashboardService} from '../shared/services/dashboard.service';
import {CustomWidgetDto, WidgetData, WidgetDto} from '../dashboard/model/widget-dto';
import {OperatorSettingService} from '../shared/services/operator-setting.service';
import {isGlobalSettingsTrue} from '../player-management/shared/constants/players.functions';

@Component({
	selector: 'he-home',
	styleUrls: [
		'./home.component.css'
	],
	templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
	addLabel = 'Add Widget';
	showWidgetCentre = false;

	// It is important that this is not initialised to [], since this is used to flag when widgets are available in html template
	public widgets: WidgetDto[];

	public customWidgets: CustomWidgetDto[];

	protected isDashboardEnabled = false;

	constructor(private breadcumbService: BreadcrumbService,
				private toastDisplayService: ToastDisplayService,
				private service: DashboardService,
				private changeDetectRef: ChangeDetectorRef,
				private operatorSettingService: OperatorSettingService,
				private boErrorHandlerService: BoErrorHandlerService) {
		this.loadWidgets();
	}

	ngOnInit() {
		this.breadcumbService.setItems([]);

		this.operatorSettingService.getSettings().subscribe({
			next: operatorSetting => {
				this.isDashboardEnabled = isGlobalSettingsTrue('DashboardsEnabled', operatorSetting);
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	public loadWidgets() {
		this.service.getWidgets()
			.subscribe(arg => {
				this.pushWidget(...arg);
			});

		this.service.getCustomWidgets()
			.subscribe(arg => {
				this.customWidgets = arg;
			});
	}

	closeDialog() {
		this.showWidgetCentre = false;
	}

	onAddCustomWidget(data: WidgetData) {
		if (!data) {
			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: 'Error Message',
				description: 'Widget initialising data missing.'
			});

			return;
		}

		this.showWidgetCentre = false;
		this.pushWidget(data);
		// this.changeDetectRef.detectChanges();

		// console.log('onAddWidget: ', this.widgets);
	}

	private pushWidget(...widgets: WidgetDto[]) {
		if (!this.widgets) {
			this.widgets = [];
		} else {
			// Although there is a GridStack.addWidget method, this appears to only support adding text content,
			// Since GridStack.init is important for applying resizing and drag/drop, using a timer to clear the object, which when
			// used in tandem with ngIf* in template, has the effect of re-rendering the component, and thus a fresh
			// call to DashBoardPanelComponent#ngAfterViewChecked which triggers GridStack.init with the updated this.widgets
			const temp = this.widgets;
			this.widgets = undefined;
			const timer = window.setTimeout(() => {
				temp.push(...widgets);
				this.widgets = temp;

				window.clearTimeout(timer);
			}, 200);
		}

		this.widgets.push(...widgets);
	}
}
