import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DataTableLazyLoadEvent, ToastMessageType} from '../../shared';
import {ToastDisplayService} from '../../helio-core-services';
import {WidgetData} from '../model/widget-dto';

@Component({
	selector: 'he-widget-catalogue',
	templateUrl: './widget-catalogue.component.html',
	styleUrls: ['./widget-catalogue.component.scss']
})
export class WidgetCatalogueComponent {

	@Input() title = '...';
	@Input() subTitle = '...';
	@Input() data: WidgetData;
	@Input() imgSrc = '';
	@Input() imgAlt = '';

	@Output() addWidgetEvent: EventEmitter<WidgetData> = new EventEmitter<WidgetData>();

	addLabel = 'Add Widget';

	onAddWidgetClicked() {
		this.addWidgetEvent.emit(this.data);
	}
}
