export abstract class BaseSkeleton {
	viewType: DataViewLayout = 'list';

	emptyArrayOfSize(value: number) {
		return Array(value);
	}

	get isList() {
		return this.viewType === 'list';
	}
}

export type DataViewLayout = 'grid' | 'list';
