<he-base-dialog header="{{'Bank Details'}}" [(visible)]="visible" [isLoadingLookups]="false"
				[style]="{'max-height': '80%', height: '900px', width: '100%'}" (visibleChange)="onCloseDialog()"
				rejectBtnOnly="true" rejectLabel="Close">

	<!--[selectedRows]="selectedCards"-->
	<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data"
					  (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords"
					  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields"
					  [loading]="loading">
		<div class="he-specific-table-actions">
			<button class="helio-orange-btn" pButton type="button" (click)="showCreateDialog = true"
					[label]="'Add Bank Details'" [title]="'Add Bank Details'" icon="pi pi-plus"
					[disabled]="loading"></button>
		</div>
	</he-data-table-v3>

</he-base-dialog>

<he-bank-details-editor-dialog *ngIf="showCreateDialog" [(visible)]="showCreateDialog"
								 style="position: absolute" forCRUD="true" dialogTitle="Add Bank Details"
								 [submitLabel]="'Add'" [playerPartialData]="playerPartialData"
								 (closeEvent)="showCreateDialog = false; selectedRow = null;"
								 (submitEvent)="onCreateRowSubmit($event)"></he-bank-details-editor-dialog>

<he-bank-details-editor-dialog *ngIf="showEditDialog" [(visible)]="showEditDialog"
								 [bankDetails]="selectedRow" [submitLabel]="'Edit'"
								 style="position: absolute" forCRUD="true" dialogTitle="Edit Bank Details"
								 (closeEvent)="showEditDialog = false; selectedRow = null;"
								 (submitEvent)="onEditRowSubmit($event)"></he-bank-details-editor-dialog>
