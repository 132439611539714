import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
	BreadcrumbService, AppConfigService, GameAdministrationService, BoErrorHandlerService,
	AppLoaderService, TenantService, UsersService, GroupsService, NomenclaturesService,
	AuditService, Base64ToBlobConverter, PagePermissionsService, ActionPermissionsService,
	CompanyService, LoginService, WindowEventsService, ToastDisplayService, ConfirmDialogDisplayService, AdvancedSearchService
} from './services';
import { MessageService, ConfirmationService } from 'primeng/api';

@NgModule({
	declarations: [
	],
	imports: [
		CommonModule
	],
	providers: [
		AppConfigService,
		BreadcrumbService,
		GameAdministrationService,
		BoErrorHandlerService,
		AppLoaderService,
		TenantService,
		UsersService,
		GroupsService,
		NomenclaturesService,
		AuditService,
		Base64ToBlobConverter,
		PagePermissionsService,
		ActionPermissionsService,
		CompanyService,
		LoginService,
		WindowEventsService,
		MessageService,
		ToastDisplayService,
		ConfirmationService,
		ConfirmDialogDisplayService,
		AdvancedSearchService
	]
})

export class HelioCoreServicesModule {
	/**
	 * This module is imported once in the App module to be used through out the whole application
	 * This contructor is created to throw an error when this module (and its services) are injected more than once
	 * We are injecting this module into itself.
	 * If Angular injects it correctly, it means that it has been already created therefore throw an error
	 */
	constructor(@Optional() @SkipSelf() core: HelioCoreServicesModule) {
		if (core) {
			throw new Error('Core Services have already been injected!');
		}
	}
}
