import { Component, Input } from '@angular/core';

@Component({
	selector: 'he-header',
	styleUrls: [
		'./header.component.scss'
	],
	templateUrl: './header.component.html'
})
export class HeaderComponent {
	@Input()
	headerTitle: string;
}
