import { Component, OnInit } from '@angular/core';
import { AppLoaderService } from '../../../helio-core-services/services/app-loader.service';

@Component({
	selector: 'he-app-loader',
	styleUrls: [
		'./app-loader.component.scss'
	],
	templateUrl: './app-loader.component.html'
})

export class AppLoaderComponent implements OnInit {
	isLoading = false;
	constructor(private appLoaderService: AppLoaderService) { }

	ngOnInit() {
		this.appLoaderService.loadChange.subscribe(ret => {
			this.isLoading = ret;
		});
	}
}
