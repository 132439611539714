//region QueryParams

export const FILTER_URL_PARAM = '$filter'
export const ORDER_BY_URL_PARAM = '$orderBy'

//endregion

export const DIALOG_CONFIRM_MSG_NORMAL = 'Are you sure that you want to proceed?';

export const DIALOG_CONFIRM_MSG_CRITICAL = 'This action may not be reversible. Are you sure you want to proceed?'

//region RegExp

export const RE_COMMENTS = '^[A-Za-z0-9-_.\\s]+$'; // Alphanumeric, white-space and the symbols: -_.
// export const RE_COMMENTS_2 = '^[A-Za-z0-9-_.\\s]{2,}$'; // two or more valid words
export const RE_ALPHANUMERIC = '^[A-Za-z0-9]+$';
export const RE_ALPHANUMERIC_WS = '^[A-Za-z0-9\\s]+$';
export const RE_ALPHABETIC_WS = '^[A-Za-z\\s]+$';
export const RE_NUMERIC = '^[0-9]+$';

//endregion


export const CONST_LOCAL_STORAGE = Object.freeze({
	ACTIONS: 'action_permissions',
	PAGES: 'page_permissions',
	PAGES_FLATTEN: 'page_permissions_flattened',
	REPORTS: 'report_permissions'
})

/**
 * @deprecated use from {@link PRIME_NG_ICON_TAGS}
 */
export const ADJUST_ICON_TAG = 'pi pi-plus';

export const PRIME_NG_ICON_TAGS = Object.freeze({
	ADJUST: ADJUST_ICON_TAG,
	CREATE: 'pi pi-plus',
	CANCEL: 'ui-icon-cancel',
	ADD_USER: 'pi pi-user-plus',
	UPLOAD: 'pi pi-upload',
	DOWNLOAD: 'pi pi-download'
});

export const LABEL_ADJUST_WALLET = 'Adjust Wallet';

// NB: The same format of 25/12/2022 is represented differently by PrimeNG and LUXON
export const P_CALENDAR_FORMAT = {P_COMPONENT: 'dd/mm/yy', LUXON: 'dd/MM/yy'};
export const DOT_NET_DATE_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'';

export const  HELIO_ADMIN_BE_ID = 3;
