import {HttpParams} from '@angular/common/http';
import {CSV_FORMAT, FILTER_URL_PARAM, FORMAT_URL_PARAM, OFFSET_URL_PARAM, TAKE_URL_PARAM} from '../../constants';

export class ExportCSVUtility {
	/**
	 * @todo THIS DOES NOT CURRENTLY WORK with Date, since they require quotation with BE
	 * Build the HttpParams for CSV Exports
	 * @param currentHttpParams Current Http Params being used in requests
	 * @param selectedData List of the selected data from the list being viewed
	 * @param isAllDataSelected Boolean signifying whether the whole list is selected or not - useful when list is lazy loaded
	 * @param filterPropertyName Property name to be used in the filter when not all data is selected
	 * @param additionalFilters Any additional filter to be added
	 */
	public static getHttpParams(
		currentHttpParams: HttpParams = new HttpParams(),
		selectedData: any[] = [],
		isAllDataSelected: boolean = true,
		filterPropertyName?: string,
		...additionalFilters: string[]): HttpParams {

		if (filterPropertyName && filterPropertyName.toLowerCase().includes('time') || filterPropertyName.toLowerCase().includes('date')) {
			console.warn('getHttpParams: $filter does NOT currently support date or time filters, use object ID instead.')
		}

		if (selectedData.length > 0 && (filterPropertyName.length < 2)){

			throw new Error('Cannot set filter without specifying both filterPropertyName');
		}

		if (!selectedData) {
			selectedData = [];
		}

		// TODO: Watch-out for potential bug.
		//  This code is removed sinced it causes a table with only a single row, and that row selected to
		//  to flag as all selected. This functionality was originally intended for ExportAllData in DB,
		//  which is no longer supported.
		// selectedData = isAllDataSelected ? [] : selectedData;

		currentHttpParams = currentHttpParams.set(OFFSET_URL_PARAM, '0');

		const filterFragments: string[] = [];
		if (currentHttpParams.has(FILTER_URL_PARAM)) {
			filterFragments.push(currentHttpParams.get(FILTER_URL_PARAM));
		}

		if (additionalFilters.length > 0) {
			const filters = additionalFilters.map(temp => `(${temp})`).join(' AND ');
			filterFragments.push(filters);
		}

		if (selectedData.length === 0) {
			currentHttpParams = currentHttpParams.set(TAKE_URL_PARAM, '1000000');
		} else {
			currentHttpParams = currentHttpParams.set(TAKE_URL_PARAM, selectedData.length.toString());

			const apiPropName = filterPropertyName.charAt(0).toUpperCase() + filterPropertyName.slice(1);

			const idFilterFragments: string = selectedData
				.map(tempData => `${apiPropName} eq ${tempData[filterPropertyName].toString()}`)
				.join(' OR ');

			filterFragments.push(`(${idFilterFragments})`);
		}

		if (filterFragments.length > 0) {
			currentHttpParams = currentHttpParams.set(FILTER_URL_PARAM, `${filterFragments.join(' AND ')}`);
		}

		currentHttpParams = currentHttpParams.set(FORMAT_URL_PARAM, CSV_FORMAT);

		return currentHttpParams;
	}
}
