import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {BoErrorHandlerService} from '../../../../helio-core-services';
import {LookupService} from '../../../../shared/services/lookup.service';
import {CountryGroupLookup} from '../../../../shared/interfaces/lookup-interfaces';
import {
	LABEL_ADJUST_WALLET,
	RE_ALPHABETIC_WS,
	RE_ALPHANUMERIC,
	RE_ALPHANUMERIC_WS,
	RE_NUMERIC
} from '../../../../shared/constants/constants';
import {AppGuard, ServiceAction, ServiceController} from '../../../../shared';
import {TransferDetails} from '../../../models/finance/transfer-information.model';

@Component({
	selector: 'he-transfer-details-editor-dialog',
	templateUrl: './transfer-details-editor-dialog.component.html',
	styleUrls: ['./transfer-details-editor-dialog.component.scss']
})
export class TransferDetailsEditorDialogComponent implements OnInit {

	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() forCRUD = false;

	@Input() dialogTitle: 'Transfer Details' | 'Add ExchangeHouse' | 'Edit ExchangeHouse' = 'Transfer Details';

	@Input() editable = true;

	@Input() bankDetails: TransferDetails;
	@Input() tenantID?: number;

	@Output() closeEvent = new EventEmitter<void>();
	@Output() submitEvent = new EventEmitter<TransferDetails>();

	form: FormGroup;

	allCountries: CountryGroupLookup[] = [];
	unsanctionedCountries: CountryGroupLookup[] = [];

	isLoadingLookups = false;

	isViewMode = false;

	submitLabel = LABEL_ADJUST_WALLET;

	canUpdateBankDetails = false;

	constructor(
		private appGuard: AppGuard,
		private formBuilder: FormBuilder,
		private lookupService: LookupService,
		private boErrorHandlerService: BoErrorHandlerService) {
	}

	ngOnInit() {
		this.canUpdateBankDetails = this.appGuard.hasActionPermission(ServiceController.WITHDRAWALS, ServiceAction.UPDATE_BANK_DETAILS);

		this.formInit();
		this.setFormData();

		if (this.forCRUD) {
			this.setState(true); // so that all editable inputs can take user input
		}
	}

	onSubmit() {
		const rawValue = this.form.getRawValue();

		const request: TransferDetails = {
			paymentOrderID: this.bankDetails?.paymentOrderID,
			playerPaymentAccountID: this.bankDetails?.playerPaymentAccountID,

			beneficiaryName: rawValue['accHolderName'],
			nationalIDNumber: rawValue['nationalIdNum'],

			bankName: rawValue['bankName'],
			bankAccountNumber: rawValue['bankAccNumber'],
			iban: rawValue['iban'],
			swiftCode: rawValue['swiftCode'],
			routingNumber: rawValue['routingNum'],
			ifsc: rawValue['ifsc'],
			bankCity: rawValue['bankCity'],
			bankState: rawValue['bankState'],
			bankBranchCountryID: rawValue['bankBranchCountry'],
			bankBranch: rawValue['bankBranch']
		}

		this.submitEvent.emit(request);
	}

	onCloseDialog() {
		// this.visible = false;
		this.closeEvent.emit();
	}

	private formInit() {
		this.form = this.formBuilder.group({
			accHolderName: new FormControl(this.bankDetails ? this.bankDetails.beneficiaryName : '', {
				validators: [Validators.required, Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			nationalIdNum: new FormControl(this.bankDetails ? this.bankDetails.nationalIDNumber : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			/*,
			placeOfBirth: new FormControl(this.bankDetails ? this.bankDetails.placeOfBirth : '', {
				validators: [Validators.required, Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			countryOfBirth: new FormControl('', {
				validators: [Validators.required]
			}),*/
			bankName: new FormControl(this.bankDetails ? this.bankDetails.bankName : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC_WS)]
			}),
			bankBranch: new FormControl(this.bankDetails ? this.bankDetails.bankBranch : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC_WS)]
			}),
			bankCity: new FormControl(this.bankDetails ? this.bankDetails.bankCity : '', {
				validators: [Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			bankState: new FormControl(this.bankDetails ? this.bankDetails.bankState : '', {
				validators: [Validators.pattern(RE_ALPHABETIC_WS)]
			}),
			bankBranchCountry: new FormControl(undefined),
			iban: new FormControl(this.bankDetails ? this.bankDetails.iban : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			swiftCode: new FormControl(this.bankDetails ? this.bankDetails.swiftCode : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			ifsc: new FormControl(this.bankDetails ? this.bankDetails.ifsc : '', {
				validators: [Validators.pattern(RE_ALPHANUMERIC)]
			}),
			routingNum: new FormControl(this.bankDetails ? this.bankDetails.routingNumber : '', [
				// Validators.pattern('^[0-9]{9,9}$') TODO routingNum is always a 9 digit value,
				//                                      before applying make sure BE uses the same val
				Validators.pattern(RE_NUMERIC)
			]),
			bankAccNumber: new FormControl(this.bankDetails ? this.bankDetails.bankAccountNumber : '', [
				Validators.pattern(RE_NUMERIC),
				Validators.min(0)
			])
		});

		this.setState(false);
	}

	setState(enabled: boolean) {
		for (const entry in this.form.controls) {
			if (entry && enabled) {
				this.form.controls[entry].enable();
			} else if (entry && !enabled) {
				this.form.controls[entry].disable();
			}
		}

		this.form.updateValueAndValidity();
	}

	onModeToggle($event: any) {
		this.setState($event.checked);
	}

	private setFormData() {
		this.isLoadingLookups = true;

		const observables: any = {
			getPlayerNomenclature: this.lookupService.getPlayerNomenclature()
		};

		forkJoin(observables).subscribe({
			next: (res: any) => {
				this.allCountries = res.getPlayerNomenclature.result.countries;
				this.unsanctionedCountries = res.getPlayerNomenclature.result.unblockedCountries;

				if (this.bankDetails) {
					// this.form.get('countryOfBirth').setValue(this.bankDetails.countryOfBirthId);
					this.form.get('bankBranchCountry').setValue(this.bankDetails.bankBranchCountryID);
				}

				this.isLoadingLookups = false;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}
}
