import { Component } from '@angular/core';

@Component({
	selector: 'he-no-content',
	template: `
		<div>
			<h1>404: page missing</h1>
		</div>
	`
})
export class NoContentComponent { }
