import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
	selector: '[heShowHidePassword]'
})

export class ShowHidePasswordDirective implements OnInit {
	private isTypePassword = true;

	constructor(private elementRef: ElementRef) { }

	ngOnInit() {
		this.changeType(true);
	}

	/**
	 * Toogle type of text box (text or password).
	 * If isTypePassword is not specified the value is toggled, else value is set to the passed value
	 * @param isTypePassword Boolean to specify whether the text box should be set to type "password" or "text"
	 */
	changeType(isTypePassword?: boolean): void {
		if (isTypePassword === undefined) {
			this.isTypePassword = !this.isTypePassword;
		} else {
			this.isTypePassword = isTypePassword;
		}

		this.elementRef.nativeElement.type = (this.isTypePassword) ? 'password' : 'text';
	}
}
