import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {CommonTable} from '../../../interfaces/common-table';
import {ExchangeHouse, ExchangeHouseRequest} from '../../../models/finance/transfer-information.model';
import {AppConfigService, BoErrorHandlerService, ConfirmDialogDisplayService, ToastDisplayService} from '../../../../helio-core-services';
import {ActivatedRoute} from '@angular/router';
import {BankCardService} from '../../../../player-management/shared/services/bank-card.service';
import * as ColumnNames from '../../../constants/ui-db-name-mappings';
import {ColumnType, DataTableActionCallback, DataTableLazyLoadEvent} from '../../data-table-v3';
import {DIALOG_CONFIRM_MSG_NORMAL} from '../../../constants/constants';
import {HttpParams} from '@angular/common/http';
import {ToastMessageType} from '../../../models';
import {Confirmation} from 'primeng/api';
import {Player} from '../../../models/player/players.model';

@Component({
	selector: 'he-exchange-house-details-dialog',
	templateUrl: './exchange-house-details-dialog.component.html',
	styleUrls: ['./exchange-house-details-dialog.component.scss']
})
export class ExchangeHouseDetailsDialogComponent extends CommonTable<ExchangeHouse> implements OnInit, OnDestroy {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() playerPartialData: Player;

	@Output() closeEvent = new EventEmitter<void>();

	getTableDataSub$: Subscription
	createExchangeHouseSub$: Subscription
	updateExchangeHouseSub$: Subscription
	deleteExchangeHouseSub$: Subscription

	routePlayerID: number;

	showCreateExchangeHouseDialog = false;
	showEditExchangeHouseDialog = false;

	selectedRow: ExchangeHouse;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private bankCardService: BankCardService,
				private confirmationDisplayService: ConfirmDialogDisplayService,
				private toastDisplayService: ToastDisplayService,
				private renderer: Renderer2,
				private elmRef: ElementRef,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.initDataTable();

		this.route.params.subscribe(params => {
			this.routePlayerID = params.id;

			if (this.params) {
				this.getTableData(undefined);
			}
		});

		this.getTableData();
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getTableDataSub$, this.createExchangeHouseSub$,
			this.updateExchangeHouseSub$, this.deleteExchangeHouseSub$);
	}

	initDataTable() {
		this.dataKey = ColumnNames.EXCHANGE_HOUSE_ID.DB;

		this.cols = [
			{
				field: ColumnNames.EXCHANGE_HOUSE_ID.DB,
				header: ColumnNames.EXCHANGE_HOUSE_ID.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_BENEFICIARY.DB,
				header: ColumnNames.EXCHANGE_HOUSE_BENEFICIARY.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_BANK_NAME.DB,
				header: ColumnNames.EXCHANGE_HOUSE_BANK_NAME.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_BANK_CITY.DB,
				header: ColumnNames.EXCHANGE_HOUSE_BANK_CITY.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_BANK_STATE.DB,
				header: ColumnNames.EXCHANGE_HOUSE_BANK_STATE.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_COUNTRY.DB,
				header: ColumnNames.EXCHANGE_HOUSE_COUNTRY.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_NAT_ID_NUMBER.DB,
				header: ColumnNames.EXCHANGE_HOUSE_NAT_ID_NUMBER.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_WITHDRAWAL_METHOD.DB,
				header: ColumnNames.EXCHANGE_HOUSE_WITHDRAWAL_METHOD.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_ACCOUNT_REF.DB,
				header: ColumnNames.EXCHANGE_HOUSE_ACCOUNT_REF.UI
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_DATE_CREATED.DB,
				header: ColumnNames.EXCHANGE_HOUSE_DATE_CREATED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_DATE_LAST_USED.DB,
				header: ColumnNames.EXCHANGE_HOUSE_DATE_LAST_USED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_DATE_LAST_UPDATED.DB,
				header: ColumnNames.EXCHANGE_HOUSE_DATE_LAST_UPDATED.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.EXCHANGE_HOUSE_IS_DEFAULT.DB,
				header: ColumnNames.EXCHANGE_HOUSE_IS_DEFAULT.UI,
				columnType: ColumnType.TrueFalse
			}
		];

		this.tableActions = [
			{
				menuItem: {
					label: 'Edit',
					icon: 'pi pi-pencil'
				},
				callback: (callbackObj: DataTableActionCallback) => {
					this.selectedRow = callbackObj.data[0];
					this.showEditExchangeHouseDialog = true;
				}
			},
			{
				menuItem: {
					label: 'Delete',
					icon: 'ui-icon-delete'
				},
				callback: (callbackObj) => {
					const selectedRow: ExchangeHouse = callbackObj.data[0];

					const confMessage: Confirmation = {
						message: `${DIALOG_CONFIRM_MSG_NORMAL} This will delete Exchange House with ID ${selectedRow.playerPaymentAccountID}.`,
						accept: () => {
							this.onDeleteExchangeHouse(selectedRow.playerPaymentAccountID);
						},
						reject: () => {
							// Do nothing
						}
					};

					this.confirmationDisplayService.showConfirmDialog(confMessage);
				}
			}
		];

		this.searchFields = []

		this.fetchLookups();
	}

	fetchLookups() {
	}

	getTableData(event?: DataTableLazyLoadEvent, forceSearchParam?: HttpParams) {
		super.getTableData(event, forceSearchParam);

		this.params = this.computeTableDataHttpParams(event);
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getTableDataHelper();
	}

	private getTableDataHelper() {
		this.getTableDataSub$ = this.bankCardService.getExchangeHouses(this.routePlayerID, this.params).subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}
				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}

	onCreateExchangeHouseSubmit(request: ExchangeHouseRequest) {
		request.playerID = this.routePlayerID;

		this.beforeTableDataServiceCall(this.createExchangeHouseSub$);

		this.createExchangeHouseSub$ = this.bankCardService.addExchangeHouse(request).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'Exchange house added.'
				});

				this.getTableData();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}

	onEditExchangeHouseSubmit(request: ExchangeHouseRequest) {
		request.playerPaymentAccountID = this.selectedRow.playerPaymentAccountID;

		this.beforeTableDataServiceCall(this.updateExchangeHouseSub$);

		this.updateExchangeHouseSub$ = this.bankCardService.updateExchangeHouse(request).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: 'Exchange house updated.'
				});

				this.getTableData();
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData();
			}
		});
	}

	onDeleteExchangeHouse(playerPaymentAccountID: number) {
		this.loading = true;
		this.releaseSubscriptions(this.deleteExchangeHouseSub$);

		this.deleteExchangeHouseSub$ = this.bankCardService.deleteExchangeHouse(playerPaymentAccountID).subscribe({
			next: () => {
				this.toastDisplayService.addMessage({
					type: ToastMessageType.success,
					title: 'Success',
					description: `Exchange House deleted.`
				});
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			},
			complete: () => {
				this.getTableData();
			}
		});
	}
}
