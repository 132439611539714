import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'he-app-title',
	styleUrls: [
		'./app-title.component.scss'
	],
	templateUrl: './app-title.component.html'
})

export class AppTitleComponent {

	constructor(private router: Router) {
	}

	onLogoClicked() {
		this.router.navigate(['/']);
	}
}
