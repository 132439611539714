export class Base64ToBlobConverter {

	// Convert base64 to Blob
	public static base64ToBlob(base64: any, fileType: string) {
		const byteCharacters = atob(base64);
		const byteNumbers = [byteCharacters.length];

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: fileType });

		return blob;
	}
}
