import {NgModule, APP_INITIALIZER, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

/*
 * Platform and Environment providers/directives/pipes
 */
import {AppRoutingModule} from './app-routing.module';
// App is our top level component
import {AppComponent} from './app.component';
import {HomeComponent} from './home';

import {NoContentComponent} from './no-content';

import {NavigationComponent, ShowNavItemDirective, AppSubMenuComponent} from './navigation';
import {LoginComponent} from './login';
import {KitchenSinkComponent} from './kitchen-sink';

import {DialogModule} from 'primeng/dialog';
import {MenuModule} from 'primeng/menu';
import {ToastModule} from 'primeng/toast';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';

import {
	AppTitleComponent, AppGuard, RightsDirective, SharedModule, AppLoaderComponent, LoginGuard
} from './shared';

import {AppTopBarComponent} from './topbar';
import {AppBreadcrumbComponent} from './breadcrumbs';

import {PublicContentComponent, SecureContentComponent} from './layout';
import {AppLayoutService} from './layout/secure/app-layout.service';
import {HelioCoreServicesModule, AppConfigService} from './helio-core-services';
import {NoAccessRightsComponent} from './no-access-rights';
import {appInitLoader} from './app-init.loader';
import {ChangePasswordModule} from './change-password';
import {ComponentHostModule} from './component-host/component-host.module';
import {MyProfileComponent} from './my-profile/my-profile.component';
import {AppLangService} from './app-lang.service';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DashBoardPanelComponent} from './dashboard/panel/dash-board-panel.component';
import {DashboardWidgetComponent} from './dashboard/widget/dashboard-widget.component';
import {RippleModule} from 'primeng/ripple';
import {WidgetCatalogueComponent} from './dashboard/widget-catalogue/widget-catalogue.component';
import {DropdownModule} from 'primeng/dropdown';
import {SkeletonModule} from 'primeng/skeleton';
import {provideServiceWorker, ServiceWorkerModule, SwUpdate} from '@angular/service-worker';
import {PlayersService} from './shared/services/players.service';

// Import the utility file for registering locales to register them
import './register-locales';

@NgModule({
	bootstrap: [AppComponent],
	declarations: [
		AppComponent,
		HomeComponent,
		MyProfileComponent,
		NoContentComponent,
		NoAccessRightsComponent,
		NavigationComponent,
		ShowNavItemDirective,
		LoginComponent,
		AppTitleComponent,
		KitchenSinkComponent,
		RightsDirective,

		AppSubMenuComponent,
		AppTopBarComponent,
		AppBreadcrumbComponent,
		PublicContentComponent,
		SecureContentComponent,

		AppLoaderComponent,
		DashBoardPanelComponent,
		DashboardWidgetComponent,
		WidgetCatalogueComponent
	],
	imports: [ // import Angular's modules
		BrowserModule,
		FormsModule,
		ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'always'}),
		HttpClientModule,
		BrowserAnimationsModule,
		HelioCoreServicesModule,
		ChangePasswordModule,
		ComponentHostModule,

		ButtonModule,
		DialogModule,
		ToastModule,
		InputTextModule,
		MenuModule,
		ScrollPanelModule,
		ProgressSpinnerModule,
		AppRoutingModule,
		BreadcrumbModule,
		SharedModule,
		ConfirmDialogModule,
		RippleModule,
		DropdownModule,
		SkeletonModule,
		ServiceWorkerModule.register('/ngsw-worker.js', {
			enabled: false, // !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [ // expose our Services and Providers into Angular's dependency injection
		AppConfigService,
		AppLangService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitLoader,
			deps: [AppLangService, AppConfigService],
			multi: true
		},
		AppGuard,
		AppLayoutService,
		PlayersService,
		LoginGuard,
		// { provide: LOCALE_ID, useValue: 'da-DK' } // Set the locale to Danish
	]
})
export class AppModule {
}
