<ng-container *ngIf="isModal; else notModalTemplate">
	<p-dialog header="{{headerMessage}}" [visible]="true" modal="modal" [style]="{ width: '400px' }"
			  [responsive]="true" [draggable]="false" [resizable]="false" [closeOnEscape]="false" [closable]="false">
		<ng-container *ngTemplateOutlet="formContent"></ng-container>
		<p-footer>
			<ng-container *ngTemplateOutlet="formButtonsContent"></ng-container>
		</p-footer>
	</p-dialog>

	<!-- Reset Password INFO dialog -->
	<p-dialog header="Alert" [(visible)]="displayInfo" showEffect="fade" [modal]="true" [style]="{ width: '500px' }">
		{{ information }}
		<p-footer>
			<div>
				<button type="button" pButton icon="ui-icon-check" (click)="closeInfoAlert()" label="Okay"></button>
			</div>
		</p-footer>
	</p-dialog>
</ng-container>

<ng-template #notModalTemplate>
	<div class="form-wrapper">
		<p>Change password:</p>
		<ng-container *ngTemplateOutlet="formContent"></ng-container>
		<ng-container *ngTemplateOutlet="formButtonsContent"></ng-container>
	</div>
</ng-template>

<ng-template #formContent>
	<form [formGroup]="changePasswordForm">
		<div id="password-container">
			<div class="he-float-label-wrapper">
				<span class="p-float-label">
					<input heTogglePasswordVisibility pInputText type="password" formControlName="oldPassword"
						   autocomplete="new-password">
					<label>Old Password</label>
				</span>
			</div>

			<div class="he-float-label-wrapper">
				<span class="p-float-label">
					<input heTogglePasswordVisibility pInputText type="password" formControlName="newPassword"
						   autocomplete="off">
					<label>New Password</label>
				</span>

				<div class="alert" *ngIf="!changePasswordForm.valid && !changePasswordForm.pristine">
					{{ errors }}
				</div>
			</div>

			<div class="he-float-label-wrapper">
				<span class="p-float-label">
					<input heTogglePasswordVisibility pInputText type="password" formControlName="confirmNewPassword"
						   autocomplete="off">
					<label>Confirm New Password</label>
				</span>

				<div class="alert" *ngIf="!changePasswordForm.valid && !changePasswordForm.pristine">
					{{ confirmPasswordError }}
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #formButtonsContent>
	<div class="submit-btn">
		<button type="button" pButton icon="ui-icon-check" [disabled]="!changePasswordForm.valid" label="Save"
				name="submitButton" title="Save Password" (click)="saveNewPassword()"></button>
	</div>
</ng-template>
