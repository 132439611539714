import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {AppGuard} from '../shared';

@Directive({
	selector: '[heShowNavItem]'
})

export class ShowNavItemDirective {
	constructor(
		private appGuard: AppGuard,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) { }

	@Input() set heShowNavItem(url: string) {
		if (this.appGuard.hasPageRights(url)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
