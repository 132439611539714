import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/models/general/app-config.model';

@Injectable()
export class AppConfigService {
	private config: AppConfig;

	constructor(private http: HttpClient) { }

	public load(url: string): Promise<void> {
		return new Promise((resolve) => {
			this.http.get(url)
				.subscribe(config => {
					this.setAppConfigObj(config);

					localStorage.setItem('helioEngineAppConfig', JSON.stringify(this.config));
					resolve(); // Since, no value was provided in previous impl, {} is used to satisfy, latest, typescript version
				});
		});
	}

	public get serviceBaseURL() {
		return (this.isCentralServer) ? this.appConfig.centralBaseURL : this.appConfig.tenantBaseURL;
	}

	public get serviceCentralBaseURL() {
		return this.appConfig.centralBaseURL;
	}

	public get authBaseURL() {
		return (this.isCentralServer) ? this.appConfig.centralAuthURL : this.appConfig.tenantAuthURL;
	}

	public get walletBaseURL() {
		return this.appConfig.walletBaseURL;
	}

	public get isCentralServer(): boolean {
		return this.config.isCentralServer;
	}

	public get hasWallet(): boolean {
		return this.config.hasWallet;
	}

	public get useSubdomain(): boolean {
		return this.config.useSubdomain;
	}

	public get genericErrorMessage(): string {
		return this.config.genericErrorMessage;
	}

	public get tableEmptyMessage_loading(): string {
		return this.config.tableEmptyMessage;
	}

	public get tableMissingDataMessage(): string {
		return this.config.tableMissingDataMessage;
	}

	public get defaultDateFormat(): string {
		return this.config.defaultDateFormat;
	}

	public get defaultTimezoneOffset(): string {
		return this.config.defaultTimezoneOffset;
	}

	public get defaultNumberFormat(): string {
		return this.config.defaultNumberFormat;
	}

	public get defaultMoneyFormat(): string {
		return this.config.defaultMoneyFormat;
	}

	public get tokenClientID(): string {
		return this.config.tokenClientID;
	}

	public get inactiveTimeLimit(): number {
		return this.config.inactiveTimeLimit;
	}

	private get appConfig(): AppConfig {
		return this.config;
	}

	private setAppConfigObj(config: any): void {
		this.config = new AppConfig(
			config.isCentralServer,
			config.hasWallet,
			config.useSubdomain,
			config.centralBaseURL,
			config.centralAuthURL,
			config.tenantBaseURL,
			config.tenantAuthURL,
			config.walletBaseURL,
			config.genericErrorMessage,
			config.tableEmptyMessage,
			config.tableMissingDataMessage,
			config.defaultDateFormat,
			config.defaultTimezoneOffset,
			config.defaultNumberFormat,
			config.defaultMoneyFormat,
			config.tokenClientID,
			config.inactiveTimeLimit
		);
	}
}
