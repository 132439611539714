import {PlayerRegistrationTypesLookup} from '../interfaces/lookup-interfaces';

export class RetailAgentRequest {
	retailAgentTypeID?: number;
	retailAgentID?: number; // Required for EDIT mode
	tenantID: number;
	tenantBrandID: number;
	currencyID?: number;
	fullName: string;
	username: string;
	password: string;
	email: string;
	address: string;
	isActive: boolean;
	requirePasswordChange: boolean;
	playerRegistrationTypeIDs: number;
}

export class RetailAgent extends RetailAgentRequest {
	balances: RetailAgentBalance[];

	currencyCode: string;
	playerID?: number;
	tenantName: string;
	balance: number;

	retailAgentType?: string;

	// Newest fields
	// operator: string;
	brand: string;
	tenantBrandDisplayName: string;

	isDeleted: boolean;
	defaultBalance: any; // Todo change once type known
	lastReconciledDate: string;
	playerTypes: PlayerRegistrationTypesLookup[];
}


export interface RetailAgentBalance {
	currencyCode: string;
	currencyID: number;
	balance: number;
	reconciledUpTo: string;
	isActive: boolean,
	reconciledUpToBalance: number,
	unreconciledTicketsSold: number,
	unreconciledTicketsPaid: number,
	unreconciledTicketsCancelled: number,
	defaultBalance?: number
}
