import {BaseLookup} from '../../../shared/interfaces/lookup-interfaces';
import {OperatorSettingDTO, OpSetting, OpTenantSetting} from '../../../shared/models/operator/operator-setting';

/**
 * @deprecated use pattern from {@link LookupService#getTransactionTypes}
 * Return a validated list of lookup, to prevent adding null or undefined lookup items.
 * @param rawLookup is an object representing the data from API, e.g., {@link TransactionTypesLookup}.
 */
export function parseValidLookups(rawLookup: BaseLookup[]): BaseLookup[] {
	if (!Array.isArray(rawLookup)) {
		return [];
	}

	const tempLookup: BaseLookup[] = [];

	for (const obj of rawLookup) {
		// Validate, to present adding null or undefined lookup
		// TODO - Impl this app-wide via a helper method
		if (obj.label && obj.value) {
			tempLookup.push({label: obj.label, value: obj.value});
		}
	}

	return tempLookup;
}

export function extractTenantSettings(operatorSetting: OperatorSettingDTO, tenantID: number): OpTenantSetting {
	return operatorSetting.tenants.find(entry => {
		return entry.tenantID === tenantID;
	});
}

export function isTenantSettingsTrue(
	attr: 'FreeTicketEnabled' | 'FreeTicketBatchesEnabled',
	operatorSetting: OperatorSettingDTO, tenantID: number
): boolean {
	const applicableTenant: OpTenantSetting = extractTenantSettings(operatorSetting, tenantID);

	const setting = applicableTenant.settings
		.find(entry => {
		return entry.name === attr;
	});

	return setting?.value?.toLowerCase() === 'true';
}

export function isGlobalSettingsTrue(
	attr: 'FreeTicketEnabled' | 'FreeTicketBatchesEnabled' | 'DashboardsEnabled', operatorSetting: OperatorSettingDTO
): boolean {
	const globalSetting: OpSetting[] = operatorSetting.globalSettings;

	const applicableSetting = globalSetting.find(entry => {
			return entry.name === attr;
		});

	return applicableSetting?.value?.toLowerCase() === 'true';
}
