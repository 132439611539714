<he-base-dialog [header]="dialogTitle" [(visible)]="visible" [isLoadingLookups]="isLoadingLookupsOrModel"
				[style]="{'max-width': '550px', width: '550px'}" [submitLabel]="submitLabel"
				(visibleChange)="closeDialog()" (submitEvent)="onDialogSubmit()" [confirmDialogMsg]="confirmDialogMsg"
				[disableSubmit]="!form.valid  || isDisabled">
	<form [formGroup]="form" class="dialog-form-placement">
		<label>Status</label>
		<div>
			<p-dropdown [options]="options.lookupList" formControlName="status" appendTo="body"
						placeholder="Please select a status"></p-dropdown>

			<he-form-messages [errors]="form.controls.status.errors"
							  [reaction]="form.controls.status.dirty"></he-form-messages>
		</div>

		<label for="comment">Comment</label>
		<textarea pInputTextarea id="comment" formControlName="comment" type="text" rows="5"></textarea>
	</form>
</he-base-dialog>
