import { Routes } from '@angular/router';

import { LoginComponent } from '../../login';
import { KitchenSinkComponent } from '../../kitchen-sink';
import { NoAccessRightsComponent } from '../../no-access-rights';

export const PUBLIC_ROUTES: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		// canActivate: [LoginGuard]
	},
	{
		path: 'kitchen-sink',
		component: KitchenSinkComponent
	},
	{
		path: 'no-access-rights',
		component: NoAccessRightsComponent
	}
];
