import {Component, HostBinding, NgZone, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AppConfigService, ToastDisplayService} from './helio-core-services';
import {LookupService} from './shared/services/lookup.service';
import {PromptUpdateService} from './shared/services/prompt-update.service';

export interface WindowResizeListener {
	onWindowResize(width: number, height: number): void;
}

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'he-app',
	styleUrls: [
		'./app.component.scss'
	],
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

	private static windowWidthLis: WindowResizeListener | undefined = undefined;
	private static windowWidth = 0;
	private static windowHeight = 0;
	TAG = AppComponent.name;

	rippleInitListener: any;
	rippleMouseDownListener: any;
	toastPosition: string;

	@HostBinding('attr.app-version') appVersion = VERSION;

	constructor(
		private appConfigService: AppConfigService,
		private lookupService: LookupService,
		private promptUpdateService: PromptUpdateService,
		public zone: NgZone,
		public toastDisplayService: ToastDisplayService
	) {
	}

	public static registerWindowWidthLis(l: WindowResizeListener) {
		AppComponent.windowWidthLis = l;
		AppComponent.handleWidthListener();
	}

	private static handleWidthListener() {
		if (AppComponent.windowWidthLis) {
			AppComponent.windowWidthLis.onWindowResize(AppComponent.windowWidth, AppComponent.windowHeight);
		}
	}

	ngOnInit() {
		if (false) {
			this.promptUpdateService.registerForSwUpdates();
		}

		this.computeWindowWidth();

		if (this.appConfigService.useSubdomain) {
			this.getAndSaveSubdomain();
		}

		this.zone.runOutsideAngular(() => {
			this.bindRipple();
		});

		this.toastDisplayService.toastPosition.subscribe(pos => {
			this.toastPosition = pos;
		});
	}

	getAndSaveSubdomain() {
		const getSubdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
		const fullURL: string = window.location.host;
		const result: RegExpMatchArray = fullURL.match(getSubdomainRegex);
		let subdomain: string;

		if (result !== null) {
			subdomain = result[1];
			if (subdomain !== 'www') {
				sessionStorage.setItem('subdomain', subdomain);
			}
		}
	}

	bindRipple() {
		this.rippleInitListener = this.init.bind(this);
		// document.addEventListener('DOMContentLoaded', this.rippleInitListener);
		this.rippleInitListener();
	}

	init() {
		this.rippleMouseDownListener = this.rippleMouseDown.bind(this);
		document.addEventListener('mousedown', this.rippleMouseDownListener, false);
	}

	rippleMouseDown(e) {
		// tslint:disable-next-line:no-string-literal
		for (let target = e.target; target && target !== this; target = target['parentNode']) {
			if (!this.isVisible(target)) {
				continue;
			}

			// Element.matches() -> https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
			if (this.selectorMatches(target, '.ripplelink, .ui-button')) {
				this.rippleEffect(target, e); // target is the element
				break;
			}
		}
	}

	selectorMatches(el, selector) {
		const p = Element.prototype;
		// tslint:disable-next-line:no-string-literal
		const f = p['matches'] || p['webkitMatchesSelector'] || p['mozMatchesSelector'] || p['msMatchesSelector'] || function (s) {
			return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
		};
		return f.call(el, selector);
	}

	isVisible(el) {
		return !!(el.offsetWidth || el.offsetHeight);
	}

	rippleEffect(element, e) {
		if (element.querySelector('.ink') === null) {
			const inkEl = document.createElement('span');
			this.addClass(inkEl, 'ink');

			if (this.hasClass(element, 'ripplelink') && element.querySelector('span')) {
				element.querySelector('span').insertAdjacentHTML('afterend', '<span class=\'ink\'></span>');
			} else {
				element.appendChild(inkEl);
			}
		}

		const ink = element.querySelector('.ink');
		this.removeClass(ink, 'ripple-animate');

		if (!ink.offsetHeight && !ink.offsetWidth) {
			const d = Math.max(element.offsetWidth, element.offsetHeight);
			ink.style.height = d + 'px';
			ink.style.width = d + 'px';
		}

		const x = e.pageX - this.getOffset(element).left - (ink.offsetWidth / 2);
		const y = e.pageY - this.getOffset(element).top - (ink.offsetHeight / 2);

		ink.style.top = y + 'px';
		ink.style.left = x + 'px';
		ink.style.pointerEvents = 'none';
		this.addClass(ink, 'ripple-animate');
	}

	hasClass(element, className) {
		if (element.classList) {
			return element.classList.contains(className);
		} else {
			return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
		}
	}

	addClass(element, className) {
		if (element.classList) {
			element.classList.add(className);
		} else {
			element.className += ' ' + className;
		}
	}

	removeClass(element, className) {
		if (element.classList) {
			element.classList.remove(className);
		} else {
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}

	getOffset(el) {
		const rect = el.getBoundingClientRect();

		return {
			top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
			left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0)
		};
	}

	unbindRipple() {
		if (this.rippleInitListener) {
			document.removeEventListener('DOMContentLoaded', this.rippleInitListener);
		}
		if (this.rippleMouseDownListener) {
			document.removeEventListener('mousedown', this.rippleMouseDownListener);
		}
	}

	ngOnDestroy() {
		this.unbindRipple();
	}

	private computeWindowWidth() {
		// -  Determine initial value
		this.computeWindowWidthHandler();

		// Re-acquire height on window resize
		window.onresize = () => this.computeWindowWidthHandler();
	}

	private computeWindowWidthHandler() {
		const element = window.document.querySelector('html');
		AppComponent.windowWidth = (element == null) ? 0 : element.offsetWidth;
		AppComponent.windowHeight = (element == null) ? 0 : element.offsetHeight;
		AppComponent.handleWidthListener();
	}
}
