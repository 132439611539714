import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { AppConfigService } from './app-config.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { DataTableServerResponse } from '../../shared/components/data-table-v3/shared/data-table-server-response.model';

@Injectable()
export class ActionPermissionsService extends BaseService {
	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.ACTION_PERMISSIONS, appConfigService.serviceBaseURL);
	}

	getActions(): Observable<DataTableServerResponse> {
		return this.get();
	}
}
