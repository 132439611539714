<ng-container *ngIf="loading; else showDropdown">
	<p-skeleton [style]="{'margin-top': '1em', 'margin-bottom': '1em'}" [width]="skeletonWidth" height="34px"></p-skeleton>
</ng-container>

<ng-template #showDropdown>
	<div id="dropdown-wrapper">
		<p-dropdown [(ngModel)]="selectedItem" [options]="options" optionLabel="label" [filter]="true"
					filterBy="label"
					placeholder="Select a Country" (onChange)="onOperatorChange($event)"></p-dropdown>
	</div>
</ng-template>


