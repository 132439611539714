<he-base-dialog header="{{'Cards Management'}}" [(visible)]="visible" [isLoadingLookups]="false"
				[style]="{'max-height': '80%', height: '900px', width: '100%'}" (visibleChange)="onCloseDialog()"
				rejectBtnOnly="true" rejectLabel="Close">

	<!--[selectedRows]="selectedCards"-->
	<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data"
					  (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords"
					  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields"
					  [loading]="loading">
	</he-data-table-v3>

</he-base-dialog>
