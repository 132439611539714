import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IndividualPlayerConstants} from '../constant/individual-player.constants';
import {Subscription} from 'rxjs';
import {AppConfigService, BoErrorHandlerService, BreadcrumbService, ToastDisplayService} from '../../../../helio-core-services';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AppGuard} from '../../../guards';
import {PlayersService} from '../../../services/players.service';
import {LookupService} from '../../../services/lookup.service';
import {ResponsiveContent} from '../../../interfaces/responsive-content';
import {PlayerAuditDTO} from '../../../models/player/player-audit.model';
import * as ColumnNames from '../../../constants/ui-db-name-mappings';
import {ColumnType, DataTableLazyLoadEvent, SearchFieldType, ValidRows} from '../../data-table-v3';
import {HttpParams} from '@angular/common/http';
import {SelectItem} from 'primeng/api';
import {PlayerAuditService} from '../../../services/player-audit.service';
import {ExportCSVUtility, FileDownloadUtility} from '../../../utilities';
import {NAV_SEGMENT} from '../../../constants/navigation-titles';

@Component({
	selector: 'he-player-audit',
	templateUrl: './player-audit.component.html',
	styleUrls: ['./player-audit.component.scss']
})
export class PlayerAuditComponent  extends ResponsiveContent<PlayerAuditDTO> {

	title: string;

	/**
	 * Default of fault here is useful as it means when rendering the component via routing,
	 * the data: {isTabbedTable: false}, even need not be passed - as this is the default;
	 */
	@Input() isTabbedTable = false; // Represents false == 'globalPlayersPage' and true == 'tabbedIndividualPlayerPage'

	@Output() updateDashboard: EventEmitter<void> = new EventEmitter();

	rowsPerPage: ValidRows = 50;

	getTableDataSub$: Subscription;
	getCSVSub$: Subscription

	routePlayerID: number;
	oldRoutePlayerID = 0;

	selectedTargetData: SelectItem;
	selectedTransactions: PlayerAuditDTO[] = [];

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private appGuard: AppGuard,
				private breadcrumbService: BreadcrumbService,
				private toastDisplayService: ToastDisplayService,
				private playersService: PlayersService,
				private lookupService: LookupService,
				private playerAuditService: PlayerAuditService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.initDataTable();

		this.title = this.isTabbedTable ? IndividualPlayerConstants.TAB_NAMES.playerAuditTabbed
			: IndividualPlayerConstants.TAB_NAMES.playerAuditGlobal;

		this.breadcrumbService.setItems([
			{label: 'Player Management'},
			{label: this.title}
		]);

		this.route.paramMap.subscribe((params: ParamMap) => {
			// Necessary so that the table is update to reflect changes the new playerID via findPlayer
			this.routePlayerID = Number(params.get('id'));

			this.getTableData();
		});
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.getTableDataSub$, this.getCSVSub$
		);
	}

	initDataTable() {
		this.dataKey = ColumnNames.PLAYER_AUDIT_ID.DB;

		this.cols = [
			{
				field: ColumnNames.PLAYER_AUDIT_ID.DB,
				header: ColumnNames.PLAYER_AUDIT_ID.UI
			}
		];

		if (!this.isTabbedTable) {
			this.cols.push({
				field: ColumnNames.PLAYER_AUDIT_PLAYER_ID.DB,
				header: ColumnNames.PLAYER_AUDIT_PLAYER_ID.UI
			});
		}

		// Todo: Add others
		this.cols.push(
			{
				field: ColumnNames.PLAYER_AUDIT_DATETIME.DB,
				header: ColumnNames.PLAYER_AUDIT_DATETIME.UI,
				columnType: ColumnType.Date
			},
			/*{
				field: ColumnNames.PLAYER_AUDIT_CHANGED_BY.DB,
				header: ColumnNames.PLAYER_AUDIT_CHANGED_BY.UI
			},*/
			{
				field: ColumnNames.PLAYER_AUDIT_CHANGED_BY.DB,
				header: ColumnNames.PLAYER_AUDIT_CHANGED_BY.UI,
				columnType: ColumnType.Link,
				getRouterLink: (dto: PlayerAuditDTO) => this.getPlayerIDLink(dto),
				getLinkText: (dto: PlayerAuditDTO) => this.getPlayerName(dto),
				dynamicLinkText: true
			},
			{
				field: ColumnNames.PLAYER_AUDIT_CHANGED_FIELD.DB,
				header: ColumnNames.PLAYER_AUDIT_CHANGED_FIELD.UI
			},
			{
				field: ColumnNames.PLAYER_AUDIT_OLD_VALUE.DB,
				header: ColumnNames.PLAYER_AUDIT_OLD_VALUE.UI
			},
			{
				field: ColumnNames.PLAYER_AUDIT_NEW_VALUE.DB,
				header: ColumnNames.PLAYER_AUDIT_NEW_VALUE.UI
			}
		);

		this.tableActions = [
			{
				menuItem: {
					label: 'Export to CSV',
					icon: 'ui-icon-insert-drive-file'
				},
				callback: (callbackObj) => {
					this.exportToCSV(callbackObj.data, callbackObj.isAllDataSelected);
				},
				isRowAction: false // implies isBulkAction
			}
		];

		this.searchFields = []

		this.searchFields.push(
			{
				property: ColumnNames.PLAYER_AUDIT_ID.DB,
				label: ColumnNames.PLAYER_AUDIT_ID.UI,
				type: SearchFieldType.Id
			}
		);

		if (!this.isTabbedTable) {
			this.searchFields.push({
				property: ColumnNames.PLAYER_AUDIT_PLAYER_ID.DB,
				label: ColumnNames.PLAYER_AUDIT_PLAYER_ID.UI,
				type: SearchFieldType.Id
			});
		}

		// Todo: Add others
		this.searchFields.push(
			{
				property: ColumnNames.PLAYER_AUDIT_DATETIME.DB,
				label: ColumnNames.PLAYER_AUDIT_DATETIME.UI,
				type: SearchFieldType.Date
			},
			{
				property: ColumnNames.PLAYER_AUDIT_CHANGED_BY.DB,
				label: ColumnNames.PLAYER_AUDIT_CHANGED_BY.UI,
				type: SearchFieldType.StringDefault
			},
			{
				property: ColumnNames.PLAYER_AUDIT_CHANGED_FIELD.DB,
				label: ColumnNames.PLAYER_AUDIT_CHANGED_FIELD.UI,
				type: SearchFieldType.StringDefault
			},
			{
				property: ColumnNames.PLAYER_AUDIT_OLD_VALUE.DB,
				label: ColumnNames.PLAYER_AUDIT_OLD_VALUE.UI,
				type: SearchFieldType.StringDefault
			},
			{
				property: ColumnNames.PLAYER_AUDIT_NEW_VALUE.DB,
				label: ColumnNames.PLAYER_AUDIT_NEW_VALUE.UI,
				type: SearchFieldType.StringDefault
			}
		);

		this.fetchLookups();
	}

	protected fetchLookups() {
		super.fetchLookups();
	}

	getTableData(event?: DataTableLazyLoadEvent) {
		super.getTableData(event);

		this.params = this.computeTableDataHttpParams(event, undefined, this.rowsPerPage);
		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getPlayerAudits(this.params);
	}

	getPlayerAudits(params: HttpParams) {
		const observable: any = this.isTabbedTable ?
			this.playerAuditService.getAudits(this.routePlayerID, params) :
			this.playerAuditService.getAudits(undefined, params);

		this.getTableDataSub$ = observable.subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;
				this.offset = res.offset;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}

				this.loading = false;
			},
			error: error => {
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError(error, error.description);
				this.loading = false;
			}
		});
	}

	/**
	 * If {@link PlayerAuditDTO#changedBy} matches "Player(xxxx)" or "Player (xxxx)" or "Player  (xxxx)"
	 * return the concatenated path, else return undefined so that no link is added. Also return undefined when
	 * {@link isTabbedTable} is true, since the specific page is the displayed page in this scenario.
	 */
	private getPlayerIDLink(dto: PlayerAuditDTO): string[] | undefined  {
		if (this.isTabbedTable) {
			return undefined;
		}
		const regex = /^Player\s*\(\s*[a-zA-Z0-9]+\s*\)$/;

		try {
			const isChangedByPlayer = regex.test(dto.changedBy);
			if (isChangedByPlayer) {
				const extractorRegex = /^Player\s*\(\s*([a-zA-Z0-9]+)\s*\)\s*$/;
				const id = dto.changedBy.match(extractorRegex)[1];
				return [NAV_SEGMENT.PLAYER_MG__PLAYERS, id];
			}

			return undefined; // it is necessary to have this here and in catch, as this satisfies else condition when no err!
		} catch (e){
			return undefined;
		}
	}

	getPlayerName(dto: PlayerAuditDTO) {
		return dto.changedBy;
	}

	exportToCSV(selectedData?: PlayerAuditDTO[], isAllDataSelected?: boolean) {
		super.exportToCSV(selectedData, isAllDataSelected);

		this.loading = true;

		this.releaseSubscriptions(this.getCSVSub$);

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, selectedData, isAllDataSelected, ColumnNames.PLAYER_AUDIT_ID.DB
		);

		const observable: any = this.isTabbedTable ?
			this.playerAuditService.getAuditsCsv(this.routePlayerID, exportHttpParams, this.cols) :
			this.playerAuditService.getAuditsCsv(undefined, exportHttpParams, this.cols);

		this.getCSVSub$ = observable.subscribe({
			next: (res: string) => {
				if (res !== undefined) {
					// download file
					FileDownloadUtility.downloadCsv(
						res,
						this.routePlayerID ? `PlayerAuditForPlayer_${this.routePlayerID}` : 'AllPlayersAudit'
					);
				}

				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}
}
