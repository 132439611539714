import {SearchFieldType} from './search-field-type.enum';
import {SearchOperator} from './search-operator.enum';
import {Dictionary} from '../../../data-types';

export interface SearchFieldOperators {
	fieldType: SearchFieldType;
	operators: SearchOperator[];
}

export const AdvancedSearchOperators: Dictionary<SearchOperator[]> = new Dictionary<SearchOperator[]>();

AdvancedSearchOperators.add(SearchFieldType.StringDefault, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL,
	SearchOperator.STARTS_WITH
]);

AdvancedSearchOperators.add(SearchFieldType.StringWithContains, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL,
	SearchOperator.STARTS_WITH,
	SearchOperator.LIKE
]);

AdvancedSearchOperators.add(SearchFieldType.Boolean, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL
]);

AdvancedSearchOperators.add(SearchFieldType.Date, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL,
	SearchOperator.LESS_THAN,
	SearchOperator.LESS_THAN_OR_EQUAL,
	SearchOperator.GREATER_THAN,
	SearchOperator.GREATER_THAN_OR_EQUAL,
	SearchOperator.BETWEEN
]);

AdvancedSearchOperators.add(SearchFieldType.Id, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL
]);

AdvancedSearchOperators.add(SearchFieldType.NumberStrDefault, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL,
	SearchOperator.STARTS_WITH
]);

AdvancedSearchOperators.add(SearchFieldType.NumberStrWithContains, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL,
	SearchOperator.STARTS_WITH,
	SearchOperator.LIKE
]);

AdvancedSearchOperators.add(SearchFieldType.Number, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL,
	SearchOperator.LESS_THAN,
	SearchOperator.LESS_THAN_OR_EQUAL,
	SearchOperator.GREATER_THAN,
	SearchOperator.GREATER_THAN_OR_EQUAL,
	SearchOperator.BETWEEN
]);

AdvancedSearchOperators.add(SearchFieldType.NumberList, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL
]);

AdvancedSearchOperators.add(SearchFieldType.StringList, [
	SearchOperator.EQUAL,
	SearchOperator.NOT_EQUAL
]);
