<!--'layout-wrapper-static': layoutStatic, -->
<!--'layout-wrapper-inactive' - is when the menu only shows icons, width-wise or it is entirely collapsed -->
<div class="layout-wrapper" [ngClass]="{
                'layout-wrapper-active': menuActive,
                'layout-wrapper-inactive': !menuActive,
				'layout-menu-horizontal': layoutHorizontal,
				'layout-rtl': isRTL}">

	<he-app-loader></he-app-loader>

	<he-navigation [menuActive]="menuActive" (heMouseOverEvent)="onMouseOverHandler($event)"
				   (setToggleMenuLis)="registerToggleMenuLis($event)"
				   (slideMenuStateChange)="onMenuStaticStateChange($event)"></he-navigation>

	<div class="layout-main">
		<div id="appbar-breabcrumb-wrapper">
			<he-topbar (toggleMenu)="toggleMenu()"></he-topbar>

			<he-breadcrumb></he-breadcrumb>
		</div>

		<div class="layout-content">
			<div class="card card-w-title">
				<router-outlet></router-outlet>
			</div>
		</div>

		<!--<div class="layout-main-mask" *ngIf="mobileMenuActive" (click)="onMaskClick($event)"></div>-->
	</div>
</div>

<ng-template #overlayHost heComponentHost></ng-template>

<p-button *ngIf="showReturnToTop" icon="pi pi-chevron-up" styleClass="p-button-rounded"
		  class="heDontTargetMe labelScrollBtn returnToTop" (onClick)="returnToTop()"></p-button>
