import {Component, OnInit, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import {AppLayoutService} from './app-layout.service';
import {ComponentHostDirective} from '../../component-host';
import {ChangePasswordService} from '../../change-password';
import {MouseOverType, ToggleMenuListener} from '../../navigation';
import {WindowResizeListener} from '../../app.component';
import {actualHeight} from '../../shared/utilities/general-utilities/html.utils';

@Component({
	selector: 'he-secure-content',
	styleUrls: [
		'./secure-content.component.scss'
	],
	templateUrl: './secure-content.component.html'
})
export class SecureContentComponent implements OnInit, AfterViewInit, OnDestroy {

	public static contentElWidth;
	public static contentElHeight;
	static TAG = SecureContentComponent.name;
	private static winResizeLis: WindowResizeListener | null;
	toggleMenuLis: ToggleMenuListener | null;

	isStaticMenu = true;
	menuActive = false;
	layoutHorizontal = false;
	isRTL = false;

	@ViewChild('overlayHost', {static: false, read: ComponentHostDirective}) overlayHost: ComponentHostDirective;
	private canAnimateScrollPos = true;

	private lastScrollPos;
	showReturnToTop = false;

	constructor(
		private appLayoutService: AppLayoutService,
		private changePasswordService: ChangePasswordService
	) {
	}

	public static registerWindowResizeLis(l: WindowResizeListener | null) {
		SecureContentComponent.winResizeLis = l;
		// SecureContentComponent.computeContentDimensions();

		if (SecureContentComponent.winResizeLis) {
			SecureContentComponent.winResizeLis.onWindowResize(
				SecureContentComponent.contentElWidth, SecureContentComponent.contentElHeight);
		}
	}

	private static computeContentDimensions() {
		const html = document.querySelector('html') as HTMLElement;

		const htmlH = html.offsetHeight;
		const toolbar = document.querySelector('.layout-main he-topbar') as HTMLElement;
		const breadcrumb = document.querySelector('.layout-main he-breadcrumb') as HTMLElement;

		SecureContentComponent.contentElWidth = html.offsetWidth;
		// Subtract toolbar and breadcrumb contributions
		SecureContentComponent.contentElHeight = htmlH - (actualHeight(toolbar) + actualHeight(breadcrumb));

		const contentEl = document.querySelector('.layout-main .layout-content') as HTMLElement;
		contentEl.style.height = (SecureContentComponent.contentElHeight + 24) + 'px';

		if (SecureContentComponent.winResizeLis) {
			SecureContentComponent.winResizeLis.onWindowResize(
				SecureContentComponent.contentElWidth, SecureContentComponent.contentElHeight);
		}
	}

	ngOnInit() {
		this.isStaticMenu = false;
		// this.isStaticMenu = this.getMenuStaticPreference();
		// this.mobileMenuActive = this.isStaticMenu;

		this.appLayoutService.toggleMenuLayoutClick.subscribe(isStatic => {
			this.isStaticMenu = isStatic;
		});

		this.layoutHorizontal = this.appLayoutService.isMenuHorizontal;

		window.onresize = () => SecureContentComponent.computeContentDimensions();

		/*document.querySelector('#appbar-breabcrumb-wrapper').addEventListener('transitionstart', () => {
			console.log('Starting Transition: ');
			this.canAnimateScrollPos = false;
		})
		document.querySelector('#appbar-breabcrumb-wrapper').addEventListener('transitionend', () => {
			console.log('Ending Transition: ');
			this.canAnimateScrollPos = true;
		})*/

		// p-table p-scroller .p-scroller
		const body = document.querySelector('body') as HTMLElement;

		const collapseCls = 'collapseAppBar';

		const layoutContent = document.querySelector('.layout-content');
		const appbarBreabcrumbWrapper = document.querySelector('#appbar-breabcrumb-wrapper');

		let respondToScroll = 0;

		body.addEventListener('scroll', () => {
			respondToScroll++;

			// this.lastScrollPos && this.canAnimateScrollPos
			// console.log('scrollPos = ', layoutContent.getBoundingClientRect().top);

			if (respondToScroll < 5) {
				// Respond only to 5 units of scroll, to fix Collapsable appBar glitches when scrolling
				//  - this is small enough to ensure that all normal scrolls are responded to.
				return;
			}

			let isUp;

			if (this.lastScrollPos !== undefined && this.canAnimateScrollPos) {
				isUp = Number(layoutContent.getBoundingClientRect().top) > Number(this.lastScrollPos)

				// Using both isUp flag and checking that adding/removing class is necessary before proceeding
				// ensures that changes to classList are only conduct when there are actual changes - otherwise the UI jitters
				if (isUp && appbarBreabcrumbWrapper.classList.contains(collapseCls)) {
					appbarBreabcrumbWrapper.classList.remove(collapseCls);
				} else if (!isUp && // Only collapse the toolbar after scroll is 2x its height - i.e. from 106 to -106
					(layoutContent.getBoundingClientRect().top < -106) &&
					!appbarBreabcrumbWrapper.classList.contains(collapseCls)) {
					appbarBreabcrumbWrapper.classList.add(collapseCls);
				}
			}

			respondToScroll = 0;

			const scrollPos = layoutContent.getBoundingClientRect().top;
			// since negative position indicates that 100% of height has been passed at the foot of the page
			this.showReturnToTop = scrollPos < 0 // (-1 * scrollPos) > (body.offsetHeight);

			this.lastScrollPos = scrollPos;
		});
	}

	ngAfterViewInit() {
		this.changePasswordService.overlayHost = this.overlayHost;

		setTimeout(() => {
			const requiresPassChange = sessionStorage.getItem('requires_pass_change');
			if (requiresPassChange === 'true' || requiresPassChange === undefined || requiresPassChange === null || requiresPassChange === '') {
				this.changePasswordService.showChangePasswordDialog();
			}
		}, 1000);

		SecureContentComponent.computeContentDimensions();
	}

	ngOnDestroy() {
		if (!this.appLayoutService.isMobileOrTablet()) {
			// Save menu preference
			localStorage.setItem('is_menu_static', this.menuActive.toString());
		}
	}

	registerToggleMenuLis(l: ToggleMenuListener | null) {
		this.toggleMenuLis = l;
	}

	toggleMenu() {
		if (this.toggleMenuLis) {
			this.toggleMenuLis.onToggleMenu();
		}
		// this.mobileMenuActive = !this.mobileMenuActive;
	}

	onMouseOverHandler(type: MouseOverType) {
		if (type === 'enter' && !this.menuActive) {
			this.menuActive = true;
		} else if (type === 'leave' && !this.isStaticMenu) {
			this.menuActive = false;
		}
	}

	onMenuStaticStateChange(flag: boolean) {
		this.isStaticMenu = flag;
		this.menuActive = flag;
	}

	private getMenuStaticPreference() {
		return this.appLayoutService.isMobileOrTablet()
			? false : localStorage.getItem('is_menu_static') === null || localStorage.getItem('is_menu_static') === 'true';
	}

	returnToTop() {
		const body = document.querySelector('body');
		body.scrollTo({left: 0, top: 0, behavior: 'smooth'});
	}
}
