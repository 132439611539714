<!--[ngClass]="{'layout-sidebar-active': sidebarActive}"-->
<div class="layout-sidebar user-select-none" (click)="onSidebarClick($event)" [class.showMenu]="menuActive"
	 (mouseover)="mouseOverHandler('enter')" (mouseleave)="mouseOverHandler('leave')">
	<div class="side-menu-appbar">
		<div *ngIf="loggedInUser" class="mobile-user-area">
			<span
				style="font-size: 14px; padding: 0 1em; color: #ffa910; font-style: italic; font-weight: 900;">Welcome</span>

			<button id="user-area-btn" pButton type="button"
					[label]="loggedInUser.username" (click)="goToProfile()"></button>
		</div>
	</div>

	<div id="scroll-panel-wrapper">
		<p-scrollPanel>
			<ul app-submenu [menuItems]="refinedMenuItems" class="layout-menu" visible="true" [reset]="reset"
			></ul>
		</p-scrollPanel>
	</div>

	<div id="menu-bottom" (click)="onSlideMenuStateChange($event)">
			<span>{{isMenuPinned ? 'Collapse Menu' : 'Pin Menu'}}</span>
			<i [ngClass]="{'pi': true, 'pi-chevron-left': isMenuPinned, 'pi-chevron-right': !isMenuPinned}"></i>
	</div>
</div>

