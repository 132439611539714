import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {Subscription} from 'rxjs';
import {Confirmation} from 'primeng/api';
import {ConfirmDialogDisplayService} from '../../helio-core-services';

@Injectable({
	providedIn: 'root'
})
export class PromptUpdateService {

	swUpdate$: Subscription;

	constructor(private swUpdate: SwUpdate, private confirmDialogDisplayService: ConfirmDialogDisplayService) {
	}

	registerForSwUpdates() {
		if (this.swUpdate.isEnabled && !this.swUpdate$) {
			this.swUpdate$ = this.swUpdate.versionUpdates
				// .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
				.subscribe(evt => {
					switch (evt.type) {
						case 'VERSION_DETECTED':
							console.log(`#updates: Downloading new app version: ${evt.version.hash}`);
							break;
						case 'VERSION_READY':
							console.log(`#updates: Current app version: ${evt.currentVersion.hash}`);
							console.log(`#updates: New app version ready for use: ${evt.latestVersion.hash}`);
							this.promptUser();
							break;
						case 'VERSION_INSTALLATION_FAILED':
							console.log(`#updates: Failed to install app version '${evt.version.hash}': ${evt.error}`);
							break;
					}
				});

			console.log('registerForSwUpdates: Now registered...');
		} else {
			console.log('registerForSwUpdates: Already registered!');
		}
	}

	private promptUser() {
		const confMessage: Confirmation = {
			message: 'A new version of the app is available, click YES to update to it.',
			accept: () => {
				// Reload the page to update to the latest version.
				document.location.reload();
			},
			reject: () => {
				// Do nothing
			}
		};

		this.confirmDialogDisplayService.showConfirmDialog(confMessage);
	}
}
