	import { Routes } from '@angular/router';

import { NoContentComponent } from './no-content';
import { AppGuard } from './shared';
import { PublicContentComponent, PUBLIC_ROUTES, SecureContentComponent, SECURE_ROUTES } from './layout';

export const ROUTES: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},
	{
		path: '',
		component: PublicContentComponent,
		children: PUBLIC_ROUTES
	},
	{
		path: '',
		component: SecureContentComponent,
		canActivate: [AppGuard],
		children: SECURE_ROUTES
	},
	{
		path: '**',
		component: NoContentComponent
	}
];
