import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AppConfigService, LoginService} from '../helio-core-services';
import {Login} from '../shared';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {numToPxStr} from '../shared/utilities/general-utilities/string.utility';
import {LookupService} from '../shared/services/lookup.service';

@Component({
	selector: 'he-login',
	styleUrls: [
		'./login.component.scss'
	],
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, AfterViewInit {

	loginModel: Login = new Login();
	isLoginFormLoading = false;
	loginFailedMsg?: string;
	loginDetailsInvalid = false;
	checksum = '-1';

	isSessionExpired = false;

	progressSpinnerStyle: any = {
		width: '28px',
		height: '28px',
		position: 'relative',
		top: '10px',
		right: '10px'
	};

	loginForm: FormGroup;

	private redirectURL: string;

	constructor(
		private router: Router,
		private appConfigService: AppConfigService,
		private loginService: LoginService,
		private lookupService: LookupService,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder
	) {
	}

	get isLoginValid(): boolean {
		const username = this.loginForm.get('username');
		const password = this.loginForm.get('password');

		return (username.valid || username.pristine) && (password.valid || password.pristine);
	}

	ngOnInit() {
		this.formInit();

		this.route.queryParamMap.subscribe((params: ParamMap) => {
			if (params.has('sessionExpired')) {
				this.isSessionExpired = (params.get('sessionExpired') === 'true');
			}

			if (params.has('redirectURL')) {
				this.redirectURL = params.get('redirectURL');
			}
		});

		this.attachAutoFillListener();
	}

	/**
	 * Sets listeners on form inputs, to determine when autofilled by browser and adjust
	 * the Floating Label accordingly.
	 */
	attachAutoFillListener(): void {
		const AUTOFILLED = 'he-is-autofilled';
		const onAutoFillStart = (el) => el.classList.add(AUTOFILLED);
		const onAutoFillCancel = (el) => el.classList.remove(AUTOFILLED);

		const onAnimationStart = ({target, animationName}) => {
			switch (animationName) {
				case 'onAutoFillStart':
					return onAutoFillStart(target);
				case 'onAutoFillCancel':
					return onAutoFillCancel(target);
			}
		};

		document.getElementById('username')
			.addEventListener('animationstart', onAnimationStart, false);

		document.getElementById('password')
			.addEventListener('animationstart', onAnimationStart, false);
	}

	submitLogin() {
		if (!this.isLoginFormLoading) {
			this.isLoginFormLoading = true;
			this.loginFailedMsg = null;

			this.loginModel.username = this.loginForm.get('username').value;
			this.loginModel.password = this.loginForm.get('password').value;

			this.loginService.loginUser(this.loginModel)
				.subscribe({
					next: () => {
						if (this.loginService.isLoggedInAndTokenValid) {
							this.lookupService.initAllEagerly(this.loginService.isLoggedInAndTokenValid);

							this.isLoginFormLoading = false;
							sessionStorage.setItem('user_name', this.loginModel.username);

							this.lookupService.initActionPermissions().then(() => {
								if (this.redirectURL !== undefined) {
									this.router.navigateByUrl(this.redirectURL);
								} else {
									this.router.navigateByUrl('/home');
								}
							});
						}
					},
					error: err => {
						this.isLoginFormLoading = false;
						this.loginFailedMsg = err.error[0]?.description ?? `Auth error: ${this.appConfigService.genericErrorMessage}`;
						this.loginModel.password = '';
					}
				});
		}
	}

	ngAfterViewInit(): void {
		const loginContainer: HTMLElement = document.querySelector('div#login-container');
		const htmlHeight = document.querySelector('html').offsetHeight;
		const gap = htmlHeight - loginContainer.offsetHeight;

		loginContainer.style.setProperty('--login-margin-top', numToPxStr(0.4 * gap));
		loginContainer.style.setProperty('--login-margin-btm', numToPxStr(0.6 * gap));
	}

	private formInit() {
		this.loginForm = this.formBuilder.group({
			username: new FormControl('', Validators.required),
			password: new FormControl('', Validators.required)
		});
	}

}
