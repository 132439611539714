import { Dictionary } from '../../../data-types';
import { SearchOperator } from './search-operator.enum';

export interface SearchOperatorData {
	label: string;
	filterOperatorString: string;
	value: SearchOperator;
}

export const SearchOperatorData: Dictionary<SearchOperatorData> = new Dictionary<SearchOperatorData>();

SearchOperatorData.add(SearchOperator.EQUAL, {
	label: 'is equal to',
	filterOperatorString: 'eq',
	value: SearchOperator.EQUAL
});

SearchOperatorData.add(SearchOperator.NOT_EQUAL, {
	label: 'is not equal to',
	filterOperatorString: 'ne',
	value: SearchOperator.NOT_EQUAL
});

SearchOperatorData.add(SearchOperator.LESS_THAN, {
	label: 'is less than',
	filterOperatorString: 'lt',
	value: SearchOperator.LESS_THAN
});

SearchOperatorData.add(SearchOperator.LESS_THAN_OR_EQUAL, {
	label: 'is less than or equal to',
	filterOperatorString: 'le',
	value: SearchOperator.LESS_THAN_OR_EQUAL
});

SearchOperatorData.add(SearchOperator.GREATER_THAN, {
	label: 'is greater than',
	filterOperatorString: 'gt',
	value: SearchOperator.GREATER_THAN
});

SearchOperatorData.add(SearchOperator.GREATER_THAN_OR_EQUAL, {
	label: 'is greater than or equal to',
	filterOperatorString: 'ge',
	value: SearchOperator.GREATER_THAN_OR_EQUAL
});

SearchOperatorData.add(SearchOperator.LIKE, {
	label: 'contains',
	filterOperatorString: 'lk',
	value: SearchOperator.LIKE
});

SearchOperatorData.add(SearchOperator.BETWEEN, {
	label: 'is between',
	filterOperatorString: undefined,
	value: SearchOperator.BETWEEN
});

SearchOperatorData.add(SearchOperator.STARTS_WITH, {
	label: 'starts with',
	filterOperatorString: 'sw',
	value: SearchOperator.STARTS_WITH
});
