<div style="display: flex; justify-content: space-between; align-items: center; margin: 0 10px;">
	<he-header headerTitle="Home"></he-header>

	<button *ngIf="isDashboardEnabled" class="helio-orange-btn" pButton type="button" pRipple
			(click)="showWidgetCentre = true" [label]="addLabel" [title]="addLabel" icon="pi pi-plus"></button>
</div>

<he-dashboard-panel *ngIf="isDashboardEnabled" [widgets]="widgets"></he-dashboard-panel>

<he-base-dialog [header]="'Widgets Centre'" [(visible)]="showWidgetCentre" [isLoadingLookups]="false"
				[style]="{'max-width': '90%', 'min-width': '70%', 'max-height': '90%', 'min-height': '64%'}"
				(visibleChange)="closeDialog()" [rejectBtnOnly]="true" [rejectLabel]="'Close'">
	<p style="margin: 8px 0 18px 0;">
		Add custom widgets to get insights of interest to you...
	</p>

	<div class="widget-catalogue">
		<he-widget-catalogue *ngFor="let customWidget of customWidgets" [title]="customWidget.title"
							 [subTitle]="customWidget.subTitle"
		[data]="customWidget" (addWidgetEvent)="onAddCustomWidget($event)">
		</he-widget-catalogue>
	</div>
</he-base-dialog>
