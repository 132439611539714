<!--(onHide)="onDialogClosed()"-->
<!--header="{{header}}" is not used as an attr on p-dialog auto applies default (onHide)-->
<p-dialog [visible]="visible" [modal]="true" [style]="style" [closable]="false" [contentStyle]="{'overflow':'visible'}">
	<ng-template pTemplate="header">
		<span class="p-dialog-title" id="header_title">{{header}}</span>

		<p-button type="button" class="p-dialog-header-close" icon="pi pi-times" (onClick)="showDialog(false)"></p-button>
	</ng-template>

	<div class="dialog-content">
		<p *ngIf="description">{{description}}</p>

		<!--position: absolute - is intended to remove the skeleton from being placed within the parent and thus
		the container height should only be a function of the content-div;
		width: 100% and padding-right: 32px simply statically styles the resulting position of the skeleton-->
		<div id="skel-div" *ngIf="isLoadingLookups" style="position: absolute; width: 100%; padding-right: 32px;">
			<he-skeleton-form [inputCount]="skeletonInputCount"></he-skeleton-form>
		</div>

		<!--Using visibility so that the original height of dialog is retained when showing skel-->
		<div id="content-div" [style.visibility]="isLoadingLookups ? 'hidden' : 'visible'">
			<ng-content></ng-content>
		</div>
	</div>

	<p-footer>
		<button *ngIf="!submitBtnOnly" type="button" pButton icon="ui-icon-close" (click)="showDialog(false)"
                [label]="rejectLabel" name="cancelButton" [title]="rejectLabel" data-cy="negativeBtn"></button>

		<button *ngIf="!rejectBtnOnly" type="submit" pButton icon="ui-icon-check" [disabled]="disableSubmit"
				data-cy="positiveBtn" [label]="submitLabel" [title]="submitLabel" (click)="onSubmitClicked()"
				[ngStyle]="submitStyle">
		</button>
	</p-footer>
</p-dialog>
