// Utility file for registering locales

import { registerLocaleData } from '@angular/common';

import localeDa from '@angular/common/locales/da';
import localeFr from '@angular/common/locales/fr';
import localeEnGb from '@angular/common/locales/en-GB';
import localeMt from '@angular/common/locales/mt';
import localeArAe from '@angular/common/locales/ar-AE';

registerLocaleData(localeDa);
registerLocaleData(localeFr);
registerLocaleData(localeEnGb);
registerLocaleData(localeMt);
registerLocaleData(localeArAe);
