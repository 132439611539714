import * as fileSaver from 'file-saver';

export class FileDownloadUtility {

	private static filenameHasExt(filename: string) {
		const regEx = /^[A-Za-z0-9_-]+.[A-Za-z]+/g;
		return regEx.test(filename);
	}
	/**
	 * Function to download CSV File
	 * @param responseData File contents retrieved from server
	 * @param filename Filename of the downloaded file - do not include extension
	 */
	public static downloadCsv(responseData: any, filename: string): void {
		const byteCharacters = responseData as string;
		const byteNumbers = [byteCharacters.length];

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: 'text/csv' });
		fileSaver.saveAs(blob, `${filename}${(this.filenameHasExt(filename) ? '' : '.csv')}`);
	}

	public static downloadZip(responseData: Blob, filename: string): void {
		// const blob = new Blob(responseData, { type: 'application/zip' });
		fileSaver.saveAs(responseData, `${filename}${(this.filenameHasExt(filename) ? '' : '.zip')}`);
	}

	/**
	 * This relies on the correct file ext being already set in the filename string.
	 */
	public static downloadAsIs(responseData: Blob, filename: string): void {
		fileSaver.saveAs(responseData, `${filename}`);
	}
}
