import { Routes } from '@angular/router';
import { MyProfileComponent } from 'src/app/my-profile/my-profile.component';

import { HomeComponent } from '../../home';
import { AppGuard } from '../../shared';

export const SECURE_ROUTES: Routes = [
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AppGuard]
	},
	{
		path: 'my-profile',
		component: MyProfileComponent,
		canActivate: [AppGuard]
	},
	{
		path: 'group-management',
		loadChildren: () => import('../../group-rights/group-rights.module').then(m => m.GroupRightsModule),
		canActivate: [AppGuard]
	},
	{
		path: 'user-management',
		loadChildren: () => import('../../user-rights/user-rights.module').then(m => m.UserRightsModule),
		canActivate: [AppGuard]
	},
	{
		path: 'tenant-management',
		loadChildren: () => import('../../tenant-management/tenant-management.module').then(m => m.TenantManagementModule),
		canActivate: [AppGuard]
	},
	{
		path: 'player-management',
		loadChildren: () => import('../../player-management/player-management.module').then(m => m.PlayerManagementModule),
		canActivate: [AppGuard]
	},
	{
		path: 'reports',
		loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule),
		canActivate: [AppGuard]
	},
	{
		path: 'financial',
		loadChildren: () => import('../../financial/financial.module').then(m => m.FinancialModule),
		canActivate: [AppGuard]
	},
	{
		path: 'finance',
		loadChildren: () => import('../../finance/finance.module').then(m => m.FinanceModule),
		// canActivate: [AppGuard]
	},
	{
		path: 'draw-processing',
		loadChildren: () => import('../../draw-processing/draw-processing.module').then(m => m.DrawProcessingModule),
		canActivate: [AppGuard]
	},
	{
		path: 'ticket-management',
		loadChildren: () => import('../../ticket-management/ticket-management.module').then(m => m.TicketManagementModule),
		canActivate: [AppGuard]
	},
	{
		path: 'retail-agents',
		loadChildren: () => import('../../retail-agent/retail-agent.module').then(m => m.RetailAgentModule),
		canActivate: [AppGuard]
	}
];
