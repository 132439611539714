import {Component, EventEmitter, Input, Output, Renderer2, ElementRef, OnInit, HostBinding, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Confirmation} from 'primeng/api';
import {ConfirmDialogDisplayService} from '../../../../helio-core-services';
import {DIALOG_CONFIRM_MSG_CRITICAL} from '../../../constants/constants';

@Component({
	selector: 'he-base-dialog',
	templateUrl: './base-dialog.component.html',
	styleUrls: ['./base-dialog.component.scss']
})
export class BaseDialogComponent implements OnInit, OnDestroy {

	@Input() header = '';
	@Input() style: object = {};
	@Input() submitStyle: object = {};
	@Input() isLoadingLookups = false;
	@Input() description: string; // show below the header
	@Input() rejectLabel = 'Cancel';
	@Input() submitLabel = 'Submit';

	@Input() submitBtnOnly = false;
	@Input() rejectBtnOnly = false;

	@Input() useConfirmDialog = true;
	@Input() confirmDialogMsg = DIALOG_CONFIRM_MSG_CRITICAL;

	// @Output() hideEvent = new EventEmitter<void>();
	@Output() submitEvent = new EventEmitter<void>();
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() skeletonInputCount: '1' | '2' | '3' | '5' = '3';

	private _disableSubmit$ = new BehaviorSubject<boolean>(false);
	private _visible$ = new BehaviorSubject<boolean>(false);

	constructor(private confirmationDisplayService: ConfirmDialogDisplayService,
				private renderer: Renderer2,
				private elmRef: ElementRef) {
	}

	get disableSubmit() {
		return this._disableSubmit$.getValue();
	}

	@Input() set disableSubmit(value: boolean) {
		this._disableSubmit$.next(value);
	}

	get visible() {
		return this._visible$.getValue();
	}

	@HostBinding('attr.vis')
	@Input() set visible(value: boolean) {
		this._visible$.next(value);
	}

	ngOnInit() {
		/*this._visible$.asObservable().subscribe({
			next: (value) => {
				// this.renderer.setStyle(this.elmRef.nativeElement, 'visibility', value ? 'visible' : 'hidden');
				console.log('BaseDialogComponent: _visible$')
			}
		});*/
	}

	ngOnDestroy() {
	}

	showDialog(show: boolean = false) {
		this.visibleChange.emit(show);
	}

	onSubmitClicked() {
		if (this.useConfirmDialog) {
			const confMessage: Confirmation = {
				message: this.confirmDialogMsg,
				accept: () => {
					this.submitEvent.emit();
					this.showDialog(false); // This must be sent back since so if not using binding, the caller can reset its own value
					// this.onDialogClosed();
				},
				reject: () => {
					this.showDialog(false);
				}
			};

			// The importance of supporting both visibilityChange and onDialogClosed is to allow an
			// instance to live on before a confirmation to submit - or discard - it is given by UI,
			// thus enabling its form values to be extracted.
			// this.renderer.setStyle(this.elmRef.nativeElement, 'visibility', 'hidden');/**/
			// ===== THIS APPROACH DIS NOT WORK AS "this._visible$.asObservable()", set in ngOnInit, does not re-deliver visible
			// for some unknown reason  =====

			this.confirmationDisplayService.showConfirmDialog(confMessage);

			// locally enforce hiding of dialog to show confirmation screen
			// onVisibleChange is later called if ACCEPT to allow caller to sync with this value
			this.visible = false;
		} else {
			this.submitEvent.emit();
			this.showDialog(false);
		}
	}
}
