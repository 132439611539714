<div id="root">
    <div id="container" class="card" style="width: 100%; height: 100%"></div>

    <div id="filter" class="card">


        <div id="filter-toggle">
            <button style="width:auto" class="helio-orange-btn" pButton
                    (click)="expandFilterPane = !expandFilterPane"
                    [icon]="'pi ' + (expandFilterPane ? 'pi-chevron-right' : 'pi-chevron-left')"></button>

            <div id="collapse-label" *ngIf="!expandFilterPane">Filters</div>
        </div>


        <div id="filter-container" [style.display]="expandFilterPane ? 'block' : 'none'">
            <div class="header">
                <h3>Filters</h3>

                <div style="margin-bottom: 22px;">
                    Filters on this page
                </div>
            </div>

            <ng-container *ngIf="loading; else showFilters">
                <p-skeleton [style]="{'margin-top': '1em', 'margin-bottom': '1em'}" width="100%"
                            height="33px"></p-skeleton>
            </ng-container>

            <ng-template #showFilters>
                <div id="content">
                    <he-filter-component *ngFor="let field of filterFields" [filterField]="field"
                                         (valueChange)="onFilterValueChange($event)"
                                         (searchEvent)="onFilterListSearchEvent($event)"
                                         (lazyLoadEvent)="onLazyLoadEvent($event)"></he-filter-component>
                </div>

                <div id="footer">
                    <div>
                        <button style="width:auto" [disabled]="false" class="helio-orange-btn" pButton
                                (click)="submitSearchClick()" label="Apply Filter" icon="ui-icon-search"></button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
