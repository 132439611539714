import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchField, SearchType} from '../advanced-search';
import {SearchEventType, SubmitSearchEvent} from '../advanced-search/submit-search-event.model';

@Component({
	selector: 'he-filter-search',
	templateUrl: './filter-search.component.html',
	styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit {
	@Input() searchFields: SearchField[];
	@Output() searchEvent: EventEmitter<SubmitSearchEvent> = new EventEmitter<SubmitSearchEvent>();
	showFilterSearch = true;

	protected readonly SearchType = SearchType;

	ngOnInit() {
		// console.warn('FilterSearchComponent: searchFields =', this.searchFields)
	}

	/**
	 * @todo If implementing table in future, then examine how this is setup in DataTableV3Component#handleAdvancedSearch
	 */
	handleAdvancedSearch(event: SubmitSearchEvent): void {
		if (event.searchType === SearchEventType.CLOSE) {
			// this.showFilterSearch = false;
			// this.advancedSearchService.fields.delete(this.urlPath);
			// this.advancedSearchService.setShowAdvancedSearch(this.urlPath, false);
			return;
		} else if (event.searchType === SearchEventType.CLEAR) {
			return;
		} else if (event.searchType === SearchEventType.SUBMIT) {
			this.searchEvent.emit(event);
		}
	}
}
