import {Subscription} from 'rxjs';

export abstract class ComponentHelper {
	releaseSubscriptions(...subs: Subscription[]) {
		for (let s of subs) {
			if (s) {
				s.unsubscribe();
				s = undefined;
			}
		}
	}
}
