
export enum SearchFieldType {
	StringDefault,
	StringWithContains,
	Boolean,
	Date,
	Time,
	DateTime,
	DateRange,
	DateTimeRange,
	Id,
	Number,
	NumberRange,
	NumberStrDefault, // Allows validation to be added for an input which should be a number UI-side, but string when parsed for BE
	NumberStrWithContains,
	NumberList,
	StringList,
}


export enum ReportFilterFieldType {
	String,
	Date,
	Time,
	DateTime,
	DateRange,
	DateTimeRange,
	Number, // This corresponds to - "reportVariableComponent": "NumberRange" - in the API

	SearchableSelectionList,
	AdvancedSearchableSelectionList,
	SearchableMultiSelectionList,
	AdvancedSearchableMultiSelectionList,
}
