import { ComponentHostDirective } from './component-host.directive';
import { NgModule } from '@angular/core';

@NgModule({
	declarations: [
		ComponentHostDirective
	],
	exports: [
		ComponentHostDirective
	]
})
export class ComponentHostModule { }
