export class AppConfig {
	constructor(
		public isCentralServer: boolean,
		public hasWallet: boolean,
		public useSubdomain: boolean,
		public centralBaseURL: string,
		public centralAuthURL: string,
		public tenantBaseURL: string,
		public tenantAuthURL: string,
		public walletBaseURL: string,
		public genericErrorMessage: string,
		public tableEmptyMessage: string,
		public tableMissingDataMessage: string,
		public defaultDateFormat: string,
		public defaultTimezoneOffset: string,
		public defaultNumberFormat: string,
		public defaultMoneyFormat: string,
		public tokenClientID: string,
		public inactiveTimeLimit: number
	) { }
}
