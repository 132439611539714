import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServiceAction, ServiceController} from '../utilities';
import {AppConfigService, BaseService} from '../../helio-core-services';
import {TransferDetails} from '../models/finance/transfer-information.model';

@Injectable({
	providedIn: 'root'
})
export class WithdrawalsService extends BaseService {

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.WITHDRAWALS, appConfigService.serviceBaseURL);
	}

	getTransferDetailsByPaymentOrder(paymentOrderID: number): Observable<TransferDetails> {
		return this.get(undefined, [paymentOrderID, ServiceAction.GET_TRANSFER_DETAILS]);
	}

	getBankDetailsByPlayer(playerID: number): Observable<TransferDetails> {
		const params = new HttpParams().set('playerID', playerID);

		return this.get(ServiceAction.GET_BANK_DETAILS, undefined, params);
	}

	updateBankDetails(data: TransferDetails): Observable<TransferDetails> {
		return this.edit(ServiceAction.UPDATE_BANK_DETAILS, data,undefined, undefined);
	}
}
