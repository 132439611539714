import {CommonTable} from './common-table';
import {AfterViewInit, Directive, OnDestroy, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';

@Directive()
export abstract class ResponsiveContent<D> extends CommonTable<D> implements OnInit, AfterViewInit, OnDestroy {

	abstract ngOnInit();

	abstract ngAfterViewInit();

	abstract ngOnDestroy();
}

export type HeApiEvent = 'start' | 'end';
