import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {CustomWidgetDto, WidgetDto} from '../../dashboard/model/widget-dto';
import {delay} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	constructor() {
	}

	public getWidgets(): Observable<WidgetDto[]> {
		const widgets = [
			{width: 4, height: 2, content: 'Widget 1'},
			{width: 4, height: 4, content: 'Widget 2'},
			{width: 2, height: 2, content: 'Widget 3'},
			{width: 2, height: 2, content: 'Widget 4'},
			{width: 2, height: 2, content: 'Widget 5'},
			{width: 2, height: 4, content: 'Widget 6'},
			{width: 4, height: 2, content: 'Widget 7'},
			{width: 2, height: 2, content: 'Widget 8'},
			{width: 4, height: 2, content: 'Widget 9'},
			{width: 2, height: 2, content: 'Widget 10'},
			{width: 2, height: 2, content: 'Widget 11'}
		];

		return of(widgets).pipe(delay(500));
	}

	public getCustomWidgets(): Observable<CustomWidgetDto[]> {
		const widgets: CustomWidgetDto[] = [
			{title: 'BarChart', subTitle: 'Gain insight to your data at a glance', width: 4, height: 2, content: 'Widget 12'},
			{title: 'Pie', subTitle: 'Your progress at a glance', width: 4, height: 4, content: 'Widget 13'},
			{title: 'Table', subTitle: 'Interact with data', width: 2, height: 2, content: 'Widget 14'},
			{
				title: 'Bar',
				subTitle: 'Gain insight to your data at a glance, and interact with results - however you like',
				width: 2,
				height: 4,
				content: 'Widget 15'
			},
			{title: 'Table', subTitle: 'Interact with data', width: 6, height: 4, content: 'Widget 16'},
		];

		return of(widgets).pipe(delay(500));
	}
}
