import { Injectable } from '@angular/core';
import { ConfirmationService, Confirmation } from 'primeng/api';

@Injectable()
export class ConfirmDialogDisplayService {
	constructor(
		private confirmationService: ConfirmationService
	) { }

	/**
	 * Shows confirm dialog
	 * @param confirmation confirmation interface in primeNg API
	 * @param isDefaultSetup default icon and header for the dialog
	 */
	showConfirmDialog(confirmation: Confirmation, isDefaultSetup = true) {
		if (isDefaultSetup) {
			confirmation.header = 'Confirmation';
			confirmation.icon = 'ui-icon-info';
		}
		this.confirmationService.confirm(confirmation);
	}
}
