import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {DIALOG_CONFIRM_MSG_NORMAL, RE_COMMENTS} from '../../../constants/constants';
import {BehaviorSubject} from 'rxjs';
import {BaseLookup} from '../../../interfaces/lookup-interfaces';
import {DialogOpts} from '../dialog-opts';

@Component({
	selector: 'he-change-status-dialog-v2',
	styleUrls: [
		'./change-status-dialog-v2.scss',
		'../common-draw-dialog-style.scss'
	],
	templateUrl: './change-status-dialog-v2.component.html'
})
export class ChangeStatusDialogV2Component implements OnInit {

	@Input() visible = false;
	@Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() dialogTitle = 'Change KYC Status';
	submitLabel = 'Change';

	// @Input() data: any;
	@Input() isDisabled = false;

	@Output() dialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() submitEvent: EventEmitter<StatusRequest> = new EventEmitter<StatusRequest>();

	isLoadingLookupsOrModel = false;
	form: FormGroup;
	confirmDialogMsg: string = DIALOG_CONFIRM_MSG_NORMAL;

	selectedStatusID: number;

	private _options$ = new BehaviorSubject<DialogOpts>(null);

	constructor(
		private formBuilder: FormBuilder
	) {
	}

	get options() {
		return this._options$.getValue();
	}

	@Input() set options(value: DialogOpts) {
		this._options$.next(value);
	}

	ngOnInit() {
		if (!this.options || !this.options?.selectedStatus || !this.options?.lookupList) {
			this.isLoadingLookupsOrModel = true;
		}

		const commentsValidators = [
			Validators.pattern(RE_COMMENTS),
			Validators.minLength(6),
			Validators.maxLength(2500)
		];

		if (this.options?.selectedStatus) {
			commentsValidators.splice(0, 0, Validators.required)
		}

		this.form = this.formBuilder.group({
			status: new FormControl(this.options?.selectedStatus ? this.options?.selectedStatus : ''),
			comment: new FormControl('', commentsValidators)
		});

		this.form.get('status').valueChanges
			.subscribe(newValue => {
				// Get status names from selected ID
				const oldOpt: BaseLookup = this.options.lookupList.find(entry => {
					return entry.value === this.selectedStatusID;
				});

				const newOpt: BaseLookup = this.options.lookupList.find(entry => {
					return entry.value === newValue;
				});

				// Require "comments" if selected status == ‘KYC Rejected’
				this.addOrRemoveCommentsValidator(newValue);

				this.confirmDialogMsg = (!oldOpt || !newOpt) ? DIALOG_CONFIRM_MSG_NORMAL :
					`Are you sure you want to change from '${oldOpt?.label}' to '${newOpt?.label}'`;

				this.selectedStatusID = newValue;
			});

		// Update selected status on form if @Input value changes and is defined
		this._options$.asObservable().subscribe({
			next: options => {
				const hasLookup = options && options?.lookupList;
				const hasCurrentStatus = options?.selectedStatus; // The current mapped data in DB

				// console.log('options?.selectedStatus', options?.selectedStatus)

				if (hasLookup && hasCurrentStatus) {
					this.selectedStatusID = this.options?.selectedStatus;
					this.form.get('status').setValue(options?.selectedStatus);

					this.isLoadingLookupsOrModel = false;

					this.addOrRemoveCommentsValidator(options?.selectedStatus);
				} else if (hasLookup && !hasCurrentStatus) {
					// Lookup is present but undefined is returned for current status
					this.form.get('status').setValue('');
					this.isLoadingLookupsOrModel = false;
				}
				else {
					this.form.get('status').setValue(''); // to ensure that stale values are not presented as current values
				}
			}
		})
	}

	addOrRemoveCommentsValidator(statusId: number | string) {
		const opt: BaseLookup = this.options.lookupList.find(entry => {
			return entry.value === statusId;
		});

		if (this.form && opt) {
			const statusLabel = opt.label;

			// Require "comments" if selected status == ‘KYC Rejected’
			if (statusLabel.toLowerCase().includes('reject')) {
				this.form.get('comment').addValidators(Validators.required);
			} else {
				this.form.get('comment').removeValidators(Validators.required);
			}

			// this.form.updateValueAndValidity();
			this.form.get('comment').updateValueAndValidity();
		}
	}

	public closeDialog() {
		this.form.reset();
		this.dialogClose.emit(false);
	}

	onDialogSubmit() {
		// NB: 1. all elements within the same #kycDocumentReferences field are associated to the same playerKYCDocumentsID
		//     2. There are instances where "kycDocumentReferences" == []

		const requestData: StatusRequest = {
			selectedStatusID: this.form.get('status').value,
		}

		const comment = this.form.get('comment').value;

		if (comment && comment.length > 0) {
			requestData.comment = this.form.get('comment').value;
		}

		this.submitEvent.emit(requestData);

		this.visible = false;
	}
}

export class StatusRequest {
	selectedStatusID: number;
	comment?: string;
}
