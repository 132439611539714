import {Injectable} from '@angular/core';
import {AppConfigService, BaseService} from '../../helio-core-services';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormatParams, ServiceController} from '../utilities';
import {Observable} from 'rxjs';
import {TableDataResponse} from '../components';
import {CSV_FORMAT, FORMAT_URL_PARAM} from '../constants';
import {ResponseBodyType} from '../enums';
import {PlayerAuditDTO} from '../models/player/player-audit.model';
import {PLAYER_AUDIT_DATETIME, PLAYER_AUDIT_ID, PLAYER_AUDIT_PLAYER_ID} from '../constants/ui-db-name-mappings';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PlayerAuditService extends BaseService {

	constructor(protected http: HttpClient, protected appConfigService: AppConfigService) {
		super(http, ServiceController.PLAYER_AUDIT, appConfigService.serviceBaseURL);
	}

	private _parseGetAuditsParams(playerID?: number, searchParams?: HttpParams, entityTypeID: 1 | 2 | 3 = 3) {
		let params = searchParams ? searchParams : new HttpParams();
		params = params.set('entityTypeID', entityTypeID);

		if (playerID !== undefined) {
			params = params.set('entityID', playerID);
		}

		return params;
	}

	/**
	 * Fetch the {@link PlayerAuditDTO}s for an operator or a specific player of the operator.
	 * @param searchParams The HttpParams for the request.
	 * @param playerID If provided then the returned transactions are for a specific player
	 * @param entityTypeID EntityTypes: 1 - User | 2 - Retail Agent | 3 - PlayerFull
	 *                     Right now we only support change trails for Players
	 */
	getAudits(playerID?: number, searchParams?: HttpParams, entityTypeID: 1 | 2 | 3 = 3)
		: Observable<TableDataResponse<PlayerAuditDTO>> {
		const params = this._parseGetAuditsParams(playerID, searchParams, entityTypeID);

		return this.validateDataTableRes(
			[PLAYER_AUDIT_ID.DB, PLAYER_AUDIT_PLAYER_ID.DB, PLAYER_AUDIT_DATETIME.DB], undefined,
			undefined, params, ResponseBodyType.JSON
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: PlayerAuditDTO) => {
					// Parse Date object
					entry.changeDate = new Date(entry.changeDate);

					return entry;
				})

				return res as TableDataResponse<PlayerAuditDTO>;
			}));
	}

	getAuditsCsv(
		playerID?: number,
		searchParams?: HttpParams,
		xFormatParams?: FormatParams[],
		entityTypeID: 1 | 2 | 3 = 3
	): Observable<string> {
		let params = this._parseGetAuditsParams(playerID, searchParams, entityTypeID);
		params = params.set(FORMAT_URL_PARAM, CSV_FORMAT);

		return this.get(undefined, undefined, params, ResponseBodyType.Text,
			undefined, xFormatParams);
	}
}
