<div class="filter-field-name"
	 [class.he-disabled]="heDisabled">
	<label>{{ filterField.label }}</label>
</div>

<div [ngSwitch]="filterField.type" class="filter-field-value">
	<!-- String -->
	<span class="p-float-label" *ngSwitchCase="filterFieldType.String">
				<input id="{{ inputID }}" pInputText
					   [ngModel]="filterField.searchValue" (ngModelChange)="onValueChange($event, filterField)"/>

				<label for="{{ inputID }}">Value - Text</label>
			</span>

	<!-- Date & Time-->
	<ng-container *ngSwitchCase="filterFieldType.Time">
		<ng-container
			*ngTemplateOutlet="dateAndDatetime; context:{ $implicit: true, setupData: dtContext.TIME}"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="filterFieldType.Date">
		<ng-container
			*ngTemplateOutlet="dateAndDatetime; context:{ $implicit: true, setupData: dtContext.DATE}"></ng-container>
	</ng-container>

	<!--True context signifies it's a range
	{isRange: true, showTime: true}-->
	<ng-container *ngSwitchCase="filterFieldType.DateTime">
		<ng-container
				*ngTemplateOutlet="dateAndDatetime; context:{ $implicit: false, setupData: dtContext.DATETIME}"></ng-container>
	</ng-container>

	<!--<ng-container *ngSwitchCase="SearchFieldType.DateTime">
		<ng-container
				*ngTemplateOutlet="dateAndDatetime; context:{ $implicit: true, setupData: dtContext.DATETIME}"></ng-container>
	</ng-container>-->

	<ng-container *ngSwitchCase="filterFieldType.DateRange">
		<ng-container
			*ngTemplateOutlet="dateAndDatetime; context:{ $implicit: true, setupData: dtContext.DATE_RANGE}"></ng-container>
	</ng-container>

	<!--True context signifies it's a range
	{isRange: true, showTime: true}-->
	<ng-container *ngSwitchCase="filterFieldType.DateTimeRange">
		<ng-container
			*ngTemplateOutlet="dateAndDatetime; context:{ $implicit: true, setupData: dtContext.DATETIME_RANGE}"></ng-container>
	</ng-container>

	<!-- Number -->
	<!--<ng-container *ngSwitchCase="SearchFieldType.Number">
		<ng-container
				*ngTemplateOutlet="dateAndDatetime; context:{ $implicit: false}"></ng-container>
	</ng-container>-->

	<ng-container *ngSwitchCase="filterFieldType.Number">
		<ng-container
			*ngTemplateOutlet="numberAndNumberRange; context:{ $implicit: false}"></ng-container>
	</ng-container>

	<!-- List -->
	<ng-container *ngSwitchCase="filterFieldType.SearchableMultiSelectionList">
		<ng-container *ngTemplateOutlet="multiSelectionList"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="filterFieldType.AdvancedSearchableMultiSelectionList">
		<!--!searchField.listOptions -->
		<p-multiSelect [filterValue]="filterField.listSearchValue"
			[options]="filterField.listOptions" placeholder="Choose multiple" appendTo="body"
					   [style]="{'width': '100%'}" [ngModel]="filterField.searchValue"
					   [disabled]="!filterField.listOptions || filterField.listOptions.length === 0"
					   [virtualScroll]="true" [virtualScrollItemSize]="36"
					   (ngModelChange)="onValueChange($event, filterField)"
					   (onFilter)="onListSearchEvent($event, filterField)"
					   [lazy]="true" (onLazyLoad)="onLazyLoadEvent($event, filterField)"></p-multiSelect>
	</ng-container>

	<ng-template #multiSelectionList>
		<p-multiSelect [options]="filterField.listOptions" [resetFilterOnHide]="true" appendTo="body"
					   [style]="{'width': '100%'}" [ngModel]="filterField.searchValue"
					   [disabled]="!filterField.listOptions"
					   placeholder="Choose multiple" [virtualScroll]="true" [virtualScrollItemSize]="36"
					   (ngModelChange)="onValueChange($event, filterField)"></p-multiSelect>
	</ng-template>

	<ng-container *ngSwitchCase="filterFieldType.SearchableSelectionList">
		<ng-container *ngTemplateOutlet="selectionList"></ng-container>
	</ng-container>

	<ng-container *ngSwitchCase="filterFieldType.AdvancedSearchableSelectionList">
		<ng-container *ngTemplateOutlet="selectionList"></ng-container>
	</ng-container>

	<ng-template #selectionList>
		<p-dropdown [options]="filterField.listOptions" [ngModel]="filterField.searchValue" [filter]="true"
					placeholder="Select an option" appendTo="body" [disabled]="!filterField.listOptions"
					(ngModelChange)="onValueChange($event, filterField)"></p-dropdown>
	</ng-template>

	<ng-template #dateAndDatetime let-option="setupData">
		<span class="p-float-label">
			<!-- Setting filterField.searchValue in ngModelChange and not [ngModel]="filterField.searchValue",
			 as the later does not lend itself to p-calender string format for API-->
			<p-calendar dataType="string" [inputId]="input2ID" [selectionMode]="option['isRange'] ? 'range' : 'single'"
						[showTime]="option['showTime']" [showSeconds]="filterField.showSeconds"
						[ngModel]="searchValueTemp" appendTo="body" [timeOnly]="filterField.timeOnly"
						[placeholder]="calendarPlaceholder(filterField.type)"
						(ngModelChange)="onValueChange($event, filterField)" dateFormat="dd/mm/yy">
			</p-calendar>
		</span>
	</ng-template>

	<!--Impl the specific details when a sample filter is provided by API, as atm it is not certain whether
	numberRangeMinValue, minNumberRange, etc. will always be provided...
	the other alternative is to use a p-inputNumber for from and another for to-->
	<ng-template #numberAndNumberRange let-isRange>
        <span class="p-float-label" *ngIf="!isRange">
            <p-inputNumber [inputId]="inputID" [ngModel]="setDefaultValue(filterField)"
						   [useGrouping]="false" mode="decimal" maxFractionDigits="7"
						   (ngModelChange)="onValueChange($event, filterField)" [showButtons]="true">
            </p-inputNumber>
            <label for="{{ inputID }}">Value - Number</label>
        </span>

		<div *ngIf="isRange" class="number-range-wrapper">
				<span class="number-range-value">
					<span class="min">{{ numberRangeMinValue }}</span>
					<span class="max">{{ numberRangeMaxValue }}</span>
				</span>

			<p-slider [min]="filterField.minNumberRange" [max]="filterField.maxNumberRange" [range]="true"
					  [style]="{'width': '100%'}"
					  [ngModel]="filterField.searchValue"
					  (ngModelChange)="onValueChange($event, filterField)"></p-slider>
		</div>
	</ng-template>
</div>

<!--<button class="helio-orange-btn" pButton (click)="delete()" icon="ui-icon-delete" title="Delete Search Field"
		style="margin: 6px 6px 6px 28px;" [attr.he-label]="searchField.label"></button>-->
