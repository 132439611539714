<ng-container *ngFor="let item of menuItems; index as i">
		<li [ngClass]="{'active-menuitem': isActive(i)}" [class]="item.badgeStyleClass" *heShowNavItem="item.routeName">
			<ng-container *ngIf="item.routerLink; else noRouterLink">
				<a (click)="itemClick($event, item, i)" [routerLink]="item.routerLink" draggable="false"
				   routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}"
				   (mouseenter)="hover=true" (mouseleave)="hover=false" class="ripplelink"
				   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="item.target"
				   [attr.data-cy]="item.routeName">
					<ng-container *ngIf="false; else anchorChildren"></ng-container>
				</a>
			</ng-container>

			<ul app-submenu [menuItems]="item.items" *ngIf="item.items" [visible]="isActive(i)" [reset]="reset"
				[@children]="(isHorizontal) && menuItems ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
		</li>

		<!--region Templates-->

	    <!--When there is no route to go to, for example a parent slide menu holding child menu items-->
		<ng-template #noRouterLink>
			<a (click)="itemClick($event, item, i)" [href]="item.url||'#'" draggable="false"
			   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="item.target" [attr.data-cy]="item.routeName"
			   (mouseenter)="hover=true" (mouseleave)="hover=false" class="ripplelink">
				<ng-container *ngIf="false; else anchorChildren"></ng-container>
			</a>
		</ng-template>

		<ng-template #anchorChildren>
			<i *ngIf="isPrimeIcon(item.icon); else materialIcon" [ngClass]="['pi', item.icon]"
			   [attr.data-cy]="item.routeName"></i>
			<ng-template #materialIcon>
				<i class="material-icons" [attr.data-cy]="item.routeName">{{item.icon}}</i>
			</ng-template>

			<span class="menuitem-text">{{item.label}}</span>
			<i class="material-icons layout-submenu-toggler" *ngIf="item.items">keyboard_arrow_down</i>
			<span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
		</ng-template>
		<!--endregion-->
</ng-container>
