// Global
export const FILTER_URL_PARAM = '$filter';
export const ORDER_BY_URL_PARAM = '$orderBy';
export const TAKE_URL_PARAM = '$take';
export const OFFSET_URL_PARAM = '$offset';
export const FORMAT_URL_PARAM = 'format';
export const STATUS_URL_PARAM = 'status';
export const CURRENCY_TYPE_PARAM = 'currencyType';

export const CSV_FORMAT = 'csv';
export const IS_VALIDATE_RES_FORMAT = 'validateResults';
export const TRANSACTION_TYPE = 'transactionType';

export const INCLUDE_CLOSED_ACCOUNTS = 'includeAccountClosed';

// WithdrawalRequest
export const KYC_VERIFIED_OR_NOT_REQ_PARAM = 'kycVerifiedOrNotRequired';

export const PASSWORD_PARAM = 'password';

//region Lookups

export const UNDEFINED_LOOKUP_TOAST = 'Some search options are missing, please try again shortly.';
export const SEARCH_OPT_NOT_SELECTED_TOAST = 'Please select a Search Field from the dropdown.';
export const FILTER_OPT_NOT_SELECTED_TOAST = 'Please select a Filter Field from the dropdown.';

//endregion


export const API_DATA_INVALID = 'HE-API-ERROR: Data delivered from API does not match expected signature.'
