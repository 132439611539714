import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Paginator, PaginatorState} from 'primeng/paginator';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {NotesUpdateBase, NotesUpdateRequest, PlayerNotesDTO} from '../../models/player/player-audit.model';
import {ComponentHelper} from '../../interfaces/component.helper';
import {PlayerNotesService} from '../../services/player-notes.service';
import {AppConfigService, BoErrorHandlerService, ConfirmDialogDisplayService, LoginService} from '../../../helio-core-services';
import {Confirmation, Message} from 'primeng/api';
import {formatDateWithLocale} from '../../utilities/general-utilities/string.utility';
import {getLang} from '../../utilities/general-utilities/html.utils';

@Component({
	selector: 'he-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends ComponentHelper implements OnInit, OnDestroy {

	@Input() loading = false;
	@Output() submitEvent = new EventEmitter<string>();
	@Output() editNoteEvent = new EventEmitter<NotesUpdateRequest>();
	@Output() deleteNoteEvent = new EventEmitter<NotesUpdateBase>();

	private routePlayerId: number;

	private locale: string;

	formGroup: FormGroup;

	first = 0;
	readonly numOfItemsPerLoad = 10; // Number of additional items per load
	private _take: number;

	totalRecords: number;
	data: PlayerNotesDTO[];

	private paginatorState: PaginatorState = {
		first: 0,
		page: 0,
		pageCount: 0,
		rows: 0,
	}

	historyRequest: NotesUpdateBase;

	private searchParams = new HttpParams();

	private getPlayerNotesSub$: Subscription;

	constructor(
		protected appConfigService: AppConfigService,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private confirmationDisplayService: ConfirmDialogDisplayService,
		private loginService: LoginService,
		private playerNotesService: PlayerNotesService,
		protected boErrorHandlerService: BoErrorHandlerService
	) {
		super();

		// this.searchParams = this.getSearchParams(this.numOfItemsPerLoad);
		this.locale = getLang();
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({
			newNote: new FormControl('', [Validators.min(1)])
		});

		this.route.paramMap.subscribe((params: ParamMap) => {
			this.routePlayerId = Number(params.get('id'));

			this.getPlayerNotes(this.numOfItemsPerLoad);
		});
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getPlayerNotesSub$);
	}

	private getSearchParams(take: number): HttpParams {
		return this.searchParams
			.set('take', take)
			.set('offset', 0)
			.set('playerID', this.routePlayerId)
			.set('orderBy', 'playerNoteID desc');
	}

	/**
	 * Extracts text content from all paragraph tags in an HTML string.
	 * @param html - The HTML string containing multiple paragraph tags.
	 * @returns The concatenated text content of all paragraphs.
	 */
	extractTextFromAllParagraphs(html: string): string {
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, 'text/html');
		const paragraphs = doc.querySelectorAll('p');

		if (paragraphs.length <= 0) {
			// return the original str if no paragraph match as this indicates it was already parsed.
			return html;
		}

		let textContent = '';

		paragraphs.forEach(p => {
			textContent += p.textContent + ' ';
		});

		return textContent.trim();
	}

	onSaveNote() {
		const note = this.formGroup.controls['newNote'].value;
		this.submitEvent.emit(this.extractTextFromAllParagraphs(note))
	}

	onEditPreviousNote(dto: PlayerNotesDTO) {
		dto.note = this.extractTextFromAllParagraphs(dto.note);
		this.editNoteEvent.emit({playerNoteID: dto.playerNoteID, note: dto.note});
	}

	onCancelEditPreviousNote(dto: PlayerNotesDTO) {
		dto.readonly = true;
		this._invokeGetPlayerNotes();
	}

	onDeletePreviousNote(dto: PlayerNotesDTO) {
		const confMessage: Confirmation = {
			message: `Please confirm you wish to delete the selected note.`,
			accept: () => {
				this.deleteNoteEvent.emit({playerNoteID: dto.playerNoteID});
			},
			reject: () => {
				// Do nothing
			}
		};

		this.confirmationDisplayService.showConfirmDialog(confMessage);
	}

	onShowPreviousNoteHistory(dto: PlayerNotesDTO) {
		this.historyRequest = {playerNoteID: dto.playerNoteID};
	}

	onPageChange(state: PaginatorState) {
		this.paginatorState = state;

		// i.e. for the first page = 0, take = (0 * 10) + 10
		// and the second page = 1, take = (1 * 10) + 20
		const take = (state.page * state.rows) + state.rows;

		// console.log('onPageChange: take = ', take);

		this.getPlayerNotes(take);
	}

	onLoadMoreNotes(paginator: Paginator) {
		// console.log('onLoadMoreNotes: ');
		const totalPages = Math.ceil(this.totalRecords / this.numOfItemsPerLoad);

		if ((this.first / this.numOfItemsPerLoad) < totalPages - 1) {
			this.first += this.numOfItemsPerLoad;
			paginator.changePage(this.first / this.numOfItemsPerLoad);
		}
	}

	formatDate(note: PlayerNotesDTO) {
		const date: Date = note?.updatedDate ?? note.createdDate;
		return formatDateWithLocale(date, this.locale);
	}

	isOriginalCreator(dto: PlayerNotesDTO): boolean {
		return this.loginService.loggedInUserID === dto.userID.toString();
	}

	/**
	 * Intended for use only with @ViewChild, for reloading PreviousNotes section data after an add event.
	 */
	public _invokeGetPlayerNotes() {
		this.getPlayerNotes(this._take);
	}

	public _clearEditor(): void {
		this.formGroup.controls['newNote'].setValue('');
		this.formGroup.controls['newNote'].updateValueAndValidity();
	}

	private getMessage(detail: string, severity: 'success' | 'info' | 'warning' | 'danger')
		: Message[] | undefined {
		return [
			{severity, summary: '', detail}
		];
	}

	private getPlayerNotes(take: number): void {
		this._take = take;
		this.searchParams = this.getSearchParams(this._take);
		this.loading = true;

		this.getPlayerNotesSub$ = this.playerNotesService.getNotes(this.searchParams).subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;
				// this.offset = res.offset;

				this.loading = false;
			},
			error: error => {
				// const msg = this.appConfigService.genericErrorMessage;
				// this.previousNotesMessage = this.getMessage(msg, 'danger');

				this.boErrorHandlerService.handleError(error, error.description);
				this.loading = false;
			}
		});
	}

	onEditPreviousNoteClicked(dto: PlayerNotesDTO) {
		// Explicitly set to false as cancel should only now be via the cancel action btn.
		// ensures that clicking multiple times does not negate this desired behaviour
		dto.readonly = false;
	}
}
