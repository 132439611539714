import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[heComponentHost]'
})
export class ComponentHostDirective {
	constructor(
		public viewContainerRef: ViewContainerRef
	) { }
}
