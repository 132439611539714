import {Injectable} from '@angular/core';
import {AppConfigService, BaseService} from '../../helio-core-services';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ServiceController} from '../utilities';
import {Observable} from 'rxjs';
import {TableDataResponse} from '../components';
import {
	ChangeTrail,
	NotesUpdateBase,
	NotesUpdateRequest,
	PlayerNotesChangeTrailDTO,
	PlayerNotesDTO
} from '../models/player/player-audit.model';
import {ResponseBodyType} from '../enums';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PlayerNotesService extends BaseService {

	constructor(
		protected http: HttpClient,
		protected appConfigService: AppConfigService,) {
		super(http, ServiceController.PLAYER_NOTES, appConfigService.serviceBaseURL);
	}

	addNote(playerID: number, note: string): Observable<any> {
		return this.post(undefined, {playerID, note}, undefined, undefined);
	}

	editNote(request: NotesUpdateRequest): Observable<any> {
		return this.edit(undefined, request, undefined, undefined);
	}

	deleteNote(request: NotesUpdateBase): Observable<any> {
		const params: HttpParams = new HttpParams()
			.set('playerNoteID', request.playerNoteID);

		return this.delete(undefined, undefined, params);
	}

	getNotes(searchParams: HttpParams): Observable<TableDataResponse<PlayerNotesDTO>> {
		return this.validateDataTableRes(
			['playerNoteID', 'author', 'note'], undefined,
			undefined, searchParams, ResponseBodyType.JSON
		).pipe(
			map(res => {
				res.resultSet = res.resultSet.map((entry: PlayerNotesDTO) => {
					// Parse Date object
					entry.createdDate = new Date(entry.createdDate);
					entry.updatedDate = entry.updatedDate ? new Date(entry.updatedDate) : undefined;

					// default to true so UI can use this to initially disable previous notes
					entry.readonly = true;

					return entry;
				});

				return res as TableDataResponse<PlayerNotesDTO>;
			}));
	}

	getNoteHistory(request: NotesUpdateBase): Observable<PlayerNotesChangeTrailDTO> {
		return this.get(undefined, [request.playerNoteID], undefined)
			.pipe(
				map((dto: PlayerNotesChangeTrailDTO) => {
					// Parse Date object
					dto.createdDate = dto.createdDate ? new Date(dto.createdDate) : undefined;

					dto.changeTrails = dto.changeTrails.map((entry: ChangeTrail) => {
						// Parse Date object
						entry.updatedOn = entry.updatedOn ? new Date(entry.updatedOn) : undefined;

						return entry;
					});

					return dto as PlayerNotesChangeTrailDTO;
				}));
	}
}
