<he-base-dialog header="{{dialogTitle}}" [(visible)]="visible" [isLoadingLookups]="isLoadingLookups"
				[style]="{'max-width': '550px', width: '550px'}" (visibleChange)="onCloseDialog()"
				[rejectBtnOnly]="!forCRUD"
				(submitEvent)="onSubmit()" [disableSubmit]="!form.valid">

	<!--<div id="mode-toggle-wrapper" *ngIf="forCreate || (bankDetails && editable && canUpdateBankDetails)">
		<span id="mode-text" [attr.view-mode]="isViewMode">{{isViewMode ? 'edit' : 'view'}}</span>
		<p-inputSwitch [(ngModel)]="isViewMode" (onChange)="onModeToggle($event)"></p-inputSwitch>
	</div>-->

	<form [formGroup]="form" class="dialog-form-placement" autocomplete="off">
		<ng-container *ngIf="bankDetails?.withdrawalMethod">
			<label>Withdrawal Method</label>
			<div style="padding: 0 12px;">{{bankDetails?.withdrawalMethod ?? ''}}</div>
		</ng-container>

		<label for="accHolderName">Beneficiary Name</label>
		<input pInputText id="accHolderName" formControlName="accHolderName" autocomplete="off" type="text"/>

		<label for="nationalIdNum">National ID No.</label>
		<input pInputText id="nationalIdNum" formControlName="nationalIdNum" autocomplete="off" type="text"/>

		<!--<label for="placeOfBirth">Place Of Birth</label>
		<input pInputText id="placeOfBirth" formControlName="placeOfBirth" autocomplete="off" type="text"/>

		<label for="countryOfBirth">{{'Country Of Birth'}}</label>
		<p-dropdown id="countryOfBirth" [options]="allCountries" appendTo="body"
					formControlName="countryOfBirth" placeholder="Please select a country">
		</p-dropdown>-->

		<label for="bankName">Bank Name</label>
		<input pInputText id="bankName" formControlName="bankName" autocomplete="off" type="text"/>

		<label for="bankBranch">Bank Branch</label>
		<input pInputText id="bankBranch" formControlName="bankBranch" autocomplete="off" type="text"/>

		<label for="bankCity">City</label>
		<input pInputText id="bankCity" formControlName="bankCity" autocomplete="off" type="text"/>

		<label for="bankState">State</label>
		<input pInputText id="bankState" formControlName="bankState" autocomplete="off" type="text"/>

		<label>{{'Country'}}</label>
		<p-dropdown [inputId]="'bankBranchCountry'" [options]="unsanctionedCountries" appendTo="body"
					formControlName="bankBranchCountry" placeholder="Please select a country">
		</p-dropdown>

		<label for="bankAccNumber">Account Number</label>
		<!--allowEmpty="false"-->
		<p-inputNumber id="bankAccNumber" formControlName="bankAccNumber" [useGrouping]="false"/>

		<label for="iban">IBAN</label>
		<input pInputText id="iban" formControlName="iban" autocomplete="off" type="text"/>

		<label for="swiftCode">SWIFT Code</label>
		<input pInputText id="swiftCode" formControlName="swiftCode" autocomplete="off" type="text"/>

		<label for="routingNum">Routing No.</label>
		<p-inputNumber id="routingNum" formControlName="routingNum" [useGrouping]="false"/>

		<label for="ifsc">IFSC</label>
		<input pInputText id="ifsc" formControlName="ifsc" autocomplete="off" type="text"/>
	</form>
</he-base-dialog>
