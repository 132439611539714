<!--[description]="'Please choose a PSP'"-->
<he-base-dialog header="{{'Manually Assign PSP'}}" [(visible)]="visible" [isLoadingLookups]="isLoadingLookups"
				[style]="{'max-width': '550px', width: '550px'}" (visibleChange)="onCloseDialog()"
				submitLabel="Assign" (submitEvent)="onSubmit()" [disableSubmit]="!form.valid">

	<form [formGroup]="form" class="dialog-form-placement" autocomplete="off">
		<label>{{'PSP'}}</label>
		<p-dropdown [options]="pspLookup" formControlName="selectedPsp" appendTo="body"
					placeholder="Please select a PSP">
		</p-dropdown>
	</form>
</he-base-dialog>
