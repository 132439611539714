import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of, Subscription} from 'rxjs';
import {catchError, delay, map} from 'rxjs/operators';
import {BaseService} from './base.service';
import {AppConfigService} from './app-config.service';
import {WindowEventsService} from './window-events.service';
import {ServiceController} from '../../shared/utilities/service-utilities/service-controller.urls';
import {Login, LogoutData, LogoutRes} from '../../shared/models/user/login.model';
import {HeaderContentType, ServiceHeadersConfig} from '../../shared/utilities/service-utilities/service-headers.config';
import {Buffer} from 'buffer';
import {ToastMessageType} from '../../shared';
import {ToastDisplayService} from './toast-display.service';
import {environment} from '../../../environments/environment';
import {CONST_LOCAL_STORAGE} from '../../shared/constants/constants';

/**
 * Service class used to handle HTTP requests related to Authentication
 */
@Injectable()
export class LoginService extends BaseService {
	tokenExpiresDate: Date;

	isUserActive = false;

	inactiveTimer: number;

	// private _isLoggedIn = false;
	private sessionExpiredHandlerSubscription: Subscription;

	/**
	 * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
	 */
	constructor(
		protected http: HttpClient,
		private appConfigService: AppConfigService,
		private windowEventsService: WindowEventsService,
		private router: Router,
		private toastDisplayService: ToastDisplayService
	) {
		super(http, ServiceController.LOGIN,
			appConfigService.authBaseURL,
			new ServiceHeadersConfig(true, HeaderContentType.URL_ENCODED));

		this.setUserActiveListener();
	}

	/**
	 * Checks if the initial auth token_expires_date has elapsed.
	 * In other words, if the token is still valid.
	 */
	get isLoggedInAndTokenValid(): boolean {
		if (sessionStorage.getItem('access_token') !== null) {
			const now = new Date();
			this.tokenExpiresDate = new Date(sessionStorage.getItem('token_expires_date'));

			if (now < this.tokenExpiresDate) {
				sessionStorage.setItem('log_status', 'true');
				return true;
			}
		}

		sessionStorage.setItem('log_status', 'false');
		return false;
	}

	get loggedInUserID(): string | null {
		if (sessionStorage.getItem('access_token') !== null) {
			return sessionStorage.getItem('logged_in_userID')
		}

		return null;
	}

	loginUser(loginData: Login): Observable<void> {
		loginData.client_id = this.appConfigService.tokenClientID;

		return this.post(undefined, loginData)
			.pipe(
				map(res => {
					return this.saveSessionData(res);
				})
			);
	}

	get canRefreshToken(): boolean {
		const refreshToken = sessionStorage.getItem('refresh_token');
		if (refreshToken !== null) {
			const now = new Date();
			const refreshTokenExpires = new Date(sessionStorage.getItem('refresh_token_expires_date'));

			return (now < refreshTokenExpires);
		}

		return false;
	}

	refreshUserToken(autoLogout = true) {
		const refreshToken = sessionStorage.getItem('refresh_token');

		if (!refreshToken) {
			return of();
		}

		const data = {
			refresh_token: refreshToken,
			grant_type: 'refresh_token',
			client_id: this.appConfigService.tokenClientID
		};

		return this.post(undefined, data)
			.pipe(
				map(res => {
					return this.saveSessionData(res);
				}),
				catchError(() => {
					if (autoLogout) {
						this.logoutAndRedirectToLogin(true);
					}
					return of(null);
				})
			);
	}

	/**
	 * Clears data stored locally to log out
	 */
	logOut(): Promise<boolean> {
		return new Promise((resolve) => {
			// ('isUserActiveHandler: logOut called');

			// Stop refresh of token
			this.unsubscribeSessionExpiredHandler();

			const logoutData: LogoutData = {
				refresh_token: sessionStorage.getItem('refresh_token'),
				grant_type: 'refresh_token',
				client_id: this.appConfigService.tokenClientID,
				action_type: 'Logout'
			};

			const handleRes = (res: any) => {
				// Clean up local storage
				sessionStorage.setItem('log_status', 'false');

				sessionStorage.removeItem('access_token');
				sessionStorage.removeItem('token_type');
				sessionStorage.removeItem('access_rights');
				sessionStorage.removeItem(CONST_LOCAL_STORAGE.ACTIONS);
				sessionStorage.removeItem(CONST_LOCAL_STORAGE.PAGES);
				sessionStorage.removeItem(CONST_LOCAL_STORAGE.PAGES_FLATTEN);
				sessionStorage.removeItem(CONST_LOCAL_STORAGE.REPORTS);
				sessionStorage.removeItem('logged_in_userID');
				sessionStorage.removeItem('expires_in');
				sessionStorage.removeItem('refresh_token');
				sessionStorage.removeItem('token_expires_date');
				sessionStorage.removeItem('refresh_token_expires_date');
				sessionStorage.removeItem('requires_pass_change');
				sessionStorage.removeItem('is_user_active');
				sessionStorage.removeItem('log_status');
				sessionStorage.removeItem('user_name');

				if (!res.IsSuccess) {
					if (environment.env === 'dev-server') {
						this.toastDisplayService.addMessage({
							title: 'Error',
							description: `Logout method failed.`,
							type: ToastMessageType.error
						});
					}

					resolve(false);
				} else {
					resolve(true);
				}
			}

			this.post(undefined, logoutData).subscribe({
				next: (res: LogoutRes) => {
					handleRes(res);
				},
				error: err => {
					handleRes(err);
				}
			});
		});
	}

	/**
	 * Function to save the user data in the sessionStorage after a successful login
	 */
	saveSessionData(data: any): void {
		sessionStorage.setItem('log_status', 'true');

		sessionStorage.setItem('access_token', data.access_token);
		sessionStorage.setItem('token_type', data.token_type);
		sessionStorage.setItem('expires_in', data.expires_in);
		sessionStorage.setItem('refresh_token', data.refresh_token);

		const tokenExpires = new Date();
		tokenExpires.setSeconds(tokenExpires.getSeconds() + data.expires_in);
		sessionStorage.setItem('token_expires_date', tokenExpires.toISOString());

		this.tokenExpiresDate = new Date(tokenExpires.toISOString());

		const refreshTokenExpires = new Date(data['.expires']);
		sessionStorage.setItem('refresh_token_expires_date', refreshTokenExpires.toISOString());

		// Get the access rights from token
		const encodedString = data.access_token.split('.')[1];
		// Decode it (base64)...
		const decodedString = Buffer.from(encodedString, 'base64').toString();
		// ...and store them locally
		sessionStorage.setItem('access_rights', decodedString);
		sessionStorage.setItem('logged_in_userID', JSON.parse(decodedString).Id);
		sessionStorage.setItem('requires_pass_change', JSON.parse(decodedString).RequiresPasswordChange);
	}

	logoutAndRedirectToLogin(isSessionExpired: boolean = false) {
		// get url without query params
		// ref: https://stackoverflow.com/a/46107850
		const urlTree = this.router.parseUrl(this.router.url);

		const key = 'primary';
		const rootChildren = urlTree.root.children[key];

		let urlWithoutParams: string;

		if (rootChildren !== undefined) {
			urlWithoutParams = urlTree.root.children[key].segments.map(it => it.path).join('/');
		}

		if (urlWithoutParams !== 'login') {
			const queryParams = (isSessionExpired) ?
				{queryParams: {'sessionExpired': true, 'redirectURL': this.router.url}} : undefined;

			this.logOut();
			this.router.navigate(['/login'], queryParams);
		}
	}

	/**
	 * Delay the request of a new token by the amount of time specified by {@link tokenExpiresDate},
	 * {@link #unsubscribeSessionExpiredHandler} can later be used to unsubscribe before the map operator is triggered.
	 */
	private sessionExpiredHandler() {
		return of(true)
			.pipe(
				delay(this.tokenExpiresDate),
				map(() => {
					if (this.isUserActive) {
						this.refreshUserToken().subscribe(() => {
							this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
						});
					} else {
						this.logoutAndRedirectToLogin(true);
					}
				})
			);
	}

	private clearInactivityManagerTimer() {
		if (this.inactiveTimer) {
			clearTimeout(this.inactiveTimer);
		}
	}

	private setInactivityManagerTimer() {
		this.clearInactivityManagerTimer();

		if (this.isLoggedInAndTokenValid) {
			// ('resetInactivityStateHandler: resetting')

			this.inactiveTimer = window.setTimeout(() => {
				// ('resetInactivityStateHandler: logging out');
				this.logoutAndRedirectToLogin(true);
				this.clearInactivityManagerTimer();

			}, this.appConfigService.inactiveTimeLimit);
		}
	}

	private setUserActiveListener() {
		// Only actively attempt to refresh token if focus is had before #clearInactivityManagerTimer goes off
		this.windowEventsService.onFocus
			.subscribe(() => {
				this.isUserActive = true;
				sessionStorage.setItem('is_user_active', 'true');

				if (this.isLoggedInAndTokenValid) { // if token is valid (i.e. not expired)
					if (this.sessionExpiredHandlerSubscription === undefined) {
						this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
					}
				} else {
					const canRefreshToken = this.canRefreshToken;

					if (canRefreshToken) { // if refreshToken is valid, attempt ot get new session token
						this.refreshUserToken().subscribe(() => {
							this.unsubscribeSessionExpiredHandler();

							this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
						});
					} else {
						this.logoutAndRedirectToLogin(true);
					}
				}
			});

		this.windowEventsService.onMouseDown
			.subscribe(() => {
				this.clearInactivityManagerTimer();
			});

		this.windowEventsService.onKeyDown // Targets touchscreen devices like mobile and tablets
			.subscribe(() => {
				this.clearInactivityManagerTimer()
			});

		this.windowEventsService.onBlur
			.subscribe(() => {
			this.isUserActive = false;
			sessionStorage.setItem('is_user_active', 'false');

			// unsubscribe to avoid the scenario of inadvertently requesting a new token for an inactive user
			this.unsubscribeSessionExpiredHandler();
		});

		this.windowEventsService.onMouseUp
			.subscribe(() => {
				this.setInactivityManagerTimer();
			});

		this.windowEventsService.onKeyUp // Targets touchscreen devices like mobile and tablets
			.subscribe(() => {
				this.setInactivityManagerTimer();
			});
	}

	private unsubscribeSessionExpiredHandler() {
		if (this.sessionExpiredHandlerSubscription !== undefined) {
			this.sessionExpiredHandlerSubscription.unsubscribe();
		}
	}
}
