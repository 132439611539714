<div *ngIf="!isTabbedTable" class="header-opts">
	<he-header headerTitle="{{tabbedTitle}}"></he-header>

	<he-target-data-selector [loading]="loading" [(selectedItem)]="selectedTargetData" [options]="targetDataLookup"
							 (selectedItemChange)="onSelectedTargetDataChange()"></he-target-data-selector>
</div>

<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data" [selectedRows]="selectedTransactions"
				  [lazy]="true" (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords" [offset]="offset"
				  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields"
				  [loading]="loading" [canExportAll]="false"
				  (exportToCsvRequested)="exportToCSV(null, false)"
				  [headerTitle]="null"
				  [isTabbedTable]="isTabbedTable" [tabbedTitle]="tabbedTitle" [skeletonOverheadOptRight]="isTabbedTable"
				  [showNestedOverheadOpts]="isTabbedTable">
	<div *ngIf="isTabbedTable" class="he-specific-table-actions">
		<he-target-data-selector class="wallet-selector" [loading]="loading" [(selectedItem)]="selectedTargetData"
								 [options]="targetDataLookup" skeletonWidth="120px"
								 (selectedItemChange)="onSelectedTargetDataChange()"></he-target-data-selector>

		<button class="helio-orange-btn" pButton type="button" (click)="onShowAdjustWalletDialog()"
				[label]="btnLabel.ADJUST" [title]="btnLabel.ADJUST" icon="pi pi-sliders-h"
				[disabled]="(showAdjustWalletDialog === true) || !hasAdjustWalletBalPermission"></button>

		<button class="helio-orange-btn" pButton type="button" icon="pi pi-credit-card"
				(click)="showCardsDialog = true;" [label]="btnLabel.CARDS" [title]="btnLabel.CARDS"></button>

		<button class="helio-orange-btn" pButton type="button" icon="pi pi-id-card"
				(click)="onShowBankDetailsDialog()"
				[label]="btnLabel.BANK_DETAILS" [title]="btnLabel.BANK_DETAILS"></button>

		<button class="helio-orange-btn" pButton type="button" icon="pi pi-id-card"
				(click)="onShowExchangeHouseDialog()"
				[label]="btnLabel.EXCHANGE_HOUSE_DETAILS" [title]="btnLabel.EXCHANGE_HOUSE_DETAILS"></button>

		<button class="helio-orange-btn" pButton type="button" [icon]="ADJUST_ICON_TAG"
				(click)="this.showAssignPspDialog = true"
				[label]="btnLabel.PSP" [title]="btnLabel.PSP" [disabled]="playerPspRoute === null"></button>
	</div>
</he-data-table-v3>

<!--Using if ensures the form is empty when init + it also ensures its destruction after use-->
<he-wallet-manual-adjust-dialog *ngIf="showAdjustWalletDialog" [balance]="walletBalance"
								[(visible)]="showAdjustWalletDialog" (submitEvent)="onAdjustWallet($event)"
								style="position: absolute" (closeEvent)="showAdjustWalletDialog = false">
</he-wallet-manual-adjust-dialog>

<!--Use of *ngIf here is also particularly important, since removal of the temp (class) flag used to
 elevate the cog opts relies on this -->
<he-bank-cards-dialog *ngIf="showCardsDialog" [(visible)]="showCardsDialog" style="position: absolute"
					  (closeEvent)="showCardsDialog = false"></he-bank-cards-dialog>

<he-bank-details-dialog *ngIf="showBankDetailsDialog" [(visible)]="showBankDetailsDialog"
						style="position: absolute"[playerPartialData]="this.playerPartialData"
						(closeEvent)="showBankDetailsDialog = false"></he-bank-details-dialog>

<he-exchange-house-details-dialog *ngIf="showExchangeHouseDialog" [(visible)]="showExchangeHouseDialog"
								  style="position: absolute" [playerPartialData]="playerPartialData"
								  (closeEvent)="showExchangeHouseDialog = false"></he-exchange-house-details-dialog>

<he-assign-psp-dialog *ngIf="showAssignPspDialog" [(visible)]="showAssignPspDialog" style="position: absolute"
					  [psp]="playerPspRoute" [playerPartialData]="this.playerPartialData"
					  (closeEvent)="showAssignPspDialog = false" (submitEvent)="onAssignPspRequest($event)">
</he-assign-psp-dialog>
