import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CalendarModule} from 'primeng/calendar';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {KeyFilterModule} from 'primeng/keyfilter';
import {MenuModule} from 'primeng/menu';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SpinnerModule} from 'primeng/spinner';
import {SliderModule} from 'primeng/slider';
import {TooltipModule} from 'primeng/tooltip';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';

import {
	HeaderComponent,
	FormMessagesComponent, AdvancedSearchComponent, AdvancedSearchFieldComponent,
	AdvancedSearchFieldHostDirective, DataTableV3Component, ColumnDataComponent,
	ColumnDataPipe
} from './components';

import {CharsDirective} from './directives';
import {CountdownToPipe} from './pipes';
import {TogglePasswordVisibilityDirective} from './directives/passwordReveal.directive';
import {ReplaceInvalidWithDirective} from './directives/replace-invalid-with.directive';
import {InputNumberModule} from 'primeng/inputnumber';
import {FindComponent} from './components/find/find/find.component';
import {
	TopUpHistoryTabComponent
} from '../retail-agent/agents/individual-agent/individual-agent-tabs/top-up-history/top-up-history-tab.component';
import {BaseDialogComponent} from './components/dialog/base-dialog/base-dialog.component';
import {ChangeStatusDialogComponent} from './components/dialog/change-status-dialog';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {SkeletonTableComponent} from '../skeletons/table/skeleton-table.component';
import {SkeletonModule} from 'primeng/skeleton';
import {ChangeStatusDialogV2Component} from './components/dialog/change-status-dialog-v2';
import {SkeletonDrawCatalogueComponent} from '../skeletons/draws/skeleton-draw-catalogue/skeleton-draw-catalogue.component';
import {FormInputWrapperComponent} from './components/form-input-wrapper/form-input-wrapper.component';
import {SkeletonEntityDetailsComponent} from '../skeletons/entity-details/entity-details/skeleton-entity-details.component';
import {SkeletonFormComponent} from '../skeletons/form/skeleton-form/skeleton-form.component';
import {FilterSearchComponent} from './components/data-table-v3/filter-search/filter-search.component';
import {FilterSearchV2Component} from './components/data-table-v3/filter-search-v2/filter-search-v2.component';
import {FilterComponentComponent} from './components/data-table-v3/filter-component/filter-component.component';
import {AssignPspDialogComponent} from './components/dialog/assign-psp-dialog/assign-psp-dialog.component';
import {BankCardsDialogComponent} from './components/dialog/bank-cards-dialog/bank-cards-dialog.component';
import {
	TransferDetailsEditorDialogComponent
} from './components/dialog/transfer-details-editor-dialog/transfer-details-editor-dialog.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {AdditionalInfoComponent} from './components/additional-info/additional-info.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {WalletTransactionComponent} from './components/table/wallet-transaction/wallet-transaction.component';
import {WalletManualAdjustDialogComponent} from './components/dialog/wallet-manual-adjust-dialog/wallet-manual-adjust-dialog.component';
import {RetailAgentEditorDialogComponent} from './components/dialog/retail-agent-editor-dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {TargetDataSelectorComponent} from './components/target-data-selector/target-data-selector.component';
import {BankDetailsDialogComponent} from './components/dialog/bank-details-dialog/bank-details-dialog.component';
import {
	ExchangeHouseDetailsDialogComponent
} from './components/dialog/exchange-house-details-dialog/exchange-house-details-dialog.component';
import {ExchangeHouseEditorDialogComponent}
	from './components/dialog/exchange-house-editor-dialog/exchange-house-editor-dialog.component';
import {BankDetailsEditorDialogComponent} from './components/dialog/bank-details-editor-dialog/bank-details-editor-dialog.component';
import {PlayerAuditComponent} from './components/table/player-audit/player-audit.component';
import {NotesComponent} from './components/notes/notes.component';
import {EditorModule} from 'primeng/editor';
import {PaginatorModule} from 'primeng/paginator';
import { UpdateHistoryDialogComponent } from './components/update-history-dialog/update-history-dialog.component';
import {CardModule} from 'primeng/card';
import {AddRestrictionDialogComponent} from './components/dialog/add-restriction-dialog/add-restriction-dialog.component';
import {RetailAgentService} from './services/retail-agent.service';

@NgModule({
	declarations: [
		AdditionalInfoComponent,
		WalletTransactionComponent,

		/* ==== Data Table ==== */
		DataTableV3Component,
		ColumnDataComponent,
		ColumnDataPipe,
		AdvancedSearchComponent,
		AdvancedSearchFieldComponent,
		AdvancedSearchFieldHostDirective,
		/* ==== Data Table ==== */

		ChangeStatusDialogComponent,
		ChangeStatusDialogV2Component,
		FormMessagesComponent,
		HeaderComponent,

		CharsDirective,
		CountdownToPipe,
		TogglePasswordVisibilityDirective,
		ReplaceInvalidWithDirective,
		FindComponent,
		FindComponent,
		TopUpHistoryTabComponent,

		SkeletonTableComponent,
		SkeletonDrawCatalogueComponent,
		SkeletonEntityDetailsComponent,
		SkeletonFormComponent,
		FormInputWrapperComponent,
		FilterSearchComponent,
		FilterSearchV2Component,
		FilterComponentComponent,
		TargetDataSelectorComponent,

		BaseDialogComponent,
		AssignPspDialogComponent,
		BankCardsDialogComponent,
		TransferDetailsEditorDialogComponent,
		WalletManualAdjustDialogComponent,
		RetailAgentEditorDialogComponent,
		BankDetailsDialogComponent,
		ExchangeHouseDetailsDialogComponent,
		ExchangeHouseEditorDialogComponent,
		BankDetailsEditorDialogComponent,
		PlayerAuditComponent,
		NotesComponent,
		UpdateHistoryDialogComponent,
		BankDetailsEditorDialogComponent,
		AddRestrictionDialogComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		InputSwitchModule,
		CheckboxModule,
		RadioButtonModule,

		/* ==== PrimeNG ==== */
		ButtonModule,
		CalendarModule,
		ConfirmDialogModule,
		DialogModule,
		DropdownModule,
		InputTextModule,
		KeyFilterModule,
		MenuModule,
		MessagesModule,
		MultiSelectModule,
		SelectButtonModule,
		SliderModule,
		SpinnerModule,
		TableModule,
		TooltipModule,
		TriStateCheckboxModule,
		InputNumberModule,
		InputTextareaModule,
		SkeletonModule,
		EditorModule,
		PaginatorModule,
		CardModule,
		/* ==== PrimeNG ==== */
	],
	exports: [
		InputSwitchModule,
		CheckboxModule,

		/* ==== New Data Table ==== */
		DataTableV3Component,
		AdvancedSearchComponent,
		/* ==== New Data Table ==== */

		ChangeStatusDialogComponent,
		ChangeStatusDialogV2Component,
		FormMessagesComponent,
		HeaderComponent,

		CharsDirective,
		TogglePasswordVisibilityDirective,
		CountdownToPipe,
		ColumnDataComponent,
		CountdownToPipe,
		ReplaceInvalidWithDirective,
		FindComponent,
		FindComponent,
		TopUpHistoryTabComponent,

		SkeletonTableComponent,
		SkeletonDrawCatalogueComponent,
		SkeletonEntityDetailsComponent,
		SkeletonFormComponent,
		FormInputWrapperComponent,
		FilterSearchComponent,
		FilterSearchV2Component,
		AdditionalInfoComponent,
		WalletTransactionComponent,
		TargetDataSelectorComponent,

		BaseDialogComponent,
		AssignPspDialogComponent,
		BankCardsDialogComponent,
		TransferDetailsEditorDialogComponent,
		WalletManualAdjustDialogComponent,
		RetailAgentEditorDialogComponent,
		PlayerAuditComponent,
		NotesComponent,
		RetailAgentEditorDialogComponent,
		AddRestrictionDialogComponent
	],
	providers: [
		CurrencyPipe,
		DecimalPipe
	]
})

export class SharedModule {
}
