import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfigService, BaseService} from '../../helio-core-services';
import {FormatParams, ServiceAction, ServiceController} from '../utilities';
import {TableDataResponse} from '../components';
import {FallBackRequest, PaymentProvidersFallbackOrder} from '../models/finance/payment-providers-fallback.model';
import {UpdateResponse} from '../models/general/update-response';
import {DistributionRequest, PaymentProvidersDistribution, PspStateRequest} from '../models/finance/payment-providers-dist.model';
import {AssignPspRequest} from '../models/finance/psps.model';
import {BaseLookup} from '../interfaces/lookup-interfaces';
import {CSV_FORMAT, FORMAT_URL_PARAM, OFFSET_URL_PARAM, TAKE_URL_PARAM} from '../constants';
import {FALLBACK_ID, FALLBACK_NAME} from '../constants/ui-db-name-mappings';
import {ResponseBodyType} from '../enums';


@Injectable({
	providedIn: 'root'
})
export class PaymentProviderService extends BaseService {

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.FINANCIAL, appConfigService.serviceBaseURL);
	}

	//region Fallbacks

	getFallbacks(operatorID: number, searchParams?: HttpParams): Observable<TableDataResponse<PaymentProvidersFallbackOrder>> {
		return this.getPaymentProvidersHelper(operatorID, searchParams);

		// return of(dummyFallbacksData).pipe(delay(200));
	}

	getFallbacksCsv(operatorID: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		return this.getPaymentProvidersCsvHelper(operatorID, searchParams, xFormatParams);
	}

	updateFallbackOrder(operatorID: number, requestData: FallBackRequest): Observable<UpdateResponse> {
		return this.edit(undefined, requestData, undefined,
			[operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER, ServiceAction.PAYMENT_PROVIDERS_CHANGE_ORDER]
		);

		// return of({success: true}).pipe(delay(200));
	}

	//endregion

	//region Distributions
	getPercentDistributions(operatorID: number, searchParams?: HttpParams): Observable<TableDataResponse<PaymentProvidersDistribution>> {
		return this.getPaymentProvidersHelper(operatorID, searchParams);

		// return of(JSON.parse(JSON.stringify(dummyDistributionsData))).pipe(delay(200));
	}

	getPercentDistributionsCsv(operatorID: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		return this.getPaymentProvidersCsvHelper(operatorID, searchParams, xFormatParams);
	}

	updatePercentDistribution(operatorID: number, requestData: DistributionRequest): Observable<UpdateResponse> {
		return this.edit(undefined, requestData, undefined,
			[operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER, ServiceAction.PAYMENT_PROVIDERS_DISTRIBUTE]
		);
	}

	updateActivationState(operatorID: number, requestData: PspStateRequest): Observable<UpdateResponse> {
		return this.edit(undefined, requestData, undefined,
			[operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER, ServiceAction.PAYMENT_PROVIDERS_ACTIVATION]
		);
	}

	//endregion

	updatePlayerPaymentRoutes(playerID: number, requestData: AssignPspRequest): Observable<UpdateResponse> {
		return this.edit(undefined, requestData, undefined,
			[playerID, ServiceAction.PAYMENT_ROUTES], ServiceController.PAYMENTS
		);
	}

	getPaymentProvidersLookup(operatorID: number): Observable<BaseLookup[]> {
		const searchParams: HttpParams = new HttpParams()
			.set(TAKE_URL_PARAM, 250)
			.set(OFFSET_URL_PARAM, 0);

		return this.getPaymentProvidersHelper(operatorID, searchParams).pipe(
			map((res: TableDataResponse<any>) => {
				return res.resultSet.map(entry => {
					const temp: BaseLookup = {label: entry.name, value: entry.paymentServiceProviderID};
					return temp;
				})
			})
		);

		// return of(JSON.parse(JSON.stringify(dummyDistributionsData))).pipe(delay(200));
	}

	//region Helper methods
	getPaymentProvidersHelper(operatorID: number, searchParams?: HttpParams): Observable<TableDataResponse<any>> {
		return this.validateDataTableRes(
			[FALLBACK_ID.DB, FALLBACK_NAME.DB], undefined,
			[operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER], searchParams
		);
	}

	private getPaymentProvidersCsvHelper(operatorID: number, searchParams?: HttpParams, xFormatParams?: FormatParams[]): Observable<string> {
		const params = searchParams ? searchParams.set(FORMAT_URL_PARAM, CSV_FORMAT) : searchParams;
		return this.get(undefined, [operatorID, ServiceAction.PAYMENT_SERVICE_PROVIDER], params,
			ResponseBodyType.Text, undefined, xFormatParams);
	}

	//endregion
}
