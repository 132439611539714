import { DictionaryInterface } from '../models/dictionary';

/**
 * Class representing a dictionary object. Class implements the DictionaryInterface.
 */
export class Dictionary<T> implements DictionaryInterface<T> {
	/**
	 * Object containing the key and value paris
	 */
	private dictionary: { [key: string]: T };

	/**
	 * Integer containing the count of the dictionary
	 */
	private dictionaryCount: number;

	/**
	 * When creating a new Dictionary the dictionary is set to an empty object and dictionaryCount set to zero
	 * @param jsonObject An optional parameter allowing for a key-value pair JSON object to be used to create a dictionary
	 * using the JSON Object's keys and values
	 */
	constructor(jsonObject?: object) {
		this.dictionary = {};
		this.dictionaryCount = 0;

		if (jsonObject) {
			for (const key in jsonObject) {
				if (jsonObject.hasOwnProperty(key)) {
					this.add(key, jsonObject[key]);
					// this.dictionary[key] = jsonObject[key];
					// this.dictionaryCount++;
				}
			}
		}
	}

	/**
	 * Used to add a new key/value pair. Once a value is added the count is incremented by one
	 * @param key String or number representing the key
	 * @param value Generic value, could be a primative data type, Array, Object, etc.
	 */
	public add(key: string | number, value: T): void {
		this.dictionary[key] = value;
		this.dictionaryCount++;
	}

	/**
	 * Used to verify whether a key exists in the dictionary
	 * @param key String or number representing the key to be verified
	 * @return Boolean, true if found, false if not found
	 */
	public containsKey(key: string | number): boolean {
		return this.dictionary.hasOwnProperty(key);
	}

	/**
	 * Used to get the number of key/value pairs in the dictionary
	 * @return The total number of elements in the dictionary
	 */
	public count(): number {
		return this.dictionaryCount;
	}

	/**
	 * Used to retrieve the value of a particular key
	 * @param key String or number representing the key to get data from
	 * @return Returns the data in the element. If not found undefined is returned
	 */
	public item(key: string | number): T {
		return this.dictionary[key];
	}

	/**
	 * Used to return an array with the dictionary's keys
	 * @return Array of string or number with all the keys of the dictionary
	 */
	public keys(): string[] | number[] {
		// return Object.keys(this.dictionary);
		const keys: string[] = [];
		for (const key in this.dictionary) {
			if (this.dictionary.hasOwnProperty(key)) {
				keys.push(key);
			}
		}

		return keys;
	}

	/**
	 * Used to remove an element from the dictionary. When an element is delete the count is decremented by one.
	 * @param key String or number representing the key to delete data from
	 * @return The data in the deleted element
	 */
	public remove(key: string | number) {
		const deletedValue = this.dictionary[key];
		delete this.dictionary[key];
		this.dictionaryCount--;

		return deletedValue;
	}

	/**
	 * Used to retrieve all the values of the dictionary
	 * @return An array containing the values of all the elements in the dictionary
	 */
	public values(): T[] {
		// return Object.values(this.dictionary);
		const values: T[] = [];
		for (const key in this.dictionary) {
			if (this.dictionary.hasOwnProperty(key)) {
				values.push(this.dictionary[key]);
			}
		}

		return values;
	}
}
