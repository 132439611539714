<he-base-dialog header="{{'Note Change History'}}" [(visible)]="visible" [isLoadingLookups]="false"
				[style]="{'max-height': '80%', height: '900px', width: '100%'}" (visibleChange)="onCloseDialog()"
				rejectBtnOnly="true" rejectLabel="Close">

	<he-data-table-v3 [dataKey]="dataKey" [columns]="cols" [data]="data" [rowsPerPage]="5"
					  (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords"
					  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields"
					  [loading]="loading">
	</he-data-table-v3>

	<p-editor *ngIf="originalNote" [style]="{ height: '120px' }" [ngModel]="originalNote"
			  [readonly]="true">
		<ng-template pTemplate="header">
			<div class="editor-header">
				<div class="header-info">
					<span class="email-text">{{ 'Original Note' }}</span>
				</div>
			</div>
		</ng-template>
	</p-editor>
</he-base-dialog>


