import { Injectable } from '@angular/core';
import {CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { of } from 'rxjs';

import { LoginService } from '../../helio-core-services';

@Injectable()
export class LoginGuard implements CanActivate {
	private redirectURL: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loginService: LoginService
	) { }

	// ngOnInit() {
	// 	this.route.queryParamMap.subscribe((params: ParamMap) => {
	// 		if (params.has('redirectURL')) {
	// 			this.redirectURL = params.get('redirectURL');
	// 		}
	// 	});
	// }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.getRedirectURL();

		if (this.loginService.isLoggedInAndTokenValid) {
			if (this.redirectURL !== undefined && this.redirectURL !== null) {
				this.router.navigateByUrl(this.redirectURL);
			} else {
				this.router.navigate(['/home']);
			}

			return of(false);
		}

		return of(true);
	}

	private getRedirectURL() {
		this.redirectURL = this.route.snapshot.paramMap.get('redirectURL');
	}
}
