import {_lodash, _luxonDateTime} from '../../global-libraries';
import {DateTime} from 'luxon';
import {formatDate} from '@angular/common';

//region Date Helpers - TODO: Move to a different class

export function isValidDate(value: any) {
	return value instanceof Date && !isNaN(value as any);
}

/**
 * @summary Helper method for fixing issue with Date.js, whereby the ISO deducted 1 hour from UI parsed data,
 * resulting in the backend returning no data for a valid request when "is equal to" operator is used in AdvancedSearch.
 */
export function dateToISOStr(date: Date | DateTime): string {
	if (!date) {
		console.error('HE - #dateToISOStr: date arg is null!');
	}

	if ('toJSDate' in date) {
		return date.toISO({includeOffset: false}); // date.toUTC().plus({hour: 1}).toISO();
	}

	return _luxonDateTime.fromJSDate(date).toISO({includeOffset: false});
}

export function toUKDateTime(date: Date): string | null {
	try {
		const dateTime = _luxonDateTime.fromJSDate(date); // .fromFormat(date, P_CALENDAR_FORMAT.LUXON);
		return `${dateTime.day}/${dateTime.month}/${dateTime.year} ${dateTime.toISOTime().substring(0, 8)}`; // 13:46:33.000+01:00
	} catch (e) {
		return null;
	}
}

export function formatDateWithLocale(date: Date, locale: string, format: 'date' | 'datetime' = 'datetime'): string {
	const separator = getDateSeparator(locale);

	const day = formatDate(date, 'dd', locale);
	const month = formatDate(date, 'MM', locale);
	const year = formatDate(date, 'yyyy', locale);
	const hours = formatDate(date, 'HH', locale);
	const minutes = formatDate(date, 'mm', locale);
	const seconds = formatDate(date, 'ss', locale);

	return format === 'datetime' ?
		`${day}${separator}${month}${separator}${year} ${hours}:${minutes}:${seconds}` :
		`${day}${separator}${month}${separator}${year}`;
}

export function getDateSeparator(locale: string): string {
	switch (locale) {
		case 'en-US':
		case 'en-GB':
		case 'de-DE':
		case 'fr-FR':
			return '/';
		case 'ru-RU':
			return '.';
		default:
			return '-';
	}
}

export function oDataDateStr(property: string, operator: 'eq' | 'ne' | 'lt', date: Date | DateTime) {
	return `${property} ${operator} DATETIME "${dateToISOStr(date)}"`;
}

//endregion


export function capitalise(s: string) {
	return _lodash.capitalize(s)
}


export function stripWhiteSpace(s: string): string {
	return s ? s.replace(/\s/g, '') : '';
}

/**
 * Return true if the string arg is defined and has a length greater or equal to 1.
 */
export function notEmptyStr(s: string) {
	return s && (s.length > 0);
}

/**
 * Removes whitespaces within an OData - nested - string indicated by double quotation.
 *
 * @summary the nested-OData string may only contain alphabets: a-z, whole numbers: 0-9, or dash: "-").
 * @example transforms 'couponIdentifier eq "12354   -   4465 55 "' into 'couponIdentifier eq "12354-446555"'
 */
export function stripODataWhitespace_couponIdentifier(s: string): string {
	if (!s) {
		return '';
	}

	const invalidIdRegEx = /couponIdentifier eq "(?<unparsedId>[0-9a-z\s-]+)"/g;
	const matches = invalidIdRegEx.exec(s);

	const unparsedId = matches?.groups.unparsedId;
	if (!unparsedId) {
		return s;
	}

	// Replacing un-stripped string with its whitespace-stripped counterpart
	const strippedId = unparsedId.replace(/\s/g, '');
	return s.replace(unparsedId, strippedId);
}

export function numToPxStr(n: number) {
	return n + 'px';
}

export function removePrefixIfExists(prefix: string, testStr: string) {
	if (prefix && testStr && testStr.startsWith(prefix)) {
		return testStr.substring(prefix.length);
	}
	return testStr;
}

/**
 * @param str the string on which to test the regexp against
 * @param regexp the pattern to test for
 * @param n the index, represented by 0-based indexing
 * @param replaceStr the string to replace the matching occurrence with
 */
export function replaceNthOccurrence(str, regexp, n, replaceStr) {
	let i = -1;
	return str.replace(regexp, (match) => {
		i += 1;

		if (i === n) {
			return replaceStr;
		}
		return match;
	});
}

