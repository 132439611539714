export enum ColumnType {
	Default,
	String,
	Number,
	Money,
	Date,
	TrueFalse,
	Tooltip,
	List,
	Toggle,
	Link,
	Action,
	FAB,
	ListForInlineEdit,
	ImageSnippet
}
