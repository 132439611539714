import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {CurrencyPipe} from '@angular/common';
import {AdjustWalletRequest} from '../../../models/finance/adjust-wallet-request';
import {AdjustmentOpts} from '../../../enums/adjustment-opts';
import {BaseLookup} from '../../../interfaces/lookup-interfaces';
import {WalletBalance} from '../../../models/finance/wallet-transaction.model';
import {LookupService} from '../../../services/lookup.service';
import {LABEL_ADJUST_WALLET, RE_COMMENTS} from '../../../constants/constants';
import {AppConfigService, BoErrorHandlerService, ToastDisplayService} from '../../../../helio-core-services';
import {ToastMessageType} from '../../../models';

@Component({
	selector: 'he-wallet-manual-adjust-dialog',
	templateUrl: './wallet-manual-adjust-dialog.component.html',
	styleUrls: [
		'./wallet-manual-adjust-dialog.component.scss',
		'../common-draw-dialog-style.scss'
	]
})
export class WalletManualAdjustDialogComponent implements OnInit {
	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Output() closeEvent = new EventEmitter<void>();
	@Output() submitEvent = new EventEmitter<AdjustWalletRequest>();

	form: FormGroup;

	adjustmentOpts = AdjustmentOpts;

	submitLabel = LABEL_ADJUST_WALLET;

	selectedAdjustmentType: AdjustmentOpts;

	uiAmount: number;
	calculatedAmountAfter: number;
	amountBefore: number;

	walletTypeOpt: BaseLookup[];

	isLoadingLookups = false;
	hasOneWallet = false;

	currencySymbol: string;

	private _balance$ = new BehaviorSubject<WalletBalance>(null)

	constructor(
		private formBuilder: FormBuilder,
		private lookupService: LookupService,
		protected appConfigService: AppConfigService,
		private toastDisplayService: ToastDisplayService,
		private currencyPipe: CurrencyPipe,
		private changeDetection: ChangeDetectorRef,
		private boErrorHandlerService: BoErrorHandlerService
	) {
	}

	get balance() {
		return this._balance$.getValue();
	}

	@Input() set balance(value: WalletBalance) {
		this._balance$.next(value);
	}

	get isFormDisabled(): boolean {
		return !this.form.valid && (this.form.dirty || this.form.touched) || !this.form.touched;
	}

	ngOnInit() {
		this.formInit();
		this.setFormData();

		this._balance$.asObservable().subscribe({
			next: balance => {
				if (balance?.walletsEnabled) {

					this.currencySymbol = this.currencyPipe.transform(
						0, balance.currencyCode, 'symbol', '1.0-2', 'en').replace('0', '');

					this.isLoadingLookups = false;
				}

				this.initWalletTypes();
			}
		})
	}

	onSubmit() {
		let adjustmentType;
		let adjustAmount;


		adjustmentType = this.form.get('adjustmentType').value;

		// TODO -
		//  For now, move this functionality, i.e. generating AdjustWalletRequest to a helper method so that it can be easily
		//   tested. Main test point is to ensure that the original amount derieved from the UI is positive!
		//  It should be recommended to BE that computation of the positive or neg value be done BE-side
		//  Since 1, there is currently more robust testing there
		//        2, Future changes in UI validation is likely to miss this (at the absence of automated UI tests)
		const tempAmount = Number(this.form.get('amount').value);

		if (tempAmount < 0) {
			this.boErrorHandlerService.handleError(new Error('onSubmit: UI validation error. Amount value cannot be < 0.'));
		}

		if (adjustmentType === this.adjustmentOpts.ADD) {
			adjustAmount = tempAmount;
		} else if (adjustmentType === this.adjustmentOpts.REMOVE) {
			adjustAmount = tempAmount * -1;
		} else {
			this.boErrorHandlerService.handleError(new Error('onSubmit: adjustmentType not handled correctly.'));
			return;
		}

		const request: AdjustWalletRequest = {
			balanceType: this.form.get('selectedWallet').value,
			adjustAmount,
			comment: this.form.get('comment').value
		};

		this.submitEvent.emit(request);
	}

	onCloseDialog() {
		// this.visible = false;
		this.closeEvent.emit();
	}

	initWalletTypes() {
		this.walletTypeOpt = [];

		// Generate walletType lookup
		if (this.balance?.walletsEnabled) {
			for (const s of this.balance.walletsEnabled) {
				this.walletTypeOpt.push({label: s, value: s});
			}
		}

		// Flag the number of lookup options so that UI reacts accordingly (as per template setup)
		if (this.balance?.walletsEnabled && this.balance.walletsEnabled?.length <= 0) {
			this.toastDisplayService.addMessage({
				title: 'Error',
				description: `${this.appConfigService.genericErrorMessage}. No wallets are associated to account.`,
				type: ToastMessageType.error
			});
		} else {
			this.hasOneWallet = this.balance?.walletsEnabled?.length === 1;
		}

		if (this.form && this.hasOneWallet) {
			this.form.get('selectedWallet').setValue(this.balance.walletsEnabled[0]);
		}
	}

	private formInit() {
		this.form = this.formBuilder.group({
			selectedWallet: new FormControl('', {
				validators: [Validators.required]
			}),
			adjustmentType: new FormControl('', [Validators.required]),
			amount: new FormControl(undefined, [
				Validators.required,
				Validators.pattern('[0-9]*[.]{0,1}[0-9]{0,2}'),
				Validators.min(0.01)
			]),
			comment: new FormControl('', [
				Validators.required,
				Validators.pattern(RE_COMMENTS),
				Validators.minLength(6),
				Validators.maxLength(2500)
			])
		});

		this.form.controls['selectedWallet'].valueChanges.subscribe({
			next: type => {
				this.determineInitialAmount(type);
			}
		});

		this.form.controls['adjustmentType'].valueChanges.subscribe({
			next: type => {
				this.selectedAdjustmentType = type;
				this.calculateAmountAfter(this.uiAmount);
			}
		});

		this.form.controls['amount'].valueChanges.subscribe({
			next: value => {
				this.uiAmount = value;
				this.calculateAmountAfter(this.uiAmount);
			}
		});

		this.initWalletTypes();
	}

	private calculateAmountAfter(newAmount: number) {
		const selectWallet = this.form.get('selectedWallet').value;

		// This needs to be called each time since wallet type can differ
		this.determineInitialAmount(selectWallet);

		if (newAmount < 1 || !this.selectedAdjustmentType || !selectWallet) {
			return;
		}

		if (this.selectedAdjustmentType === AdjustmentOpts.ADD) {
			this.calculatedAmountAfter = this.amountBefore + newAmount;
		} else if (this.selectedAdjustmentType === AdjustmentOpts.REMOVE) {
			this.calculatedAmountAfter = this.amountBefore - newAmount;
			this.changeDetection.detectChanges();
		} else {
			this.calculatedAmountAfter = undefined;
			this.changeDetection.detectChanges();
		}

		// console.log('calculateAmountAfter: (calculatedAmountAfter <= 0) =', (this.calculatedAmountAfter <= 0),
		// 'isFormValid =', !this.form.valid)
	}

	private determineInitialAmount(selectedWallet: string) {
		selectedWallet = selectedWallet.toLowerCase();

		if (selectedWallet === 'credit') {
			this.amountBefore = this.balance.credit;
		} else if (selectedWallet === 'winnings') {
			this.amountBefore = this.balance.winnings;
		}
	}

	private setFormData() {
		this.isLoadingLookups = true;

		if (!this.balance) {
			return;

			// TODO: Will need to set a listener to attempt to set formData in future should balance be defined later
		}
	}
}
