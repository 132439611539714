import { HttpParams } from '@angular/common/http';

import { SearchField } from './search-field.model';

export interface SubmitSearchEvent {
	searchFields?: SearchField[];
	searchType?: SearchEventType;
	filterString?: string;
	urlParams?: HttpParams;
}

export enum SearchEventType {
	SUBMIT = 444,
	CLEAR,
	CLOSE
}
