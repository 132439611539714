import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

export const REPLACE_DIRECTIVE_DEFAULT = 'N/A';
/**
 * Replace invalid strings - including stringify objects; substituting null, undefined, empty object: "{}", empty array: "[]",
 * or blank (' '.len = 0) values in an element with: N/A
 * @note use <i> as a directly nested child of the component applying the directive if targeted at columns with UI rather than string,
 * such as Agents (active, requires password change), where replacement should not be made.
 * @summary substitution takes place after trim() has been called on the value of the innerText,
 * so, multiple whitespace is treated as an empty string.
 * @summary If applied on an element not containing string (including empty) but element as a direct child, no replacement is made.
 */
@Directive({
	selector: '[heReplaceInvalidWith]'
})
export class ReplaceInvalidWithDirective implements AfterViewInit {
	@Input() heReplaceInvalidWith = REPLACE_DIRECTIVE_DEFAULT;
	@Input() invalidValues: string[] = ['null', 'undefined', '{}', '[]', ''];

	constructor(private elRef: ElementRef) {
	}

	public static containsBindingSyntax(s: string) {
		const regEx = /^{{2}[\w\s]*}{2}$/g // Example target: {{user}}
		return s ? regEx.test(s) : false;
	}

	ngAfterViewInit() {
		// Call on this hook is important so directive is applied after the contents of any nested
		// children have been rendered.
		this.parseInnerText();
	}

	private parseInnerText() {
		const html: HTMLElement = this.elRef.nativeElement;
		const innerText = html?.innerText?.trim();
		const firstChildEl = html?.firstElementChild;

		if (firstChildEl?.localName === 'i') {
			// Do not replace if the firstChild is an <i> element, i.e.: <he-column-data> <i>  </i> </he-column-data>
			return;
		}
		else if (this.invalidValues.includes(innerText) || ReplaceInvalidWithDirective.containsBindingSyntax(innerText)) {
			html.innerText = this.heReplaceInvalidWith;
		}
	}

}
