<div class="layout-topbar user-select-none">
	<a href="#" class="menu-btn" (click)="onMenuButtonClick($event)">
		<i class="material-icons">&#xE5D2;</i></a>

	<he-app-title class="mobile-logo"></he-app-title>

	<div id="auth-user-area">
		<span *ngIf="loggedInUser" style="color: #ffffff;font-size: 14px; display: flex; align-items: center">
			Welcome &nbsp;

			<button pButton class="my-prof-slider-menu-btn" type="button" [label]="loggedInUser.username"
					(click)="goToProfile()"></button>
		</span>

		<button pButton type="button" label="Logout" (click)="logOut()"></button>
	</div>
</div>
