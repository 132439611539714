import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../helio-core-services';

@Component({
	selector: 'he-no-access-rights',
	styleUrls: [
		'./no-access-rights.component.scss'
	],
	templateUrl: './no-access-rights.component.html'
})

export class NoAccessRightsComponent {

	isLoggedIn = false;

	constructor(
		private router: Router,
		private loginService: LoginService,
	) {
	}

	ngOnInit() {
		this.isLoggedIn = this.loginService.isLoggedInAndTokenValid;
	}

	logOut() {
		// Clean data stored locally
		this.loginService.logOut();
		// Navigate to the login page
		this.router.navigate(['login']);
	}
}
