<div class="widget-img">
	<img [src]="imgSrc" [alt]="imgAlt">
</div>

<span style="font-weight: bold">{{title}}</span>

<span class="widget-subtitle">{{subTitle}}</span>

<button class="helio-orange-btn" pButton type="button" pRipple (click)="onAddWidgetClicked()"
		[label]="addLabel" [title]="addLabel" icon="pi pi-plus"></button>
