import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RestrictionIntervalTypesLookup, RestrictionTypesLookup} from '../../../interfaces/lookup-interfaces';
import {AppGuard} from '../../../guards';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LookupService} from '../../../services/lookup.service';
import {BoErrorHandlerService} from '../../../../helio-core-services';
import {RE_COMMENTS} from '../../../constants/constants';
import {ActivatedRoute} from '@angular/router';
import {AddRestrictionRequest} from '../../../models/player/account-restriction.model';

@Component({
	selector: 'he-add-restriction-dialog',
	templateUrl: './add-restriction-dialog.component.html',
	styleUrls: [
		'./add-restriction-dialog.component.scss',
		'../common-draw-dialog-style.scss'
	]
})
export class AddRestrictionDialogComponent {
	protected readonly DialogType = AddRestrictionDialogType;

	@Input() restrictionType: AddRestrictionDialogType = AddRestrictionDialogType.DEACTIVATE_ACCOUNT;

	@Input() visible = false;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() dialogTitle: 'Add Timeout Restriction' | 'Add Self-Exclusion' | 'Deactivate Account' | 'Add to Blacklist';
	@Input() submitLabel = 'Add Restriction';

	@Output() closeEvent = new EventEmitter<void>();
	@Output() submitEvent = new EventEmitter<AddRestrictionRequest>();

	routePlayerID: number;

	restrictionTypeLookup: RestrictionTypesLookup[];
	intervalTypeLookup: RestrictionIntervalTypesLookup[];

	form: FormGroup;
	isLoadingRestrictionTypeLookups = false;
	isLoadingIntervalTypeLookups = false;

	constructor(
		private route: ActivatedRoute,
		private appGuard: AppGuard,
		private formBuilder: FormBuilder,
		private lookupService: LookupService,
		private boErrorHandlerService: BoErrorHandlerService) {
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.routePlayerID = params.id;
		});

		this.formInit();
		this.fetchLookup();
	}

	private formInit() {
		this.form = this.formBuilder.group({
			playerID: new FormControl(this.routePlayerID, {
				validators: [Validators.required]
			}),
			restrictionTypeID: new FormControl(['', Validators.required]),
			intervalType: new FormControl('', [Validators.required]),
			numUnits: new FormControl(0, [Validators.required, Validators.min(0)]),
			comment: new FormControl('', [
				// Validators.required,
				Validators.pattern(RE_COMMENTS),
				Validators.minLength(2),
				Validators.maxLength(2500)
			])
		});
	}

	private fetchLookup() {
		this.isLoadingRestrictionTypeLookups = true;

		this.lookupService.getRestrictionTypes().subscribe({
			next: (res: any) => {
				this.restrictionTypeLookup = res;

				if (this.form) {
					this.form.get('restrictionTypeID').setValue(this.restrictionType);
				}

				this.isLoadingRestrictionTypeLookups = false;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.isLoadingRestrictionTypeLookups = false;
			}
		});

		this.isLoadingIntervalTypeLookups = true;

		this.lookupService.getRestrictionIntervalTypes().subscribe({
			next: (res: any) => {
				this.intervalTypeLookup = res;

				// As directed by Luke, set as default;
				if (this.form) {
					this.form.get('intervalType').setValue(INSTANT_INTERVAL_TYPE_ID);
				}

				this.isLoadingIntervalTypeLookups = false;
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.isLoadingIntervalTypeLookups = false;
			}
		});
	}

	onSubmit() {
		const rawValue = this.form.getRawValue();

		const request: AddRestrictionRequest = {
			playerID: rawValue['playerID'],
			playerRestrictionTypeID: rawValue['restrictionTypeID'],
			intervalType: rawValue['intervalType'],
			numUnits: rawValue['numUnits'],
			reason: rawValue['comment']
		}

		this.submitEvent.emit(request);
	}

	onCloseDialog() {
		this.closeEvent.emit();
	}
}

export enum AddRestrictionDialogType {
	TIME_OUT = 1, // ============ These are mapped to api values ============
	SELF_EXCLUSION = 2,
	DEACTIVATE_ACCOUNT = 3,
	BLACKLIST = 4,
	SUSPICIOUS = 5
}

export const INSTANT_INTERVAL_TYPE_ID = 7; // this is mapped to api value
