import {CountryGroup} from '../models/general/nomenclature';
import {KycDocumentStatus} from '../../player-management/shared/models/kyc-document-status.model';

export interface BaseLookup {
	label: string;
	value: number | string;
}

export interface TransactionTypesLookup extends BaseLookup {
	transactionTypeID: number;
	name: string;
	displayName: string;
	signMult: number;
	categoryType: string;
}

export interface RestrictionTypesLookup extends BaseLookup {
	playerRestrictionTypeID: number;
	code: string;
	displayName: string;

	notes: string;
	loginAllowed: boolean;
	depositAllowed: boolean;
	withdrawalAllowed: boolean;
	playAllowed: boolean;
	changeTimeWindowAllowed: boolean;
	autoRemoveOnExpiry: boolean;
}

export interface RestrictionIntervalTypesLookup extends BaseLookup {
	intervalTypeID: number;
	name: string;
	displayName: string;
}

/**
 * @todo In dummy state - Update the signature once BE API ready
 */
export interface SubscriptionStatusLookup extends BaseLookup {
	subscriptionStatusID: number;
	name: string;
	displayName: string;
}

/**
 * Interface combined for {@link ServiceAction#AWARD_TICKETS_GET_GAMES_ALL} and
 * {@link ServiceAction#AWARD_TICKETS_GET_GAMES_PLAYER}
 */
export interface GamesDataLookup extends BaseLookup {
	gameGroupID: number;
	gameGroup: string;
	name?: string; // TODO Make required after BE returns the value for all associated endpoints
	gameID?: number;
	isActive?: boolean;
}

export interface GameTypeLookup extends BaseLookup {
	gameTypeID: number;
	name: string;
	displayName: string;
	description: string;
	gameVerticalID: number;
	hasDraws: boolean;
}

export interface TicketTypeLookup extends BaseLookup {
	ticketType: string;
	name: string;
	displayName: string;
}

export interface OperatorBrandsLookup extends BaseLookup {
	tenantBrandID: number;
	displayName: string;
	tenantName?: string;
	isDefault: boolean;
	fullDisplayName?: string;
}

export interface PlayerRegistrationTypesLookup extends BaseLookup {
	playerRegistrationTypeID: number;
	displayName: string;
	isRetail?: boolean;
}

export interface ApplicableUserRoleLookup extends BaseLookup {
	id: number;
	name: string;
	displayName: string;
}

export interface OperatorsLookup extends BaseLookup {
	tenantID: number;
	tenantName: string;
	integrationTypeID: number;
	integrationType: string;
	defaultPlayerRegistrationTypeID: number;
}

export class CountryGroupLookup implements BaseLookup, CountryGroup {
	countryID: number;
	countryCode: string;
	name: string;

	ageLimit: number;
	isActive: boolean;
	phonePrefix: string;

	contactNumberBlocked: boolean;
	residenceBlocked: boolean;

	// Maps the applicable DTO values to those expected by /** data-table-v3.component.ts **/
	label: string;
	value: number | string;
}

/**
 * @summary use {@link KycDocumentStatus} only for BE request so that UI generated fields
 * are not include; otherwise, {@link KycDocumentStatusLookup} should be used throughout the UI, esp
 * when dealing with AdvancedSearch and DropDown options.
 */
export interface KycDocumentStatusLookup extends BaseLookup, KycDocumentStatus {
}

export interface KycDocumentTypeLookup extends BaseLookup {
	kycDocumentTypeID: number;
	documentType: string;
	displayName: string;
	notes: string;
	isActive: boolean;
}


//region Categories

export enum TransactionTypesCategory {
	RewardPoint = 'RewardPoint',
	Financial = 'Financial',
	GamePlay = 'GamePlay',
	Unclassified = 'Unclassified'
}

//endregion

export interface WithdrawalStatuesLookup extends BaseLookup {
	paymentStateID: number,
	name: string,
	displayName: string
}

export interface WithdrawalMethodsLookup extends BaseLookup {
	paymentMethodID: number,
	name: string,
	displayName: string,
	isEnabled: boolean,
	canDeposit: boolean,
	canWithdraw: boolean
}
