import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Router} from '@angular/router';
import {AppLayoutService} from '../../layout/secure/app-layout.service';
import {HeMenuItem} from '../../shared/models/primeng-overrides/he-menu-item.interface';
import {AppGuard} from '../../shared';

@Component({
	/* tslint:disable:component-selector */
	selector: '[app-submenu]',
	/* tslint:enable:component-selector */
	templateUrl: './sub-menu.component.html',
	styleUrls: ['./sub-menu.component.scss'],
	animations: [
		trigger('children', [
			state('hiddenAnimated', style({
				height: '0px'
			})),
			state('visibleAnimated', style({
				height: '*'
			})),
			state('visible', style({
				height: '*',
				'z-index': 100
			})),
			state('hidden', style({
				height: '0px',
				'z-index': '*'
			})),
			transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	]
})
export class AppSubMenuComponent implements OnInit {
	private static lis: ToggleMenuListener | null;
	TAG = AppSubMenuComponent.name;
	@Input() menuItems: HeMenuItem[]; // MenuItem[] is used to represent root, as well as sub, items.

	// @Output() private toggleMenu = new EventEmitter<void>();
	@Input() visible: boolean;
	activeIndex: number;
	hover: boolean;
	isHorizontal = false;

	// constructor(public app: AppComponent, public router: Router, public location: Location, public appMenu: AppMenuComponent) { }
	constructor(public router: Router,
				public location: Location,
				private appGuard: AppGuard,
				private appLayoutService: AppLayoutService
	) {
	}

	_reset: boolean;

	@Input() get reset(): boolean {
		return this._reset;
	}

	set reset(val: boolean) {
		this._reset = val;
	}

	public static setToggleMenuListener(l: ToggleMenuListener | null) {
		AppSubMenuComponent.lis = l;
	}

	ngOnInit() {
		this.isHorizontal = this.appLayoutService.isMenuHorizontal;
	}

	itemClick(event: Event, item: HeMenuItem, index: number) {
		/*const li = (event.target as HTMLElement).parentElement;
		if (item.icon === 'pi-sign-out') {
			li.classList.add('active-menuitem');
		}*/

		// avoid processing disabled items
		if (item.disabled) {
			event.preventDefault();
			return true;
		}

		// activate current item and deactivate active sibling if any
		if (item.routerLink || item.items || item.command || item.url) {
			this.activeIndex = (this.activeIndex === index) ? null : index;
		}

		// execute command
		if (item.command) {
			item.command({originalEvent: event, item: item});
		}

		// prevent hash change
		if (item.items || (!item.url && !item.routerLink)) {
			// setTimeout(() => {
			// 	this.appMenu.layoutMenuScrollerViewChild.moveBar();
			// }, 450);
			event.preventDefault();
		}

		// hide menu if the end of the tree (nested or lone item) was selected
		if (!item.items && this.appLayoutService.isMobileOrTablet() && AppSubMenuComponent.lis) {
			AppSubMenuComponent.lis.onToggleMenu();
		}

		return true;
	}

	isActive(index: number): boolean {
		return this.activeIndex === index;
	}

	/*getItems(): MenuItem[] {
		return (this.item ? this.item : this.subItem.items)
	}*/

	isPrimeIcon(icon: string) {
		return icon.startsWith('pi-') || icon.startsWith('pi ');
	}
}

export interface ToggleMenuListener {
	onToggleMenu();
}
