import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { ComponentHostDirective } from '../component-host';
import { ChangePasswordComponent } from './change-password.component';

@Injectable()
export class ChangePasswordService {
	overlayHost: ComponentHostDirective;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver
	) { }

	showChangePasswordDialog() {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ChangePasswordComponent);
		this.overlayHost.viewContainerRef.createComponent(componentFactory);
	}

	destroyChangePasswordDialog() {
		this.overlayHost.viewContainerRef.remove();
	}
}
