import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {
	ApplicableUserRoleLookup, BaseLookup, CountryGroupLookup, GameTypeLookup,
	OperatorBrandsLookup,
	OperatorsLookup,
	PlayerRegistrationTypesLookup, RestrictionIntervalTypesLookup, RestrictionTypesLookup, SubscriptionStatusLookup, TicketTypeLookup,
	TransactionTypesCategory,
	TransactionTypesLookup, WithdrawalMethodsLookup, WithdrawalStatuesLookup
} from '../interfaces/lookup-interfaces';
import {ServiceAction, ServiceController} from '../utilities';
import {AppConfigService, BaseService, TenantService} from '../../helio-core-services';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppComponent} from 'src/app/app.component';
import {PlayerNomenclatureResponse} from '../models/general/nomenclature';
import {isArrayInit} from '../utilities/general-utilities/arrays.utils';
import {API_DATA_INVALID, TRANSACTION_TYPE} from '../constants';
import {delay} from 'rxjs/operators';
import {CONST_LOCAL_STORAGE} from '../constants/constants';
import {TransactionType} from '../enums/transaction-type.enum';

type Page = {
	name: string;
	childPages: Page[];
};

/**
 * @summary Retrieve all lookup on startup, Since the sum of all app (dynamic) lookup do not represent a huge amount of data
 * they are not currently tied to bootstrap, i.e. 80/20 rule; instead, lazy loading is conducted using {@link #initAllEagerly}
 * and called in {@link AppComponent}.
 *
 * In addition to {@link #initAllEagerly}, which does not return, each lookup has a dedicated method for modularity,
 * and each retrieval is stored as a singleton, for subsequent, project-wide access.
 */
@Injectable({
	providedIn: 'root'
})
export class LookupService extends BaseService {

	private static _transactionTypes: TransactionTypesLookup[] = [];
	private static _walletTransactionTypes: TransactionTypesLookup[] = [];
	private static _walletTypes: BaseLookup[] = [];
	private static _operatorBrands: OperatorBrandsLookup[] = [];
	private static _playerRegistrationTypes: PlayerRegistrationTypesLookup[] = [];
	private static _applicableUserRoles: ApplicableUserRoleLookup[] = [];
	private static _playerRestrictionTypes: RestrictionTypesLookup[] = [];
	private static _playerRestrictionIntervalTypes: RestrictionIntervalTypesLookup[] = [];
	private static _playerSubscriptionStatues: SubscriptionStatusLookup[] = [];
	private static _operators: OperatorsLookup[] = [];
	private static _retailAgents: BaseLookup[] = [];
	private static _kycStatues: BaseLookup[] = [];
	private static _gameTypes: GameTypeLookup[] = [];
	private static _ticketTypes: TicketTypeLookup[] = [];
	private static _transactionalKycStatues: BaseLookup[] = [];
	private static _activeDrawStatues: BaseLookup[] = [];
	private static _previousDrawStatues: BaseLookup[] = [];
	private static _withdrawalStatuses: WithdrawalStatuesLookup[] = [];
	private static _withdrawalMethods: WithdrawalMethodsLookup[] = [];
	private static nomenclatureDefinitions: PlayerNomenclatureResponse;
	private static _retailOperators: OperatorsLookup[] = [];

	private static _retailAgentTypes: BaseLookup[] = [];
	private static _prepaidTopUpBatchStatues: BaseLookup[] = [];
	private static _prepaidTopUpCardStatues: BaseLookup[] = [];

	TAG = LookupService.name;

	constructor(protected http: HttpClient, private appConfigService: AppConfigService, private tenantService: TenantService) {
		// TODO: change ServiceController.PLAYER_CONTROLLER to undefined once sure this does not
		//  cause issues, since this will be different for each lookup
		super(http, ServiceController.PLAYER, appConfigService.serviceBaseURL);
	}

	getKycStatuses(): Observable<BaseLookup[]> {
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._kycStatues, {label: 'status', value: 'kycStatusID'}, ServiceAction.GET_KYC_STATUES,
			undefined, undefined, undefined, ServiceController.KYC
		);
	}

	getTransactionalKycStatuses(): Observable<BaseLookup[]> {
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._transactionalKycStatues, {label: 'status', value: 'kycStatusID'},
			ServiceAction.GET_TRANSACTIONAL_KYC_STATUES, undefined, undefined,
			undefined, ServiceController.KYC
		);
	}

	getActiveDrawStatuses(): Observable<BaseLookup[]> {
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._activeDrawStatues, {label: 'name', value: 'drawExpandedStatusID'},
			ServiceAction.DRAW_ACTIVE_GET_STATUSES, undefined, undefined,
			undefined, ServiceController.DRAW
		);
	}

	getPreviousDrawStatuses(): Observable<BaseLookup[]> {
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._previousDrawStatues, {label: 'name', value: 'drawStatusID'},
			ServiceAction.DRAW_PREVIOUS_GET_STATUSES, undefined, undefined,
			undefined, ServiceController.DRAW
		);
	}

	getGameTypes(): Observable<GameTypeLookup[]> {
		return this.validateAndParseLookupResSTD<GameTypeLookup>(
			LookupService._gameTypes, {label: 'displayName', value: 'gameTypeID'}, ServiceAction.GET_GAME_TYPES,
			undefined, undefined, undefined, ServiceController.GAME_ADMINISTRATION
		);
	}

	getTicketTypes(): Observable<TicketTypeLookup[]> {
		return this.validateAndParseLookupResSTD<TicketTypeLookup>(
			// ticketTypeID is not supported for AdvancedSearch - use name!
			LookupService._ticketTypes, {label: 'displayName', value: 'name'}, ServiceAction.GET_TICKET_TYPES,
			undefined, undefined, undefined, ServiceController.GAME_ADMINISTRATION
		);
	}

	getTransactionTypes(category?: TransactionTypesCategory): Observable<TransactionTypesLookup[]> {
		const filterForCategory = (cat: TransactionTypesCategory, lookup: TransactionTypesLookup[]): TransactionTypesLookup[] => {
			if (!cat) {
				return lookup;
			}

			return lookup.filter(entry => {
				return (entry.categoryType === cat);
			});
		}

		if (isArrayInit(LookupService._transactionTypes)) {
			return of(filterForCategory(category, LookupService._transactionTypes));
		}

		return new Observable<TransactionTypesLookup[]>(subscriber => {
			// First deliver the undefined value, indicating that lookup is not ready, so caller can show Toast
			subscriber.next(LookupService._transactionTypes);

			// TODO: Use named params for #get method - make sure it's backwards compatible
			this.get(ServiceAction.GET_TRANSACTION_TYPES, undefined, undefined,
				undefined, ServiceController.PLAYER).subscribe({
				next: res => {
					LookupService._transactionTypes = [];

					res.forEach(obj => {
						// Validate, to present adding null or undefined lookup
						if (obj.displayName && obj.transactionTypeID) {
							obj.label = obj.displayName;
							obj.value = obj.transactionTypeID;

							LookupService._transactionTypes.push(obj as TransactionTypesLookup);
						}
					});

					subscriber.next(filterForCategory(category, LookupService._transactionTypes));
					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}

	/**
	 * If tenantID is passed, a new call is always made to API, when undefined, previously queried dataset
	 * for the generic brands is returned.
	 *
	 * @param tenantID is optional and if passed the brands result are specific to the tenantID.
	 */
	getOperatorBrands(tenantID?: number): Observable<OperatorBrandsLookup[]> {
		let observable: Observable<OperatorBrandsLookup[]>;

		if (tenantID) {
			// if passed the brands result are specific to the tenantID and a new query should thus be made.
			observable = this.get(ServiceAction.GET_OPERATORS_BRAND, [String(tenantID)], undefined,
				undefined, ServiceController.USER_OWN);
		} else if (isArrayInit(LookupService._operatorBrands)) {
			return of(LookupService._operatorBrands);
		} else {
			observable = this.get(ServiceAction.GET_OPERATORS_BRAND, undefined, undefined,
				undefined, ServiceController.USER_OWN);
		}

		return new Observable<OperatorBrandsLookup[]>(subscriber => {
			// First deliver the undefined value, indicating that lookup is not ready, so caller can show Toast
			subscriber.next(LookupService._operatorBrands);

			// TODO: Use named params for #get method - make sure it's backwards compatible
			observable.subscribe({
				next: res => {
					LookupService._operatorBrands = [];

					let isNonconformingRes = false;

					const temp: OperatorBrandsLookup[] = []

					res.forEach((obj) => {
						// Validate, to present adding null or undefined lookup
						if (obj.fullDisplayName && obj.tenantBrandID) {
							temp.push({
								...obj,
								label: obj.fullDisplayName,
								value: obj.tenantBrandID
							} as OperatorBrandsLookup);
						} else {
							isNonconformingRes = true;
						}
					});

					if (isNonconformingRes) {
						// Rather than throwing an exception, simply log as the app should not fail because of absence of lookup
						console.error(`#validateAndParseLookupResSTD: one or more object delivered - \
						by ${ServiceAction.GET_OPERATORS_BRAND} - has been omitted for missing a required field.`);
					}

					if (!tenantID) {
						LookupService._operatorBrands = temp;

						if (LookupService._operatorBrands.length > 0) {
							subscriber.next(LookupService._operatorBrands);
						} else {
							subscriber.error(API_DATA_INVALID);
						}
					} else {
						subscriber.next(temp);
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}

	getPlayerRegistrationTypes(isRetail?: boolean): Observable<PlayerRegistrationTypesLookup[]> {
		const filterRetail = () => {
			if (isRetail === undefined) {
				return LookupService._playerRegistrationTypes;
			}
			return LookupService._playerRegistrationTypes.filter(entry => entry.isRetail);
		}

		if (isArrayInit(LookupService._playerRegistrationTypes)) {
			return of(filterRetail());
		}

		return new Observable<PlayerRegistrationTypesLookup[]>(subscriber => {
			// First deliver the undefined value, indicating that lookup is not ready, so caller can show Toast
			subscriber.next(LookupService._playerRegistrationTypes);

			// TODO: Use named params for #get method - make sure it's backwards compatible
			this.get(ServiceAction.GET_PLAYER_REGISTRATION_TYPE, undefined, undefined,
				undefined, ServiceController.USER_OWN).subscribe({
				next: res => {
					LookupService._playerRegistrationTypes = [];

					res.forEach(obj => {
						// Validate, to present adding null or undefined lookup
						if (obj.displayName && obj.playerRegistrationTypeID) {
							LookupService._playerRegistrationTypes.push({
								...obj,
								label: obj.displayName,
								value: obj.playerRegistrationTypeID
							} as PlayerRegistrationTypesLookup);
						}
					});

					if (LookupService._playerRegistrationTypes) {
						subscriber.next(filterRetail());
					} else {
						subscriber.error(new Error(API_DATA_INVALID));
					}

					subscriber.complete();
				},
				error: err => {
					subscriber.error(err);
				}
			})
		})
	}

	/**
	 * @summary Good solution, but in the long run it could be housed in {@link LoginService} and maybe called as part of
	 * {@link LoginService#saveSessionData}; the only downside is that all the affected APIs, which should not change from session
	 * to session would be unnecessarily called as part of {@link LoginService#refreshUserToken}.
	 */
	initActionPermissions(): Promise<void> {
		return new Promise<void>(resolve => {
			const val = undefined;

			const observable = forkJoin([
				this.get(ServiceAction.GET_ACTION_PERMISSIONS, val, val, val, ServiceController.USER_OWN),
				this.get(ServiceAction.GET_PAGE_PERMISSIONS, val, val, val, ServiceController.USER_OWN),
				this.get(ServiceAction.GET_REPORT_PERMISSIONS, val, val, val, ServiceController.USER_OWN)
			]);

			observable.subscribe({
				next: res => {
					sessionStorage.setItem(CONST_LOCAL_STORAGE.ACTIONS, JSON.stringify(res[0]));
					// sessionStorage.setItem(CONST_LOCAL_STORAGE.PAGES, JSON.stringify(res[1]));
					sessionStorage.setItem(CONST_LOCAL_STORAGE.PAGES_FLATTEN, JSON.stringify(this.flattenPages(res[1])));
					sessionStorage.setItem(CONST_LOCAL_STORAGE.REPORTS, JSON.stringify(res[2]));

					resolve();
				},
				error: err => {
					sessionStorage.setItem(CONST_LOCAL_STORAGE.ACTIONS, '[]');
					sessionStorage.setItem(CONST_LOCAL_STORAGE.REPORTS, '[]');

					console.error(`${ServiceAction.GET_ACTION_PERMISSIONS}: ${err}`);

					resolve();
				}
			});
		})
	}

	private flattenPages(pages: Page[], basePath: string = ''): string[] {
		const result: string[] = [];

		pages.forEach(page => {
			const currentPath = basePath ? `${basePath}/${page.name}` : `/${page.name}`;

			result.push(currentPath); // Add the top-level name property

			if (page.childPages.length > 0) {
				result.push(...this.flattenPages(page.childPages, currentPath));
			}
		});

		return result.sort();
	};

	getOperators(): Observable<OperatorsLookup[]> {
		return this.validateAndParseLookupResSTD<OperatorsLookup>(
			LookupService._operators, {label: 'tenantName', value: 'tenantID'}, ServiceAction.GET_OPERATORS,
			undefined, undefined, undefined, ServiceController.USER_OWN
		);
	}

	/**
	 * @description This API returns only the signature required for {@link BaseLookup}, if the entire fields are required,
	 * then {@link RetailAgentService#getRetailAgents} should be used instead.
	 * @param searchParams if any
	 * @param lookupParams e.g. the default is {label: 'tenantName', value: 'retailAgentID'}. this value is used to display and map
	 * values of dropdown or multiselect lists.
	 */
	public getRetailAgents(
		searchParams?: HttpParams,
		lookupParams: {label: string, value: string} = {label: 'tenantName', value: 'retailAgentID'}
	): Observable<BaseLookup[]> {
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._retailAgents, lookupParams, undefined,
			undefined, undefined, undefined, ServiceController.RETAIL_AGENT
		);
	}

	/**
	 * Returns the type definitions for Countries, Nationalities, Genders or Languages if, specified. If the
	 *  type is not specified, all are returned.
	 *  @todo refactor this method such that if only a specific typeDef is requested, on this is sent back? Good idea?
	 */
	getPlayerNomenclature(typeDef?: 'Countries' | 'Nationalities' | 'Genders' | 'Languages' | 'Currencies')
		: Promise<PlayerNomenclatureResponse> {
		const searchParams = () => {
			const params = new HttpParams();
			if (typeDef) {
				return params.set(typeDef, true)
			}

			return params.set('Countries', true)
				.set('Nationalities', true)
				.set('Genders', true)
				.set('Currencies', true)
				.set('Languages', true);
		}

		return new Promise<PlayerNomenclatureResponse>((resolve, reject) => {
			LookupService.nomenclatureDefinitions
				? resolve(LookupService.nomenclatureDefinitions)
				: this.get(ServiceAction.NOMENCLATURES_GET_TYPES, undefined, searchParams(),
					undefined, ServiceController.NOMENCLATURES).subscribe({
					next: res => {
						let countries: CountryGroupLookup[];
						let unblockedCountries: CountryGroupLookup[];

						if (res.countries) {
							countries = res.countries.map(country => {
								country.label = country.name;
								country.value = country.countryID;
								return country;
							}).sort();

							unblockedCountries = res.countries.filter(country => {
								return !country.residenceBlocked;
							});
						}

						if (res.currencies) {
							res.currencies = res.currencies.map(entry => {
								entry.label = entry.name + ' ' + entry.currencyCode;
								entry.value = entry.currencyID;
								return entry;
							}).sort();
						}

						const temp: PlayerNomenclatureResponse = {
							result: {
								countries,
								unblockedCountries,
								nationalities: res.nationalities?.sort() ?? undefined,
								genders: res.genders,
								languages: res.languages?.sort() ?? undefined,
								currencies: res.currencies
							}
						};

						if (!typeDef) {
							LookupService.nomenclatureDefinitions = temp;
						}

						resolve(temp);
					}, error: (err) => {
						reject(err);
					}
				});
		})
	}

	getRestrictionTypes(): Observable<RestrictionTypesLookup[]> {
		return this.validateAndParseLookupResSTD<RestrictionTypesLookup>(
			LookupService._playerRestrictionTypes,
			{label: 'displayName', value: 'playerRestrictionTypeID'},
			ServiceAction.GET_RESTRICTION_TYPES, undefined, undefined,
			undefined, ServiceController.RESTRICTIONS
		);
	}

	getRestrictionIntervalTypes(): Observable<RestrictionIntervalTypesLookup[]> {
		return this.validateAndParseLookupResSTD<RestrictionIntervalTypesLookup>(
			LookupService._playerRestrictionIntervalTypes,
			{label: 'displayName', value: 'intervalTypeID'},
			ServiceAction.GET_RESTRICTION_INTERVAL_TYPES, undefined, undefined,
			undefined, ServiceController.RESTRICTIONS
		);
	}

	/**
	 * @todo Dummy data used, impl once API ready
	 */
	getSubscriptionStatues(): Observable<SubscriptionStatusLookup[]> {
		/*LookupService._playerSubscriptionStatues = dummySubscriptionsLookupRes;
		if (isArrayInit(LookupService._playerSubscriptionStatues)) {
			return of(LookupService._playerSubscriptionStatues);
		}*/

		return this.validateAndParseLookupResSTD<SubscriptionStatusLookup>(
			LookupService._playerSubscriptionStatues,
			{label: 'displayName', value: 'paymentStateID'},
			ServiceAction.RECURRING_PURCHASES_STATES, undefined, undefined,
			undefined, ServiceController.PURCHASES
		);
	}

	getApplicableUserRoles(): Observable<ApplicableUserRoleLookup[]> {
		return this.validateAndParseLookupResSTD<ApplicableUserRoleLookup>(
			LookupService._applicableUserRoles,
			{label: 'displayName', value: 'id'},
			ServiceAction.GET_APPLICABLE_USER_ROLES, undefined, undefined,
			undefined, ServiceController.USER_OWN
		);
	}

	/**
	 * @summary Not filtering category type as with {@link getTransactionTypes} as per advice
	 * from Brian that this is no longer necessary.
	 */
	getWalletTransactionTypes(): Observable<BaseLookup[]> {
		/*return of([
			{label: 'Purchase', value: 1},
			{label: 'Deposit', value: 2}
		]).pipe(delay(1000));*/

		// Use /api/PlayerBalance/GetTransactionTypes
		return this.validateAndParseLookupResSTD<TransactionTypesLookup>(
			LookupService._walletTransactionTypes, {label: 'displayName', value: 'transactionTypeID'},
			ServiceAction.GET_TRANSACTION_TYPES, undefined, undefined,
			undefined, ServiceController.PLAYER_BALANCE
		);
	}

	getWalletTypes(): Observable<BaseLookup[]> {
		/*return of([
			{label: 'Credit Balance', value: 1},
			{label: 'Winning Balance', value: 2}
		]).pipe(delay(1000));*/

		// Use /api/PlayerBalance/GetWalletTypes
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._walletTypes, {label: 'name', value: 'playerBalanceTypeID'},
			ServiceAction.GET_WALLET_TYPES, undefined, undefined,
			undefined, ServiceController.PLAYER_BALANCE
		);
	}

	getOperatorWallets(): Observable<BaseLookup[]> {
		/*return this.validateAndParseLookupResSTD<OperatorsLookup>(
			LookupService._, {label: 'tenantName', value: 'tenantID'}, ServiceAction.GET_OPERATORS,
			undefined, undefined, undefined, ServiceController.USER_OWN
		);*/

		// TODO - Replace dummy values with API object
		return of([
			{label: 'Dummy Wallet A', value: 1},
			{label: 'Dummy Wallet BEE', value: 2}
		]).pipe(delay(1000));
	}

	getOperatorDistributions(): Observable<BaseLookup[]> {
		/*return this.validateAndParseLookupResSTD<OperatorsLookup>(
			LookupService._, {label: 'tenantName', value: 'tenantID'}, ServiceAction.GET_OPERATORS,
			undefined, undefined, undefined, ServiceController.USER_OWN
		);*/

		// TODO - Replace dummy values with API object
		return of([
			{label: 'Dummy - NGenius', value: 1},
			{label: 'Dummy - Fiserv', value: 2},
			{label: 'Dummy - Amazon Payment Services', value: 3}
		]).pipe(delay(1000));
	}

	getWithdrawalStatuses(): Observable<WithdrawalStatuesLookup[]> {
		/*return of([
			{label: 'Dummy - Pending', value: 1},
			{label: 'Dummy - Approved', value: 2}
		]).pipe(delay(1000));*/

		const params = new HttpParams().set(TRANSACTION_TYPE, TransactionType.WITHDRAWAL);

		return this.validateAndParseLookupResSTD<WithdrawalStatuesLookup>(
			LookupService._withdrawalStatuses, {label: 'displayName', value: 'paymentStateID'}, ServiceAction.PAYMENT_STATES,
			undefined, params, undefined, ServiceController.PAYMENTS
		);
	}

	getWithdrawalMethods(tenantID: number): Observable<WithdrawalMethodsLookup[]> {
		/*return of([
			{label: 'Dummy - Bank Transfer', value: 1},
			{label: 'Dummy - Exchange House', value: 2}
		]).pipe(delay(1000));*/

		return this.validateAndParseLookupResSTD<WithdrawalMethodsLookup>(
			LookupService._withdrawalMethods, {label: 'displayName', value: 'paymentMethodID'}, undefined,
			[tenantID, ServiceAction.PAYMENT_METHODS], undefined, undefined, ServiceController.PAYMENTS
		);
	}

	getAgentTypes(): Observable<BaseLookup[]> {
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._retailAgentTypes,
			{label: 'displayName', value: 'retailAgentTypeID'},
			ServiceAction.GET_AGENT_TYPES, undefined, undefined,
			undefined, ServiceController.RETAIL_AGENT
		);
	}

	getTopUpBatchStatues(): Observable<BaseLookup[]> {
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._prepaidTopUpBatchStatues,
			{label: 'displayName', value: 'prepaidCardBatchStateID'},
			ServiceAction.TOP_UP_CARDS_BATCH_STATUS, undefined, undefined,
			undefined, ServiceController.TOP_UP_CARDS
		);
	}

	getTopUpCardStatues(): Observable<BaseLookup[]> {
		return this.validateAndParseLookupResSTD<BaseLookup>(
			LookupService._prepaidTopUpCardStatues,
			{label: 'displayName', value: 'prepaidCardBatchCardStateID'},
			ServiceAction.TOP_UP_CARDS_STATUS, undefined, undefined,
			undefined, ServiceController.TOP_UP_CARDS
		);
	}

	initAllEagerly(isLoggedIn: boolean): void {
		if (isLoggedIn) {
			this.getTransactionTypes(TransactionTypesCategory.RewardPoint); // Use RewardPoint as default, return value is ignored anyway
			this.getOperatorBrands();
			this.getPlayerRegistrationTypes();
			this.getOperators();
			this.getPlayerNomenclature();
		}
	}
}
