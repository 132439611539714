import {Router} from '@angular/router';
import {LoginService} from '../helio-core-services';

export function goToProfile(router: Router) {
	router.navigate(['/my-profile']);
}

export function logOutAndGotoLogin(router: Router, loginService: LoginService) {
	// Clean data stored locally
	loginService.logOut().then(() => {
		// Navigate to the login page regardless
		router.navigate(['/login']);
	});
}
