import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

/**
 * Left in this folder and not in HelioCoreServices as this service is only used in
 * the secure-content component and not app wide
 */
@Injectable()
export class AppLayoutService {
	menuClick = false;
	menuButtonClick = false;
	isMenuStatic = true;
	isMenuHorizontal = false;

	menuClickSubject: Subject<boolean> = new Subject<boolean>();
	toggleMenuLayoutClick: Subject<boolean> = new Subject<boolean>();
	sidebarActiveChange: Subject<boolean> = new Subject<boolean>();

	constructor() {
	}

	isMobileOrTablet() {
		return window.innerWidth <= 1024;
	}

	sidebarActive(isSidebarActive: boolean) {
		this.sidebarActiveChange.next(isSidebarActive);
	}
}
