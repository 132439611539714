import {HttpHeaders} from '@angular/common/http';

import {HeaderContentType, ServiceHeadersConfig} from './service-headers.config';

/**
 * Utility class to set the request headers
 */
export class ServiceHeadersUtility {
	/**
	 * Function to set the request headers
	 * @param headersConfig Object to set whether the request headers should include the Authorization header
	 * as well as set the Content-Type header
	 * @return The request headers to be used for the HTTP request
	 */
	public static httpHeaders(headersConfig: ServiceHeadersConfig): HttpHeaders {
		let headers: HttpHeaders = new HttpHeaders();

		if ( headersConfig.contentType !== HeaderContentType.FORM_DATA) {
			// Only set for content type other than formData, since doing so causes an error due to the omission of "boundary" field;
			// However, in the omission of contentType, both it and its dependent field, boundary, are generated implicitly from the
			// presence of FormData object in the POST request.
			headers = headers.set('Content-Type', headersConfig.contentType);
		}

		if (!headersConfig.isAnonymous) {
			headers = headers.set('Authorization', sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token'));
		}

		if (headersConfig.xFormatParams && headersConfig.xFormatParams !== '') {
			headers = headers.set('X-FormatParams', headersConfig.xFormatParams);
		}

		return headers;
	}

	public static httpHeaders_fetch(headersConfig: ServiceHeadersConfig): any {
		const headers: any = {};

		headers['Content-Type'] =  headersConfig.contentType;

		if (!headersConfig.isAnonymous) {
			headers['Authorization'] = sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token');
		}

		if (headersConfig.xFormatParams && headersConfig.xFormatParams !== '') {
			headers['X-FormatParams'] = headersConfig.xFormatParams;
		}

		return headers;
	}
}
