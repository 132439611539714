import { HttpParams } from '@angular/common/http';

import { ServiceAction } from './service-action.urls';
import { ServiceController } from './service-controller.urls';
import { AppConfig } from '../../models/general';

/**
 * Utility class to set the request URL
 */
export class ServiceUrlsUtility {
	/**
	 * Function to generate the request URL
	 * @params controller The 'controller' part of the URL
	 * @params action The 'action' part of the URL
	 * @params value Array containing the value/s to be appeneded to the URL
	 * @return The generated URL string
	 */
	public static getUrl(baseServiceURL: string, controller: ServiceController, action?: ServiceAction, value?: any[]): string {
		const appConfig = JSON.parse(localStorage.getItem('helioEngineAppConfig')) as AppConfig;
		// replace subdomain in URL
		if (appConfig.useSubdomain && baseServiceURL.indexOf('*.') !== -1) {
			baseServiceURL = this.replaceSubdomain(baseServiceURL);
		}

		// appending controller to service url
		let url: string = baseServiceURL + controller;

		// if defined, append action
		if (action !== undefined) {
			url += action;
		}

		// if defined, append value
		if (value !== undefined && value.length > 0) {
			// checking if there's a trailing slash before adding "/[value]"
			// so that we avoid having a URL with "//[value]"
			// Reference: http://stackoverflow.com/a/3884711/2312637
			url += (url.slice(-1) === '/') ? '' : '/';

			for (const tempVal of value) {
				url += tempVal + '/';
			}
		}

		return url;
	}

	// http://stackoverflow.com/a/1714899/2312637
	public static getObjectSearchParams(httpParams: HttpParams, object: object, prefix?: string): HttpParams {
		// let searchParams: HttpParams = new HttpParams();
		for (const prop in object) {
			if (object.hasOwnProperty(prop)) {
				let key;
				const value = object[prop];

				if (prefix) {
					key = `${prefix}[${prop}]`;
				} else {
					key = prop;
				}

				if (value !== null && typeof value === 'object') {
					httpParams = this.getObjectSearchParams(httpParams, value, key);
				} else {
					httpParams = httpParams.set(key, value);
				}
			}
		}
		return httpParams;
	}

	private static replaceSubdomain(baseServiceURL: string): string {
		const subdomain: string = sessionStorage.getItem('subdomain');
		if (subdomain === null) {
			return baseServiceURL.replace('*.', '');
		}

		return baseServiceURL.replace('*', subdomain);
	}
}
