// collapsed slideMenu + layoutContent = 60px + 1188 = 1248px ---> The menu content area of desktop
export const MIN_DESKTOP_CONTENT_WIDTH = 1188;

/**
 * A helper for retrieving margin since margin is excluded from {@link HTMLElement.offsetHeight}
 * and {@link HTMLElement.clientHeight}.
 */
export function actualHeight(el: HTMLElement) {
	const styles = window.getComputedStyle(el);
	return el.offsetHeight + parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
}

export function getLang() {
	if (navigator.languages !== undefined) {
		return navigator.languages[0];
	}
	return navigator.language;
}
