import { Component } from '@angular/core';

@Component({
	selector: 'he-public-content',
	styleUrls: [
		'./public-content.component.scss'
	],
	templateUrl: './public-content.component.html'
})

export class PublicContentComponent { }
