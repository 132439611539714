import {Component, HostBinding, Input} from '@angular/core';

@Component({
	selector: 'he-dashboard-widget',
	templateUrl: './dashboard-widget.component.html',
	styleUrls: ['./dashboard-widget.component.scss']
})
export class DashboardWidgetComponent {

	@HostBinding('class')
	gridClass = 'grid-stack-item';

	@HostBinding('attr.data-gs-x')
	@Input()
	public positionX: number;

	@HostBinding('attr.data-gs-y')
	@Input()
	public positionY: number;

	@HostBinding('attr.gs-w')
	@Input()
	public width: number;

	@HostBinding('attr.gs-h')
	@Input()
	public height: number;

	constructor() { }
}
