import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {RetailAgentTopUpHistory} from '../../../../shared';
import {
	ColumnType, DataTableLazyLoadEvent, DataTableServerResponse,
	ExportCSVUtility, FileDownloadUtility, CurrencySetting, SearchFieldType
} from '../../../../../shared';
import {BoErrorHandlerService, AppConfigService, BreadcrumbService} from '../../../../../helio-core-services';
import {ActivatedRoute, ParamMap} from '@angular/router';
import * as ColumnNames from '../../../../../shared/constants/ui-db-name-mappings';
import {CommonTable} from '../../../../../shared/interfaces/common-table';
import {Subscription} from 'rxjs';
import {isArrayInit} from '../../../../../shared/utilities/general-utilities/arrays.utils';
import {LookupService} from '../../../../../shared/services/lookup.service';
import {IndividualPlayerConstants} from '../../../../../shared/components/table/constant/individual-player.constants';
import {RetailAgentService} from '../../../../../shared/services/retail-agent.service';

@Component({
	selector: 'he-top-up-history-tab',
	templateUrl: './top-up-history-tab.component.html',
	styleUrls: ['./top-up-history-tab.component.scss']
})
export class TopUpHistoryTabComponent extends CommonTable<RetailAgentTopUpHistory> implements OnInit, OnChanges, OnDestroy {

	TAG = TopUpHistoryTabComponent.name;

	@Input() currencySetting: CurrencySetting;

	@Input() tabbedTitle = IndividualPlayerConstants.TAB_NAMES.rewardPoints;
	@Input() isTabbedTable = false;

	@Output() hideCurrencyUIEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	columns: any[] = [];

	/**
	 * Populated in {@link initDataTable()}
	 */
	baseElements: any[] = [];

	private retailAgentID: number;

	private getTopUpHistorySubs$: Subscription;
	private getTopUpHistoryCsvSubs$: Subscription;

	constructor(
		private route: ActivatedRoute,
		private retailAgentService: RetailAgentService,
		protected appConfigService: AppConfigService,
		private lookupService: LookupService,
		private breadcrumbService: BreadcrumbService,
		protected boErrorHandlerService: BoErrorHandlerService,
	) {
		super(appConfigService, boErrorHandlerService);
	}

	ngOnInit() {
		this.hideCurrencyUIEvent.emit(true);

		this.breadcrumbService.setItems([
			{label: 'Retail Management'},
			{label: 'Agents', routerLink: ['/retail-agents/agents']},
			{label: 'Top Up Balance History'}
		]);

		this.initDataTable();

		this.route.paramMap.subscribe((params: ParamMap) => {
			if (params.has('id')) {
				this.retailAgentID = +params.get('id');
			}

			this.getTableData();
		});
	}

	ngOnChanges(changes) {
		const objToAccess = 'currencySetting';
		const currencyChange = changes[objToAccess];
		if (currencyChange) {
			// console.warn(this.TAG, '#ngOnChanges: newCur =', currencyChange)
			this.handleCurrencyChange();
		}
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.getTopUpHistorySubs$);
	}

	handleCurrencyChange(): void {
	}

	initDataTable() {
		this.dataKey = ColumnNames.TIME_STAMP.DB;

		this.columns = [
			{
				field: ColumnNames.TIME_STAMP.DB,
				header: ColumnNames.TIME_STAMP.UI_timeStamp,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.CURRENCY_CODE.DB,
				header: ColumnNames.CURRENCY_CODE.UI
			},
			{
				field: ColumnNames.AMOUNT.DB,
				header: ColumnNames.AMOUNT.UI,
				columnType: ColumnType.Money
			},
			{
				field: ColumnNames.BAL_AFTER.DB,
				header: ColumnNames.BAL_AFTER.UI,
				columnType: ColumnType.Money
			},
			{
				field: ColumnNames.USER.DB,
				header: ColumnNames.USER.UI
			}
		];

		this.tableActions = [
			{
				menuItem: {
					label: 'Export to CSV',
					icon: 'ui-icon-insert-drive-file'
				},
				callback: (callbackObj) => {
					this.exportToCSV(callbackObj.data as RetailAgentTopUpHistory[], callbackObj.isAllDataSelected);
				},
				isRowAction: false
			}
		];

		// Add all options - including dynamic lookups that might not be yet available - so possible options remain
		// the same. If a lookup is unavailable, i.e. still being fetched, a warning toast is shown via
		// AdvancedSearchComponent#onSelectSearchFieldClicked
		this.searchFields.push(
			{
				property: ColumnNames.TIME_STAMP.DB,
				label: ColumnNames.TIME_STAMP.UI_timeStamp,
				type: SearchFieldType.Date,
				isRange: true
			},
			{
				label: ColumnNames.CURRENCY_ID.UI,
				property: ColumnNames.CURRENCY_ID.DB,
				type: SearchFieldType.NumberList,
				listOptions: [],
				isArraySearch: false
			},
			{
				label: ColumnNames.AMOUNT.UI,
				property: ColumnNames.AMOUNT.DB,
				type: SearchFieldType.Number,
			},
			{
				label: ColumnNames.BAL_AFTER.UI,
				property: ColumnNames.BAL_AFTER.DB,
				type: SearchFieldType.Number,
			},
			{
				property: ColumnNames.USER.DB,
				label: ColumnNames.USER.UI,
				type: SearchFieldType.StringDefault
			}
		);

		this.fetchLookups();
	}

	getTableData(event?: DataTableLazyLoadEvent): void {
		super.getTableData(event);

		this.params = this.computeTableDataHttpParams(event);

		this.getTopUpHistory();
	}

	fetchLookups() {
		// this.releaseSubscriptions(this.getWalletTransactionTypesSub$, ...);

		const reassignLookupOpts = (res: any[], prop) => {
			if (isArrayInit(res)) {
				// this.transTypeLookups = res;

				// Update the previously added transType object by reference
				const temp = this.searchFields.find(value => {
					return value.property === prop;
				});
				temp.listOptions = res;
			}
		}

		// get currencies
		this.lookupService.getPlayerNomenclature('Currencies').then(res => {
			reassignLookupOpts(res.result.currencies, ColumnNames.CURRENCY_ID.DB);
		}).catch(error => {
			this.boErrorHandlerService.handleError(error);
		});
	}

	exportToCSV(selectedRecords: RetailAgentTopUpHistory[], isAllDataSelected: boolean): void {
		super.exportToCSV(selectedRecords, isAllDataSelected);

		// Use ID for "$filter (id eq 31 OR id eq 33)" even though it is not displayed on table, since this is contained
		//  within the return data object. {"id": 23, "retailAgentID": 0, "timestamp": "2022-10-13T10:10:35Z", ...}
		// And using date is not currently supported!

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, selectedRecords, isAllDataSelected,'id'
		);

		this.releaseSubscriptions(this.getTopUpHistoryCsvSubs$);

		this.getTopUpHistoryCsvSubs$ = this.retailAgentService.getTopUpHistoryCsv(this.retailAgentID, exportHttpParams, this.columns).subscribe({
			next: res => {
				if (res !== undefined) {
					// download file
					FileDownloadUtility.downloadCsv(res, `BalanceHistoryForAgent_${this.retailAgentID}`);
				}
			},
			error: error => {
				this.boErrorHandlerService.handleError(error, undefined, 'Exporting to CSV');
			}
		});
	}

	private getTopUpHistory() {
		this.beforeTableDataServiceCall(this.getTopUpHistorySubs$);

		this.getTopUpHistorySubs$ =
			this.retailAgentService.getTopUpHistory(this.retailAgentID, this.params).subscribe({
				next: (res: DataTableServerResponse) => {
					if (res.resultSet !== null && res.resultSet !== undefined) {
						this.data = res.resultSet as RetailAgentTopUpHistory[];
						this.totalRecords = res.totalRowCount;
						this.offset = res.offset;
						this.loading = false;

						if (res.resultSet.length === 0) {
							this.tableMessage = this.appConfigService.tableMissingDataMessage;
						}
					}
				},
				error: error => {
					this.tableMessage = this.appConfigService.genericErrorMessage;
					this.boErrorHandlerService.handleError(
						error, undefined, `Getting Top up Balance History for Agent ${this.retailAgentID}`);

					this.loading = false;
				}
			});
	}
}
